/**
 * Created by madura on 1/27/17.
 */
import React from 'react';
import './../../../lib/react-multistep/prog-tracker.css';
import Multistep from './../../../lib/react-multistep/multistep';
import { Modal} from 'shoutout_themes';
import {UploadFileContainer,PeopleMapAttributesContainer,UploadPeopleContainer,ImportSettingsContainer} from './../../../redux/containers/PeopleImportContainer';
import './PeopleImport.css';


const ImportPeople = ({fileName, uploadFile, file, fileType, country,  fileId, columnNameMap, tags, isCreateSegment, uploadPeople, segmentName, segmentTagIndex, uniqueId ,showImportWizard,hideImportWizard,peopleUploading}) => {
    return (

            <Modal size="lg" aria-labelledby="contained-modal-title-lg" show={showImportWizard} dialogClassName="people-import" onHide={hideImportWizard}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-lg">Import People</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='step-progress text-center'>
                        <Multistep steps={[
                            {
                                name: 'Select File',
                                isDisableNextBtn: !fileName,
                                onClickNext: () => {
                                    uploadFile(file, fileType)
                                },
                                nextBtnTooltip: 'Required to select a file to go to next step',
                                component: <UploadFileContainer/>
                            },
                            {
                                name: 'Map Attributes',
                                isDisableNextBtn: (!columnNameMap['mobile_number'].mapWith && !columnNameMap['email'].mapWith),
                                nextBtnTooltip: 'Required to map Email or Mobile number to go to next step',
                                component: <PeopleMapAttributesContainer/>
                            },
                            {
                                name: 'Settings',
                                isDisableNextBtn: !uniqueId || (isCreateSegment && !segmentName),
                                nextBtnTooltip: !uniqueId ? 'Required to map unique id to go to next step' : (isCreateSegment && !segmentName ? 'Segment name is required to go to next step' : ''),
                                component: <ImportSettingsContainer/>
                            },
                            {
                                name: 'Import',
                                nextBtnName: 'Import',
                                isDisableNextBtn: peopleUploading,
                                showNextBtn: true,
                                onClickNext: () => {
                                    uploadPeople(columnNameMap, tags, fileId, isCreateSegment, segmentName, segmentTagIndex, uniqueId, country)
                                },
                                component: <UploadPeopleContainer/>
                            }
                        ]} />

                    </div>
                </Modal.Body>
               
            </Modal>
    );
};

export default ImportPeople;

