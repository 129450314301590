import React, { createContext, useReducer, useEffect } from "react";
import { ShoutOUTHooksService } from './../services/Services';
const InitialIntegrationContext = {
    integrations: {},
    isLoading: true
};
const IntegrationContext = createContext();

const INTEGRATIONS_ACTIONS = {
    LOAD_USER_INTEGRATIONS: 'LOAD_USER_INTEGRATIONS',
    SHOW_IS_LOADING_INTEGRATIONS: 'SHOW_IS_LOADING_INTEGRATIONS'
}


const reducer = (state, { type, ...rest }) => {

    switch (type) {
        case INTEGRATIONS_ACTIONS.LOAD_USER_INTEGRATIONS: {
            return {
                ...state, integrations: rest.integrations, isLoading: false
            }
        }
        case INTEGRATIONS_ACTIONS.SHOW_IS_LOADING_INTEGRATIONS: {
            return {
                ...state, isLoading: true
            }
        }
        default: {
            return state;
        }
    }
};


const IntegrationContextContainer = (props) => {
    const [state, dispatch] = useReducer(reducer, InitialIntegrationContext);

    const getIntegrations = async () => {
        try {
            dispatch({type:INTEGRATIONS_ACTIONS.SHOW_IS_LOADING_INTEGRATIONS})
            const userIntegrationsResponse = await ShoutOUTHooksService.getIntegrations();
            const transformedResponse = userIntegrationsResponse.reduce((result, { service, ...rest }) => {
                (result[service] || (result[service] = [])).push({ ...rest, service });
                return result;
            }, {});
            dispatch({ type: INTEGRATIONS_ACTIONS.LOAD_USER_INTEGRATIONS, integrations: transformedResponse });
        } catch (e) {

        }
    }

    useEffect(() => {
        getIntegrations();
    }, [])


    const value = { ...state, getIntegrations };
    return (
        <IntegrationContext.Provider value={value}>{props.children}</IntegrationContext.Provider>
    );
}


export default IntegrationContext;
export { InitialIntegrationContext, IntegrationContextContainer };