/**
 * Created by madura on 2/24/17.
 */
import React from 'react';

const BasicEmailWrapperTemplate = ({emailBody}) => {
    return (
        <html xmlns="http://www.w3.org/1999/xhtml">
        <head>
            <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8"/>
            <title></title>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>

        </head>
        <body style={{margin: 0, padding: 0}}>
        {emailBody}
        </body>
        </html>
    )
};

export default BasicEmailWrapperTemplate;