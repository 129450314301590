/**
 * Created by madura on 2/7/17.
 */
import COMMON_ACTIONS, {
    RECEIVE_COLUMN_NAME_MAP,
    RECEIVED_SENDER_IDS,
    RECEIVED_TAGS,
    RECEIVED_SEGMENTS
} from '../actions/CommonActions';
import update from 'immutability-helper';
import { merge } from 'lodash';
import Constants from './../../Constants';
const initialState = {
    columnNameMap: {},
    senderIds: { [Constants.TRANSPORT_SMS]: [], [Constants.TRANSPORT_EMAIL]: [], [Constants.TRANSPORT_MESSENGER]: [] },
    tags: {},
    segments: {
        [Constants.ALL_SEGMENT.id]: Constants.ALL_SEGMENT.segment
    },
    apiKeyList: [],
    loadingSenderIds: true
};
const CommonReducer = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_COLUMN_NAME_MAP: {
            return update(state, {
                columnNameMap: { $set: action.columnNameMap }
            });
        }
        case RECEIVED_SENDER_IDS: {
            return update(state, {
                senderIds: {
                    [Constants.TRANSPORT_SMS]: { $set: action.senderIds[Constants.TRANSPORT_SMS] || [] },
                    [Constants.TRANSPORT_EMAIL]: { $set: action.senderIds[Constants.TRANSPORT_EMAIL] || [] },
                    [Constants.TRANSPORT_MESSENGER]: { $set: action.senderIds[Constants.TRANSPORT_MESSENGER] || [] }
                },
                loadingSenderIds: { $set: false }
            });
        }
        case RECEIVED_TAGS: {
            return update(state, {
                tags: { $set: action.tags }
            });
        }
        case RECEIVED_SEGMENTS: {
            const segments = merge({}, initialState.segments, action.segments);
            const transformedSegment=Object.entries(segments).reduce((result, [key, value]) => {
                result[key] = { id: key, ...value };
                return result;
            }, [{}])
            return update(state, {
                segments: { $set: transformedSegment }
            });
        }

        case COMMON_ACTIONS.RECEIVED_APIKEYS: {
            return update(state, {
                apiKeyList: { $set: action.apiKeyList }
            });
        }

        case COMMON_ACTIONS.SET_LOADING_SENDER_IDS: {
            return update(state, {
                loadingSenderIds: { $set: action.isLoading }
            });
        }
        default:
            return state;
    }
};

export default CommonReducer;
