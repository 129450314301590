import React from 'react';

import {
    Modal,Button
} from 'shoutout_themes';



const AlertBox = ({message,type,title,show,onClose}) => {
    return (
        <Modal show={show} onHide={onClose}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
           <p className={`text-${type}`}>{message}</p>
            </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose}>Close</Button>
          </Modal.Footer>
        </Modal>
    );
};
export default AlertBox;