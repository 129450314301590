import SendSmsMessage from './../../components/utils/SendSmsMessage';
import { connect } from './../../contexts/ContextUtils';
import {
   changeSenderId, changeSendMessageMessage, changeSendMessageTo
} from './../actions/UtilActions';
import Constants from './../../Constants';

const SendSmsMessageContainer = connect(
    (state) => { //map state to props
        return {
            senderIds: state.common.senderIds,
            mobileNumber: state.util.sendMessage.sms.to,
            smsSenderId: state.util.sendMessage.sms.senderId,

            message: state.util.sendMessage.sms.message,
            actionFrom: state.util.sendMessage.actionFrom
        };
    },

    (dispatch, ownProps) => {
        return {

            changeSenderId: (senderId, transport) => dispatch(changeSenderId(senderId, transport)),
            onChangeMessage: (message) => { dispatch(changeSendMessageMessage(message, Constants.TRANSPORT_SMS)) },
            onChangeMobileNumber: (mobileNumber) => { dispatch(changeSendMessageTo(mobileNumber, Constants.TRANSPORT_SMS)) }
        };

    }
)(SendSmsMessage);


export default SendSmsMessageContainer;