import {
    ShoutOUTService
} from './../../services/Services';
import {
    notify
} from "react-notify-toast";
const TEMPLATE_ACTIONS = {
    SAVE: 'SAVE_TEMPLATE',
    SAVING: 'SAVING_TEMPLATE',
    SAVED: 'SAVED_TEMPLATE',
    SAVE_ERROR: 'ERROR_SAVE_TEMPLATE',
    SHOW_NAME_INPUT_DIALOG: 'SHOW_TEMPLATE_NAME_INPUT_DIALOG',
    SET_NAME: 'SET_TEMPLATE_NAME',
    SHOW_TEMPLATE_GALLERY: 'SHOW_TEMPLATE_GALLERY',
    LOADING_TEMPLATES: 'LOADING_TEMPLATES',
    LOADED_TEMPLATES: 'LOADED_TEMPLATES',
    LOADING_TEMPLATES_ERROR: 'LOADING_TEMPLATES_ERROR',
    DELETING_TEMPLATE: 'DELETING_TEMPLATE',
    DELETED_TEMPLATE: 'DELETED_TEMPLATE',
    DELETE_TEMPLATE_ERROR: 'DELETE_TEMPLATE_ERROR',
    FILTER_TEMPLATE: 'FILTER_TEMPLATE'
};
const showTemplateNameInput = (isShow) => {
    return {
        type: TEMPLATE_ACTIONS.SHOW_NAME_INPUT_DIALOG,
        isShow
    }
}

const saveTemplate = (templateContent, templateType, templateName) => {
    return (dispatch) => {
        dispatch({
            type: TEMPLATE_ACTIONS.SAVE,
            templateContent,
            templateType,
            templateName
        });
        if (!!templateName) {
            dispatch({
                type: TEMPLATE_ACTIONS.SAVING
            });
            ShoutOUTService.saveTemplate(templateContent, templateName, templateType).then(res => {
                dispatch({
                    type: TEMPLATE_ACTIONS.SAVED
                });
                notify.show('Template saved', 'success');
            }, err => {
                dispatch({
                    type: TEMPLATE_ACTIONS.SAVE_ERROR
                });
                notify.show('Template cannot save', 'error');
            })
        } else {
            dispatch(showTemplateNameInput(true));
        }
    }
}

const onChangeTemplateName = (templateName) => {
    return {
        type: TEMPLATE_ACTIONS.SET_NAME,
        templateName
    };
};
const filterTemplate = (selectedTemplate = []) => {
    return {
        type: TEMPLATE_ACTIONS.FILTER_TEMPLATE,
        selectedTemplate:selectedTemplate[0]
    }
};
const loadTemplates = (templateType) => {
    return (dispatch) => {
        dispatch({
            type: TEMPLATE_ACTIONS.LOADING_TEMPLATES
        });
        dispatch(filterTemplate());
        ShoutOUTService.getTemplates(templateType).then(res => {
            dispatch({
                type: TEMPLATE_ACTIONS.LOADED_TEMPLATES,
                templates: res.Items
            });
        }, err => {
            dispatch({
                type: TEMPLATE_ACTIONS.LOADING_TEMPLATES_ERROR
            });
        })
    }
}
const chooseTemplate = (isShow, templateType) => {
    return (dispatch) => {
        dispatch({
            type: TEMPLATE_ACTIONS.SHOW_TEMPLATE_GALLERY,
            isShow,
            templateType
        });
        if (isShow) {
            dispatch(loadTemplates(templateType));
        }
    }
};


const deleteTemplate = (templateId) => {
    return (dispatch) => {
        dispatch({
            type: TEMPLATE_ACTIONS.DELETING_TEMPLATE
        });

        ShoutOUTService.deleteTemplate(templateId).then(res => {
            notify.show("Template deleted successfully", "success");
            dispatch({
                type: TEMPLATE_ACTIONS.DELETED_TEMPLATE,
                templateId
            });
            dispatch(filterTemplate());
        }, err => {
            notify.show("Couldn't delete the template", "error");
            dispatch({
                type: TEMPLATE_ACTIONS.DELETE_TEMPLATE_ERROR
            });
            
        })
    }

};

export default TEMPLATE_ACTIONS;
export {
    saveTemplate,
    showTemplateNameInput,
    onChangeTemplateName,
    chooseTemplate,
    deleteTemplate,
    loadTemplates,
    filterTemplate
};
