import SendEmailMessage from './../../components/utils/SendEmailMessage';
import { connect } from './../../contexts/ContextUtils';
import {
    changeSenderId, changeSendMessageMessage, changeSendMessageTo
} from './../actions/UtilActions';
import Constants from './../../Constants';
const SendEmailMessageContainer = connect(
    (state) => { //map state to props
        return {
            senderIds: state.common.senderIds,
            toAddress: state.util.sendMessage.email.to,
            emailSenderId: state.util.sendMessage.email.senderId,
            body: state.util.sendMessage.email.body,
            subject: state.util.sendMessage.email.subject,
            actionFrom: state.util.sendMessage.actionFrom
        };
    },

    (dispatch, ownProps) => {
        return {
            changeSenderId: (senderId, transport) => dispatch(changeSenderId(senderId, transport)),
            onChangeMessage: (message) => { dispatch(changeSendMessageMessage(message, Constants.TRANSPORT_EMAIL)) },
            onChangeToAddress: (email) => { dispatch(changeSendMessageTo(email, Constants.TRANSPORT_EMAIL)) }
        };

    }
)(SendEmailMessage);

export default SendEmailMessageContainer;