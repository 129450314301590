/**
 * Created by madura on 2/20/17.
 */
import
EVENT_ACTIONS from '../actions/EventActions';
import update from 'immutability-helper';
const initialState = {
    events: [],
    isLoadingEvents: true,
    isCreatingEvent: false,
    createEventError: false,
    integration: {
        selectedEvent: {}
    }
};
const EventsReducer = (state = initialState, action) => {
    switch (action.type) {
        case EVENT_ACTIONS.RECEIVED_EVENTS:
            {
                return update(state, {
                    events: {
                        $set: action.events
                    },
                    isLoadingEvents: {
                        $set: false
                    }
                });
            }
        case EVENT_ACTIONS.REQUEST_EVENTS:
            {
                return update(state, {
                    isLoadingEvents: {
                        $set: true
                    }
                });
            }
        case EVENT_ACTIONS.EVENT_CREATING:
            {
                return update(state, {
                    isCreatingEvent: {
                        $set: true
                    }
                });
            }
        case EVENT_ACTIONS.EVENT_CREATE_ERROR:
            {
                return update(state, {
                    isCreatingEvent: {
                        $set: false
                    },
                    createEventError: {
                        $set: true
                    }
                });
            }
        case EVENT_ACTIONS.EVENT_CREATED:
            {
                return update(state, {
                    isCreatingEvent: {
                        $set: false
                    },
                    createEventError: {
                        $set: false
                    },
                    events: {
                        $push: [action.event]
                    }
                });
            }
        case EVENT_ACTIONS.SELECT_EVENT_INTEGRATION:
            {
                return update(state, {
                    integration: {
                        selectedEvent: {
                            $set: action.selectedEvent
                        }
                    }
                });
            }
        default:
            return state;
    }
};
export default EventsReducer;
