import React, { useContext, useState } from "react";
import './PeopleActionBar.css';
import {
    Dropdown,
    Button,
    DropdownButton,
    FaIcon
} from 'shoutout_themes';
import { faChartPie, faUserPlus, faUpload, faDownload, faTag, faPaperPlane, faTrashAlt, faFilter } from 'shoutout_themes/es/FaIconsSolid';
import SearchPeople from './PeopleSearch';
import { AddTagDialogContainer } from './../../redux/containers/PeopleListContainer';
import { PeopleDeleteContainer } from './../../redux/containers/PeopleListContainer';
import { ShoutOUTService, UtilService } from './../../services/Services';
import { notify } from 'react-notify-toast';
import UserContext from './../../contexts/UserContext';
import { withRouter } from 'react-router-dom';
const PeopleActionsBarOnboarding = withRouter(({ onClickSegments, selectedPeopleCount, onClickDelete, onSearch, showAddEditPerson, createCampaign, isSelectAll, selectedSegment, clickedAddMoreTags, selectedPeople, searchText, onChangeSearchText, selectAllStatus, selectedFilters, isFilterSelected, clickedImportPeople, peopleList, history }) => {
    const { emailVerified } = useContext(UserContext);

    let isPeopleSelected = selectedPeopleCount > 0;
    return (
        <div className="people-action-bar">
            <div className="d-flex">
                <Button className="disabled rounded-0 mr-2" size="sm" variant="transparent" onClick={onClickSegments} ><FaIcon className="mr-2" icon={faChartPie} />Segments</Button>
                <div className="flex-grow-1 disabled">
                    <SearchPeople onSearch={onSearch} searchText={searchText} onChangeSearchText={onChangeSearchText} selectedFilters={selectedFilters} />
                </div>
                <div className="ml-2 d-flex">

                    {!isPeopleSelected && <>  <Button className="rounded-0" size="sm" variant="primary" onClick={() => { showAddEditPerson(true) }} ><FaIcon className="mr-2" icon={faUserPlus} />Create Person</Button>
                        <Button className="disabled rounded-0" size="sm" variant="transparent" ><FaIcon className="mr-2" icon={faUpload} />Import People</Button></>}
                    <Button className="disabled rounded-0 mr-2" size="sm" variant="transparent" ><FaIcon className="mr-2" icon={faFilter} />Filter</Button>
                    <Button className="disabled rounded-0" size="sm" variant="transparent" ><FaIcon className="mr-2" icon={faDownload} />Download</Button>



                    {isPeopleSelected && <>
                        <Button size="sm" variant="transparent" className="ml-2 rounded-0 disabled"><FaIcon icon={faTag} />&nbsp;&nbsp;Tag </Button>
                        <Button size="sm" variant="transparent" className="ml-2 rounded-0"><FaIcon icon={faTrashAlt} />&nbsp;&nbsp;Delete</Button>
                        <Button size="sm" variant="transparent" className="ml-2 rounded-0 disabled" disabled={!emailVerified}><FaIcon icon={faPaperPlane} />&nbsp;&nbsp;Create Campaign</Button>
                    </>}

                </div>
                <AddTagDialogContainer />
                <PeopleDeleteContainer />
            </div>
        </div>
    );
});


export default PeopleActionsBarOnboarding;
