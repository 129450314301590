/**
 * Created by madura on 2/20/17.
 */
import React from 'react';
import { Card, Row, Col, Button, Form } from 'shoutout_themes';
import ConfirmBox from './../../../utils/ConfirmBox';
import { FaIcon } from 'shoutout_themes';
import { faCheck } from 'shoutout_themes/es/FaIconsSolid';
import Constants from './../../../../Constants';
import UserContext from '../../../../contexts/UserContext';
class CampaignSelectSegment extends React.Component {

    constructor() {
        super();
        this.state = { showConfirmDialog: false, confirmCallback: null, lastAction: null };
        this._changeAction = this._changeAction.bind(this);
        this._closeConfirmDialog = this._closeConfirmDialog.bind(this);
        this._confirmAction = this._confirmAction.bind(this);
        this.segmentId = '';
        this.tempLastAction = null;
    }

    _changeAction(action, callback) {
        if (this.state.lastAction !== action && ((action !== "import" && this.props.selectedFilters.length) || (action !== "create" && this.props.selectedPeople.length) || (action !== "segment" && this.props.selectedSegmentId))) {
            this.tempLastAction = action;
            this.setState({ showConfirmDialog: true, confirmCallback: callback });
        } else {
            callback();
            this.setState({ lastAction: action });
        }
    }

    _closeConfirmDialog() {
        this.setState({ showConfirmDialog: false });
    }

    _confirmAction() {
        this._closeConfirmDialog();
        this.state.confirmCallback();
        this.setState({ lastAction: this.tempLastAction });
    }
    render() {
        const { selectedSegmentId, onSelectSegment, segments, clickedImportPeople, showAddEditPerson, selectedFilters, selectedPeople } = this.props;
        let keys = Object.keys(segments);

        return (
            <UserContext.Consumer>
                {({ scopes }) => (
                    <Row className="justify-content-center">
                        <Col lg={6} sm={8} xs={12}>
                            <Card className="text-left">
                                <Card.Body>
                                    <h6 className="font-weight-bold">Select an Existing Segment</h6>
                                    <div className="d-flex justify-content-between align-items-center">


                                        <Form.Group controlId="select-segment" bsPrefix="input-group pt-0 form-group custom mr-2">
                                            <Form.Control as="select" onChange={(e) => {
                                                this.segmentId = e.target.value;
                                                this._changeAction("segment", () => { onSelectSegment(this.segmentId, this.props.segments[this.segmentId]) });
                                            }} value={selectedSegmentId}>
                                                <option value="" disabled>Select A Segment</option>
                                                {keys.map((key) => {
                                                    return (<option key={key} value={key}>{segments[key].name}</option>)
                                                })}
                                            </Form.Control>

                                        </Form.Group>
                                        <span className="border border-muted rounded-circle p-2 mt-1 line-height-1">
                                            <FaIcon className={`text-${selectedSegmentId ? "success" : "white"}`} icon={faCheck} />
                                        </span>
                                    </div>

                                    <br />
                                    {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.PEOPLE.moduleId].write &&
                                        <>
                                            <hr />
                                            <br />

                                            <h6 className="font-weight-bold">Import People</h6>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <Button className="mr-2" variant="primary" onClick={() => this._changeAction("import", clickedImportPeople)}>Import People from CSV/Excel</Button>
                                                <span className="border border-muted rounded-circle p-2 line-height-1">
                                                    <FaIcon className={`text-${selectedFilters.length > 0 ? "success" : "white"}`} icon={faCheck} />
                                                </span>
                                            </div>

                                            <br />


                                            <hr />
                                            <br />

                                            <h6 className="font-weight-bold">Create People</h6>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <Button className="mr-2" variant="primary" onClick={() => this._changeAction("create", showAddEditPerson)}>{selectedPeople.length ? 'Add more People Manuallly' : 'Create Person Manually'}</Button>
                                                <span className="border border-muted rounded-circle p-2 line-height-1">
                                                    <FaIcon className={`text-${selectedPeople.length > 0 ? "success" : "white"}`} icon={faCheck} />
                                                </span>

                                            </div>
                                            <Row className="justify-content-center"><Col xs={12}><br />{this.state.lastAction === "create" && <p>You have selected {selectedPeople.length} people</p>}</Col></Row>
                                        </>}
                                    <br />
                                    <ConfirmBox show={this.state.showConfirmDialog} onHide={this._closeConfirmDialog} confirmCallback={this._confirmAction} title="Are you sure ?" message="Current contacts selection will be cleared. Do you want to proceed ?" />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                )
                }
            </UserContext.Consumer>
        )
    }
};
export default CampaignSelectSegment;