import React from 'react';
import Constants from './../../../../Constants';
import { map } from 'lodash';
import {FaIcon,Card,Row,Col,Form } from 'shoutout_themes';
import { faCheck } from 'shoutout_themes/es/FaIconsSolid';

class CampaignSelection extends React.PureComponent {
    constructor() {
        super();
        this._onSelectCampaignType = this._onSelectCampaignType.bind(this);
        this.campignTypes = [
            {
                id: "t001",
                type: Constants.CAMPAIGN_BROADCAST,
                title: "Broadcast",
                description: "Regular campaign",
                tags: ["broadcast"],
                icon: "bullhorn"
            },
            {
                id: "t002",
                type: Constants.CAMPAIGN_TRIGGER,
                title: "Auto Respond",
                description: "Trigger campaign",
                tags: ["trigger"],
                icon: "bolt"
            }
        ];
    }
    _onSelectCampaignType(selectedCampaignType, itemType, disabled) {
        if (!disabled && selectedCampaignType !== itemType) {
            this.props.onChangeCampaignType(itemType);
        }
    }
    render() {
        const { campaignName, onChangeCampaignName, campaignType, filterByType } = this.props;
        return (<Row className="justify-content-center">
            <Col lg={8} sm={10} xs={12}>
                <Card className="text-left">
                    <Card.Body>
                        <Form.Group controlId="campaign-name">
                            <Form.Label>Campaign name:</Form.Label>
                            <Form.Control type="text" placeholder="Special discount" onChange={(e) => {
                                onChangeCampaignName(e.target.value)
                            }} value={campaignName} />
                        </Form.Group>

                        <Form.Group controlId="campaign-type">
                            <Form.Label>Select the campaign type:</Form.Label>

                            <div className="selection-box">
                                <br />
                                <div className="row">
                                    {map(this.campignTypes, (item) => {
                                        let disabled = filterByType && item.tags.indexOf(filterByType) < 0;
                                        return (
                                            

                                                <label key={item.id} className="card shadow box mx-2">

                                                    <input type="radio" name="campaign-select" value={item.type} checked={campaignType === item.type} onChange={() => this._onSelectCampaignType(campaignType, item.type, disabled)} />
                                                    <div className={`my-auto ${disabled ? "disabled" : ""}`}>

                                                    <span className="position-absolute top-right mt-2 mr-2 border border-muted rounded-circle p-2 line-height-1">
                                                    <FaIcon className={`text-${campaignType === item.type ? "success": "white"}`} icon={faCheck} />
                                                    </span>
                                                            
                                                           
                                                            <FaIcon icon={item.icon} size="2x" />
                                                            <h5 className="mb-0 font-weight-bold">{item.title}</h5>
                                                            <p><small>{item.description}</small></p>    
                                                          


                                                    </div>
                                                </label>
                                           
                                        );
                                    })}
                                </div>
                            </div>
                        </Form.Group>
                    </Card.Body>
                </Card>
            </Col>
        </Row>);
    }
}

export default CampaignSelection;