import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import parseInput from './utils/parseInput.js';
import { defaultClasses } from './styles.js';
import moment from 'moment';
class PredefinedRanges extends Component {

  constructor(props, context) {
    super(props, context);

    this.styles = this.props.theme;
  }

  handleSelect(name, event) {
    if (event)
      event.preventDefault();

    const range = this.props.ranges[name];

    if (range) {
      this.props.onSelect({
        startDate: parseInput(range['startDate'], null, 'startOf'),
        endDate: parseInput(range['endDate'], null, 'endOf'),
        selection: name
      }, PredefinedRanges);
    } else {

      const splittedRange = name.split('-');
      if (splittedRange.length === 2) {
        let [fromDate, toDate] = splittedRange;
        fromDate = fromDate.trim();
        toDate = toDate.trim();
        this.props.onSelect({
          startDate: parseInput(moment(fromDate,'MMM DD, YYYY'), null, 'startOf'),
          endDate: parseInput(moment(toDate,'MMM DD, YYYY'), null, 'endOf'),
          selection: name
        }, PredefinedRanges);
      }
    }
  }


  componentDidMount() {
    if (this.props.selectedRange) {
      this.handleSelect(this.props.selectedRange)
    }
  }
  renderRangeList(classes) {
    const { ranges, range, onlyClasses } = this.props;
    const { styles } = this;

    return Object.keys(ranges).map(name => {
      const active = (
        parseInput(ranges[name].startDate, null, 'startOf').isSame(range.startDate) &&
        parseInput(ranges[name].endDate, null, 'endOf').isSame(range.endDate)
      );

      const style = {
        ...styles['PredefinedRangesItem'],
        ...(active ? styles['PredefinedRangesItemActive'] : {}),
      };

      const predefinedRangeClass = classnames({
        [classes.predefinedRangesItem]: true,
        [classes.predefinedRangesItemActive]: active
      });

      return (
        <a
          href='#'
          key={'range-' + name}
          className={predefinedRangeClass}
          style={onlyClasses ? undefined : style}
          onClick={this.handleSelect.bind(this, name)}
        >
          {name}
        </a>
      );
    });
  }

  render() {
    const { style, onlyClasses, classNames } = this.props;
    const { styles } = this;

    const classes = { ...defaultClasses, ...classNames };

    return (
      <div
        style={onlyClasses ? undefined : { ...styles['PredefinedRanges'], ...style }}
        className={classes.predefinedRanges}
      >
        {this.renderRangeList(classes)}
      </div>
    );
  }
}

PredefinedRanges.defaultProps = {
  onlyClasses: false,
  classNames: {}
};

PredefinedRanges.propTypes = {
  ranges: PropTypes.object.isRequired,
  onlyClasses: PropTypes.bool,
  classNames: PropTypes.object,
  selectedRange: PropTypes.string
}

export default PredefinedRanges;
