import React, { PureComponent } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import {FaIcon} from 'shoutout_themes';
import './PieChartWidget.css';


const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
        fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 5) * cos;
    const sy = cy + (outerRadius + 5) * sin;
    const mx = cx + (outerRadius + 15) * cos;
    const my = cy + (outerRadius + 15) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 8;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text className="selected-label" x={cx} y={cy + 10} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
            <FaIcon icon={props.icon} x={cx - 10} y={cy - 20} width={20} height={20} className="pie-chart-icon" />
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text className="pie-labels" x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text>
            <text className="pie-labels" x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`(Rate ${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};

const COLORS = ["#8c32c9", "#2864db", "#1edd6b", "#FF9504", "#0A87B6"];
class PieChartWidget extends PureComponent {
    constructor() {
        super();
        this.onPieEnter = this.onPieEnter.bind(this);
        this.state = { activeIndex: 0 }
    }
    onPieEnter(data, index) {
        this.setState({
            activeIndex: index,
        });
    }
    render() {
        const { data } = this.props;
        return (
            <ResponsiveContainer width="100%" height={250} className="pie-chart-widget">
                <PieChart>
                    <Pie
                        activeIndex={this.state.activeIndex}
                        activeShape={renderActiveShape}
                        data={data}
                        innerRadius={55}
                        outerRadius={80}
                        fill="#8884d8"
                        onMouseEnter={this.onPieEnter}
                        legendType="square"
                        startAngle={90}
                        endAngle={450}
                        dataKey="value"

                    >{
                            data.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]} />)
                        }
                        >
                </Pie>

                </PieChart>
            </ResponsiveContainer>
        );
    }
}
export default PieChartWidget;