import React from 'react';
import { Col,  Form } from 'shoutout_themes';
import { UtilService } from './../../services/Services';
import { LoadingComponent } from './../utils/UtilComponents';

import './BillingInfo.css';

class BillingInfo extends React.Component {
    constructor() {
        super();
        this.state = {
            _validMobile: true,
            _validEmail: true
        };
        this.onChange = this.onChange.bind(this);
        this._onMobileNumberChange = this._onMobileNumberChange.bind(this);
    }


    onChange(e) {
        const { name: key, value } = e.target;
        let { billingInfo } = this.props;
        billingInfo[key] = value;
        if (key === 'email') {
            let isValidEmail = UtilService.validateEmail(value);
            this.setState({ _validEmail: isValidEmail });
            this._callOnChange({ billingInfo, validEmail: isValidEmail });
        } else {
            this._callOnChange({ billingInfo });
        }

    }

    _callOnChange({ billingInfo, validMobile = this.state._validMobile, validEmail = this.state._validEmail }) {
        if (this.props.onChangeBillingInfo) {
            let {
                cardHolderName,
                streetAddress,
                streetAddress2,
                city,
                state,
                zip,
                country,
                email,
                phone } = billingInfo;
            let isValid = streetAddress && streetAddress2 && city && state && zip && country && email && phone && cardHolderName && (validMobile) && (validEmail);
            this.props.onChangeBillingInfo(billingInfo, isValid);

        }
    }
    _onMobileNumberChange(status, value, countryData, number, id) {
        let { billingInfo } = this.props;
        let mobile = number.replace(/[^0-9]/gi, '');
        if (status) {
            let country = countryData.name.replace(/ *\([^)]*\) */g, "").trim();

            if (!billingInfo['country'])
                billingInfo['country'] = country;

        }
        billingInfo['phone'] = mobile;
        this.setState({ _validMobile: status });

        this._callOnChange({ billingInfo, validMobile: status });

    }


    componentDidMount() {
        this._callOnChange({ billingInfo: this.props.billingInfo });

    }
    componentDidUpdate(prevProps) {
        if (this.props.billingInfo.cardHolderName && !prevProps.billingInfo.cardHolderName) {
            this._callOnChange({ billingInfo: this.props.billingInfo });

        }
    }

    render() {
        const {
            phone
        } = this.props.billingInfo;

        return (
            <div className="billing-info ">
                {this.state.isLoading ? <LoadingComponent /> : <div>
                    <Form>
                        <Form.Group controlId="name" size="sm">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Sam De Silva" name="cardHolderName" onChange={this.onChange} value={this.props.billingInfo["cardHolderName"]} required />
                        </Form.Group>

                        <Form.Row>
                            <Form.Group controlId="email" size="sm" as={Col}>
                                <Form.Label>
                                    Email:
                            </Form.Label>

                                <Form.Control type='email' name='email' placeholder="sam@mycompany.com" onChange={this.onChange} value={this.props.billingInfo['email']} required />
                                {this.state._validEmail ? null :
                                    <p><small className="text-danger">Invalid Email</small></p>}

                            </Form.Group>


                            {!this.props.isLoadingBillingInfo && <Form.Group controlId="phone" size="sm" as={Col}>
                                <Form.Label>
                                    Phone:
                                </Form.Label>
                                <Form.MobileNumberInput id="mobile" defaultCountry={'lk'}
                                      ref="mobile" onPhoneNumberBlur={this._onMobileNumberChange} onPhoneNumberChange={this._onMobileNumberChange} required
                                      format={true} formatOnInit={true} defaultValue={phone} />

                               
                                {this.state._validMobile ? null :
                                    <p><small className="text-danger">Invalid Mobile Number</small></p>}

                            </Form.Group>}
                        </Form.Row>


                        <Form.Row>
                            <Form.Group controlId="streetAddress" size="sm" as={Col}>
                                <Form.Label>
                                    Street Address:
                            </Form.Label>
                                <Form.Control type='text' name='streetAddress' placeholder='410/33,Bauddhaloka Mawatha' onChange={this.onChange} value={this.props.billingInfo['streetAddress']} required />

                            </Form.Group>
                            <Form.Group controlId="streetAddress2" size="sm" as={Col}>
                                <Form.Label>
                                    Street Address2:
                            </Form.Label>
                                <Form.Control type='text' name='streetAddress2' placeholder='Colombo 07' onChange={this.onChange} value={this.props.billingInfo['streetAddress2']} required />

                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group controlId="city" size="sm" as={Col}>
                                <Form.Label>
                                    City:
                            </Form.Label>
                                <Form.Control type='text' name='city' placeholder='Colombo' onChange={this.onChange} value={this.props.billingInfo['city']} required />

                            </Form.Group>

                            <Form.Group controlId="zip" size="sm" as={Col}>
                                <Form.Label>
                                    Zip:
                            </Form.Label>
                                <Form.Control type='text' name='zip' placeholder='00700' onChange={this.onChange} value={this.props.billingInfo['zip']} required />

                            </Form.Group>

                        </Form.Row>

                        <Form.Row>
                            <Form.Group controlId="state" size="sm" as={Col}>
                                <Form.Label>
                                    State:
                            </Form.Label>
                                <Form.Control type='text' name='state' placeholder='Western' onChange={this.onChange} value={this.props.billingInfo['state']} required />

                            </Form.Group>
                            <Form.Group controlId="country" size="sm" as={Col}>
                                <Form.Label>
                                    Country:
                            </Form.Label>
                                <Form.Control type='text' name='country' placeholder='Sri Lanka' onChange={this.onChange} value={this.props.billingInfo['country']} required />

                            </Form.Group>
                        </Form.Row>





                        {this.props.children}
                    </Form>
                </div>}
            </div>
        );
    }
};
export default BillingInfo;