import React, {
    PureComponent
} from "react";
import {
    Modal
} from 'shoutout_themes';
import { TemplateGalleryContainer } from './../../../redux/containers/TemplateContainer';

class TemplateGalleryModal extends PureComponent {
    render() {
        const {
            show,
            closeGallery,
        } = this.props;
        return (<Modal show={show}
            onHide={
                closeGallery
            } size="lg">
            <Modal.Header closeButton>
                <Modal.Title> Select Template </Modal.Title> </Modal.Header>
            <Modal.Body>
                <TemplateGalleryContainer />
            </Modal.Body>

        </Modal>);
    }
}
export default TemplateGalleryModal;