const handleErrors = (response) => {
    try {
        if (!response.ok) {
            return Promise.reject(response.statusText);
        }
        return response;
    } catch (e) {
        return Promise.reject(response.statusText);
    }
}

const parseJson = (response) => {
    try {
        return response.json();
    } catch (e) {
        console.error("Cannot parse:", response);
        return Promise.reject("Can not parse");
    }
}

const parseText = (response) => {
    if (response) {
        try {
            return response.text();
        } catch (e) {
            console.error("Cannot parse:", response);
            return Promise.reject("Can not parse");
        }
    }
    return {};
}

const fetchGet = (url, track,headers) => {
    return fetch(url, {
        method: 'GET',
        headers: headers || {},
        isProtected: true,
        showLoader: true,
        track
    }).then(handleErrors).then(parseJson)
}

const fetchGetText = (url, showLoader = true, track) => {
    return fetch(url, {
        method: 'GET',
        headers: {},
        isProtected: true,
        showLoader,
        track
    }).then(handleErrors).then(parseText)
}

const fetchPostText = (url,body, showLoader = true, track) => {
    return fetch(url, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        isProtected: true,
        showLoader,
        body: JSON.stringify(body),
        track
    }).then(handleErrors).then(parseText)
}

const fetchPost = (url, body, track) => {
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        isProtected: true,
        showLoader: true,
        body: JSON.stringify(body),
        track
    }).then(handleErrors).then(parseJson)
}
const fetchPut = (url, body, track) => {
    return fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        isProtected: true,
        showLoader: true,
        body: JSON.stringify(body),
        track
    }).then(handleErrors).then(parseJson)
}
const fetchDelete = (url, body, track) => {
    return fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        isProtected: true,
        showLoader: true,
        body: body ? JSON.stringify(body) : "",
        track
    }).then(handleErrors)
}

const fetchPostMultipart = async (url, body,track) => {
   
    return fetch(url, { headers: {}, method: 'POST', body ,isProtected: true,
    showLoader: true,track}).then(handleErrors).then(parseJson);


}
export { handleErrors, parseJson, fetchGet, fetchPost, fetchPut, fetchDelete, fetchGetText, fetchPostText ,fetchPostMultipart};