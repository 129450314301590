import React, { Component } from "react";
import { Dropdown, DropdownItem, Form } from 'shoutout_themes';
import {map} from 'lodash';
import './BoostrapMultiSelect.css';
class BoostrapMultiSelect extends Component {

    render() {
        const { title, options ,onSelectItem,selectedOptions, customClassName} = this.props;
        return (
            <Dropdown id="multi-select-menu"
                onSelect={onSelectItem}
                className={`multi-select-menu ${customClassName}`}
                variant="link"
                size="sm"
            >
                <Dropdown.Toggle>
                    {title}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {map(options,(item, index) => {
                        return <DropdownItem key={`select-${index}`} data-id={index} eventKey={index} active={selectedOptions[index]}><Form.Check id={index} checked={selectedOptions[index]} label={item.title}/></DropdownItem>
                    })}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}

export default BoostrapMultiSelect;