import React, {Component} from "react";
import EmailSenderIdVerify from './EmailSenderIdVerify';
import InvalidRequest from './InvalidRequest';
import './VerifyPage.css';
class VerifyPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type:props['params']['type']
        };
    }

    _getContent(type){
        switch(type){
            case 'email':{
                return <EmailSenderIdVerify token={this.props['location']['query']['token']}/>;
            }
            default:{
                return <InvalidRequest/>
            }
        }
    }
    render() {
        return (
            <div className="container">
                {this._getContent(this.state.type)}
            </div>
        );
    }
}
export default VerifyPage;
