/**
 * Created by madura on 2/6/17.
 */
import React, { Component } from "react";
import {
    Button,
    Modal,
    Form, Badge, Row, Col, Alert,
    FaIcon
} from 'shoutout_themes';
import ReactDOM from 'react-dom';
import { UtilService } from "./../../services/Services";
import lodash from 'lodash';
import { faChevronDown, faChevronRight } from 'shoutout_themes/es/FaIconsSolid';

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class CreateOrEditPersonDialog extends Component {
    constructor() {
        super();
        this.onSubmit = this.onSubmit.bind(this);
        this.onMobileNumberChange = this.onMobileNumberChange.bind(this);
        this.addTag = this.addTag.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggleViewMore = this.toggleViewMore.bind(this);
        this.state = {
            person: { 'name': '', 'mobile_number': '', 'email': '', 'tags': [] },
            isNewPerson: true,
            mobileNumber: '',
            email: '',
            isMobileNumberValid: true,
            isEmailValid: true,
            alertVisible: false,
            isPersonValid: false,
            viewMore: false,
            customColumnNames: {},
            country: 'lk'
        };
    }

    _init(customColumnNames) {
        this.setState({
            person: { 'name': '', 'mobile_number': '', 'email': '', 'tags': [] },
            isNewPerson: true, mobileNumber: '',
            email: '',
            isMobileNumberValid: true,
            isEmailValid: true,
            alertVisible: false,
            isPersonValid: false,
            viewMore: false,
            customColumnNames: customColumnNames
        });
    }

    onSubmit() {
        let person = this.state.person;
        if (person['mobile_number'] !== '' || person['email'] !== '') {
            this.setState({ alertVisible: false });
            let person = this.state.person;
            //todo: change to assign according to user preference
            if (person['mobile_number'] !== '') {
                person['user_id'] = person['mobile_number'];
            } else {
                person['user_id'] = person['email'];
                delete person['mobile_number'];
            }

            if (person.email === '') {
                delete person.email;
            }
            this.props.onSave(this.state.person);
        } else {
            this.setState({ alertVisible: true });
        }
    }

    handleChange(event) {
        let person = this.state.person;
        let key = event.target.name;
        let stateUpdate = {};
        if (key === 'email') {
            stateUpdate['email'] = event.target.value;
            stateUpdate['isEmailValid'] = emailRegex.test(event.target.value) || event.target.value === '';
            person[key] = stateUpdate['isEmailValid'] ? event.target.value : '';
            stateUpdate['isPersonValid'] = (stateUpdate['isEmailValid'] && person[key] !== '') || (this.state.isMobileNumberValid && this.state.person.mobile_number !== '');
        } else {
            person[key] = event.target.value;
        }
        stateUpdate['person'] = person;
        this.setState(stateUpdate);
    }

    onMobileNumberChange(status, value, countryData, number, id) {
        let stateUpdate = {};
        let person = this.state.person;
        if (status) {
            let country = countryData.name.replace(/[^a-zA-Z ]/gi, '').trim();
            let mobile = number.replace(/[^0-9]/gi, '');
            person['country'] = country;
            person['mobile_number'] = mobile;
            stateUpdate['isMobileNumberValid'] = true;

        } else {
            person['mobile_number'] = '';
            stateUpdate['isMobileNumberValid'] = value.length === 0;
        }
        stateUpdate['mobileNumber'] = value;
        stateUpdate['isPersonValid'] = (stateUpdate['isMobileNumberValid'] && person['mobile_number'] !== '') || (this.state.isEmailValid && this.state.person.email !== '');
        stateUpdate['person'] = person;
        this.setState(stateUpdate);
    }

    addTag(e) {
        e.preventDefault();
        let person = this.state.person;
        let tagVal = ReactDOM.findDOMNode(this.refs.tags).value;
        ReactDOM.findDOMNode(this.refs.tags).value = '';
        person.tags.push(tagVal);
        this.setState({ person: person });
    }

    getIpInfo=(callback)=> {
        UtilService.getIpInfo().then(resp => {
            callback((resp && resp.country) ? resp.country : '')
        });
    }

    toggleViewMore() {
        this.setState({ viewMore: !this.state.viewMore });
    }

    componentWillReceiveProps(nextProps) {
        let customColumnNames = lodash.omit(nextProps.customColumnNames, ['id', 'created_on', 'modified_on', 'last_seen_on', '_created_on', '_modified_on', '_last_seen_on', 'owner_id', 'selected', 'profileImage', '_email_valid', '_mobile_number_valid', '_delete_protected', 'profile_picture', 'facebook_id', 'push_notification_arn']);
        if (nextProps.selectedPerson) {
            let person = lodash.merge(this.state.person, nextProps.selectedPerson);
            this.setState({ person: person, isNewPerson: false, isPersonValid: true, customColumnNames: customColumnNames });
        } else {
            this._init(customColumnNames);
        }
    }

    componentDidMount() {
        this.getIpInfo((country) => {
            this.setState({ country })
        })
    }
    render() {

        return (
            <Modal show={this.props.showCreatePersonDialog} onHide={this.props.closeCreatePersonDialog} size="lg" className="rounded-0 border-0">
                <Modal.Header closeButton className="rounded-0 border-0">
                    <h6 className="font-weight-bold mb-0">{this.state.isNewPerson ? 'Create Person' : 'Edit Person'}</h6>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {this.state.alertVisible && this.state.isNewPerson ? <Alert variant="danger">
                            <h5><strong>Mobile Number</strong> or <strong>Email</strong> is required!</h5>
                        </Alert> : null}
                        <Form>
                            <Form.Group
                                controlId="name"
                            >
                                <Form.Label>Name:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    placeholder="Eg: Sam" onChange={this.handleChange}
                                    value={this.state.person.name}
                                />
                            </Form.Group>
                            <Form.Group controlId="mobile"
                                validationState={this.state.isMobileNumberValid ? null : 'error'}>
                                <Form.Label>Mobile Number:</Form.Label>

                                <Form.MobileNumberInput id="mobile" defaultCountry={'lk'}
                                    disabled={!this.state.isNewPerson}
                                    geoIpLookup={this.getIpInfo}
                                    defaultCountry={this.state.country}
                                    ref="mobile"
                                    onPhoneNumberBlur={this.onMobileNumberChange}
                                    onPhoneNumberChange={this.onMobileNumberChange}
                                    required
                                    inputClassName={`form-control rounded-0 shadow-0 input-control ${this.state.mobileNumber > 0 ? (this.state.isMobileNumberValid ? 'is-valid' : 'is-invalid') : ''}`}
                                    value={this.state.mobileNumber} />
                            </Form.Group>
                            <Form.Group
                                controlId="email" validationState={this.state.isEmailValid ? null : 'error'}
                            >
                                <Form.Label>Email:</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder="Eg: sam@test.com" onChange={this.handleChange}
                                    disabled={!this.state.isNewPerson}
                                    value={this.state.email}
                                />
                            </Form.Group>
                            <Form.Group
                                controlId="tags" size="sm"
                            >
                                <Form.Label>Tags:</Form.Label>
                                <Row>
                                    <Col sm={9}>
                                        <Form.Control
                                            type="tags"
                                            placeholder="premium"
                                            ref="tags" />
                                    </Col>
                                    <Col sm={3}>
                                        <Button variant="dark" onClick={this.addTag} size="sm">Add Tag</Button>
                                    </Col>
                                </Row>
                                <div>{this.state.person.tags.length ? this.state.person.tags.map(tag => {
                                    return (<span key={tag}><Badge>{tag}</Badge>&nbsp;</span>)
                                }) : <p>No tags</p>}</div>
                            </Form.Group>
                            <a className="heading" onClick={this.toggleViewMore}>{this.state.viewMore ? 'View Less' : 'View More'}
                                &nbsp;&nbsp;
                                   <FaIcon icon={this.state.viewMore ? faChevronDown : faChevronRight} />
                            </a>
                            {this.state.viewMore ? Object.keys(this.state.customColumnNames).map((key) => {
                                let column = this.state.customColumnNames[key];
                                return (<Form.Group
                                    controlId={key} key={key}
                                >
                                    <Form.Label>{column.label}:</Form.Label>
                                    <Form.Control
                                        type={column.type === "number" ? "number" : "text"}
                                        name={key}
                                        onChange={this.handleChange}
                                        value={this.state.person[key]}
                                    />


                                </Form.Group>)
                            }) : null}
                        </Form>

                        {this.state.isNewPerson && <div className="text-left text-muted">
                            <small>
                                This person will not receive a confirmation message from ShoutOUT. By clicking on save, you confirm that you have proper permission to send messages to this person.
                               </small>
                            <br /><br />
                        </div>}
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-white rounded-0 border-0">
                    <Button onClick={this.props.closeCreatePersonDialog}
                        disabled={this.props.showLoader}>Cancel</Button>
                    <Button variant="primary" onClick={this.onSubmit}
                        disabled={!this.state.isPersonValid || this.props.showLoader}>{this.props.showLoader ? (this.state.isNewPerson ? 'Creating...' : 'Saving...') : (this.state.isNewPerson ? 'Create' : 'Save')}</Button>
                </Modal.Footer>

            </Modal>
        );
    }
}

export default CreateOrEditPersonDialog;