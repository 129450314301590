import React, { useContext } from "react";
import './PeopleActionBar.css';
import {
    Button,
    FaIcon,
    OverlayTrigger,
    Tooltip
} from 'shoutout_themes';
import { faChartPie, faUserPlus, faUpload, faDownload, faTag, faBullhorn, faTrashAlt, faFilter } from 'shoutout_themes/es/FaIconsSolid';
import SearchPeople from './PeopleSearch';
import { AddTagDialogContainer } from './../../redux/containers/PeopleListContainer';
import { PeopleDeleteContainer } from './../../redux/containers/PeopleListContainer';
import { ShoutOUTService, UtilService } from './../../services/Services';
import { notify } from 'react-notify-toast';
import UserContext from './../../contexts/UserContext';
import { withRouter } from 'react-router-dom';
import Constants from "../../Constants";

const CreateCampaignActionButton = ({ emailVerified, selectAllStatus, selectedSegment, selectedPeople, selectedFilters, isFilterSelected, selectedPeopleCount, peopleList, history, createCampaign }) => {
    return (

        <Button size="sm" variant="transparent"
            className={`ml-2 rounded-0 text-primary ${!emailVerified && 'disabled'}`}
            //disabled={!emailVerified}
            onClick={() => {
                const excludedPeople = selectAllStatus ? peopleList.filter(item => !item._selected).map(item => item.id) : [];
                createCampaign(selectAllStatus, selectedSegment, selectedPeople, selectedFilters, isFilterSelected, selectedPeopleCount, excludedPeople);
                history.push('/engagement/campaigns/create/new');
            }}><FaIcon
                icon={faBullhorn} />&nbsp;&nbsp;Create
    Campaign</Button>

    )
}
const PeopleActionsBar = withRouter(({ onClickSegments, showSegments, showFilters, onClickFilters, selectedPeopleCount, onClickDelete, onSearch, showAddEditPerson, createCampaign, isSelectAll, selectedSegment, clickedAddMoreTags, selectedPeople, searchText, onChangeSearchText, selectAllStatus, selectedFilters, isFilterSelected, clickedImportPeople, peopleList, history }) => {
    const { emailVerified, scopes } = useContext(UserContext);
    const _onClickDownload = () => {
        let requestObj = { filters: [selectedFilters] };
        if (!selectAllStatus && selectedPeopleCount > 0) {
            requestObj.includeIds = peopleList.filter(item => item._selected).map(item => item.id);
        }
        ShoutOUTService.exportContacts(requestObj).then(res => {
            UtilService.downloadCSV(res, 'contacts export.csv').then(res => { }, err => {
                notify.show('Couldn\'t export the file. Try again', 'error');
            })
        }, err => {
            notify.show('Couldn\'t export the file. Try again', 'error');
        });
    }

    // const alertMsg = () =>{
    //     return(

    //     )//Alert msg
    // }

    let isPeopleSelected = selectedPeopleCount > 0;
    return (
        <div className="people-action-bar">
            <div className="d-flex">
                <Button className={`rounded-0 border-0 mr-2 ${showSegments ? "text-white" : "text-orange"}`} size="sm" variant={showSegments ? "orange" : ""} onClick={onClickSegments} ><FaIcon className="mr-2" icon={faChartPie} />Segments</Button>
                <div className="flex-grow-1">
                    <SearchPeople onSearch={onSearch} searchText={searchText} onChangeSearchText={onChangeSearchText} selectedFilters={selectedFilters} />
                </div>
                <div className="ml-2 d-flex">

                    {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.PEOPLE.moduleId].write && !isPeopleSelected && <>
                        <Button className="rounded-0 text-primary" size="sm" variant="transparent" onClick={() => { showAddEditPerson(true) }} ><FaIcon className="mr-2" icon={faUserPlus} />Create Person</Button>
                        <Button className="rounded-0 text-pink" size="sm" variant="transparent" onClick={clickedImportPeople}><FaIcon className="mr-2" icon={faUpload} />Import People</Button>
                    </>}

                    <Button className={`rounded-0 border-0 mr-2 ${showFilters ? "text-white" : "text-success"}`} size="sm" variant={showFilters ? "success" : ""} onClick={onClickFilters} ><FaIcon className="mr-2" icon={faFilter} />Filter</Button>
                    <Button className="rounded-0 text-purple" size="sm" variant="transparent" onClick={_onClickDownload} ><FaIcon className="mr-2" icon={faDownload} />Download</Button>



                    {isPeopleSelected && <>

                        {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.PEOPLE.moduleId].write &&
                            <Button size="sm" variant="transparent" onClick={() => {
                                clickedAddMoreTags(true)
                            }} className="ml-2 rounded-0 text-dark"><FaIcon icon={faTag}
                                />&nbsp;&nbsp;Tag
                        </Button>}
                        {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.PEOPLE.moduleId].delete &&
                            <Button size="sm" variant="transparent"
                                className="ml-2 rounded-0 text-danger"
                                onClick={onClickDelete}><FaIcon
                                    icon={faTrashAlt}
                                />&nbsp;&nbsp;Delete</Button>
                        }
                        {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.CAMPAIGNS.moduleId].write &&
                            <>{
                                emailVerified ?
                                    <Button size="sm" variant="transparent"
                                        className="ml-2 rounded-0 text-primary"
                                        //disabled={!emailVerified}
                                        onClick={() => {
                                            const excludedPeople = selectAllStatus ? peopleList.filter(item => !item._selected).map(item => item.id) : [];
                                            createCampaign(selectAllStatus, selectedSegment, selectedPeople, selectedFilters, isFilterSelected, selectedPeopleCount, excludedPeople);
                                            history.push('/engagement/campaigns/create/new');
                                        }}><FaIcon
                                            icon={faBullhorn} />&nbsp;&nbsp;Create
            Campaign</Button>
                                    : <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Your email is not verified! Please verify to continue.</Tooltip>}>
                                        <span className="d-inline-block">
                                            <Button size="sm" variant="transparent"
                                                className="ml-2 rounded-0 text-primary disabled"
                                            //disabled={!emailVerified}
                                            ><FaIcon
                                                    icon={faBullhorn} />&nbsp;&nbsp;Create
            Campaign</Button>
                                        </span>

                                    </OverlayTrigger>
                            }
                            </>
                        }
                    </>}

                </div>
                <AddTagDialogContainer />
                <PeopleDeleteContainer />
            </div>
        </div>
    );
});


export default PeopleActionsBar;
