import { fetchGet } from './CommonServiceUtils';

const BASE_URL = "https://api.getshoutout.com/analyticservice/";


class ShoutOUTAnalyticsService {

    static getAnalyticsDistribution(fromDate, toDate) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(
                    {
                        gender: {
                            male: 30,
                            female: 70
                        },
                        buyingPower: {
                            low: 20,
                            mid: 50,
                            high: 30
                        },
                        netPromoter: {
                            promoters: 40,
                            passive: 40,
                            detractors: 20
                        }
                    }
                );
            }, 1000)

        });
    }

    static getSalesAndRevenueGrowth(fromDate, toDate, type = "series") {
        return fetchGet(BASE_URL + 'sales?fromDate=' + fromDate + '&toDate=' + toDate + '&select=' + type);
    }
    static getEngagementGrowth(fromDate, toDate, isSeries = true) {
        return fetchGet(BASE_URL + 'engagements?fromDate=' + fromDate + '&toDate=' + toDate + '&select=' + (isSeries ? 'series' : 'count'));
    }
    static getCustomerGrowth(fromDate, toDate, type = "series") {
        return fetchGet(BASE_URL + 'people?fromDate=' + fromDate + '&toDate=' + toDate + '&select=' + type);
    }
    static getMessagesStat(fromDate, toDate, isSeries = true, mask) {
        return fetchGet(BASE_URL + 'messages?fromDate=' + fromDate + '&toDate=' + toDate + '&mask=' + mask + '&select=' + (isSeries ? 'series' : 'count'));
    }
    static getLoyaltyGrowth({ fromDate, toDate, compareFromDate, compareToDate }, type = "series") {
        return fetchGet(BASE_URL + 'loyalty?fromDate=' + fromDate + '&toDate=' + toDate + '&select=' + type + (compareFromDate && compareToDate ? "&compareFromDate=" + compareFromDate + "&compareToDate=" + compareToDate : ""));
    }

    static getPeopleDemographic(fromDate, toDate) {
        return fetchGet(BASE_URL + 'people/demographics?fromDate=' + fromDate + '&toDate=' + toDate);
    }

    static getPeopleStatistics() {
        return fetchGet(BASE_URL + 'people/statistics');
    }

    static getPeopleGrowth(fromDate, toDate) {
        return fetchGet(BASE_URL + 'people/growth?fromDate='+fromDate+'&toDate='+toDate);
    }

    static getCustomEventStat(fromDate, toDate, eventIds, type = 'series', depth = 'hourly') {
        return fetchGet(BASE_URL + 'events?select=' + type + '&fromDate=' + fromDate + '&toDate=' + toDate + '&eventIds=' + eventIds + '&depth=' + depth);
    }
}

export default ShoutOUTAnalyticsService;