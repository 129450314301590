/**
 * Created by madura on 2/7/17.
 */
import React from 'react';
import './../../../../lib/react-multistep/prog-tracker.css';
import Multistep from './../../../../lib/react-multistep/multistep';
import { TemplateNameInputContainer, TemplateGalleryModalContainer } from './../../../../redux/containers/TemplateContainer';
import {
    CampaignSettingsContainer,
    CampaignSummaryContainer,
    CampaignMessageConfigContainer,
    CampaignSelectSegmentContainer,
    CampaignSelectEventContainer,
    CampaignSelectionContainer
} from './../../../../redux/containers/CampaignCreateContainer';


const CreateCampaign = ({ isDisableNextStep1, isDisableNextStep2, isDisableNextStep3, name, isScheduled, launchOn, transports, selectedPeople, sms, email, selectedSegment, onCreateCampaign, showSelectSegment, showSelectEvent, isDisableEventNextBtn, campaignType, activeStep, isDisableSegmentNextBtn, selectedEvent, excludedPeople, selectedFilters,isCreatingCampaign,history,messenger,onChangeActiveStep}) => {
    let steps = [
        {
            name: 'Select',
            isDisableNextBtn: isDisableNextStep1,
            component: <CampaignSelectionContainer />
        }

    ];

    if (showSelectSegment) {
        steps.push({
            name: 'Configure',
            isDisableNextBtn: isDisableSegmentNextBtn,
            component: <CampaignSelectSegmentContainer />
        });
    }
    else if (showSelectEvent) {
        steps.push({
            name: 'Configure',
            isDisableNextBtn: isDisableEventNextBtn,
            component: <CampaignSelectEventContainer />
        });
    }

    steps.push({
        name: 'Settings',
        isDisableNextBtn: isDisableNextStep2,
        component: <CampaignSettingsContainer history={history}/>
    });
    steps.push(
        {
            name: 'Messages',
            isDisableNextBtn: isDisableNextStep3,
            component: <CampaignMessageConfigContainer />
        });
    steps.push({
        name: 'Create',
        nextBtnName: 'Create',
        isDisableNextBtn : isCreatingCampaign,
        showNextBtn: true,
        onClickNext: () => {
            onCreateCampaign(name, isScheduled, launchOn, transports, selectedPeople, sms, email, selectedSegment, campaignType, selectedEvent, excludedPeople, selectedFilters,history,messenger)
        },
        component: <CampaignSummaryContainer />
    });
    
    return (
        <div className='step-progress d-flex flex-column flex-fill'>
            <Multistep steps={steps} activeStep={activeStep} fixedStepBar onChangeActiveStep={onChangeActiveStep}>
               
            </Multistep>
            <TemplateNameInputContainer />
            <TemplateGalleryModalContainer />
        </div>
    );
};
export default CreateCampaign;