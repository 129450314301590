/**
 * Created by madura on 1/27/17.
 */
import { ShoutOUTService } from './../../services/Services';

import { notify } from "react-notify-toast";
import { getPeople } from './PeopleActions';
import { createSegment } from './CommonActions';
import {showAlertBox} from './../actions/UtilActions';
import moment from 'moment';
export const FILE_UPLOADING = 'FILE_UPLOADING';
export const FILE_SELECTED = 'FILE_SELECTED';
export const REMOVE_FILE = 'REMOVE_FILE';
export const FILE_UPLOADED = 'FILE_UPLOADED';
export const FILE_UPLOAD_ERROR = 'FILE_UPLOADED';
export const ADD_CUSTOM_COLUMN = 'ADD_CUSTOM_COLUMN';
export const MAP_HEADER = 'MAP_HEADER';
export const CHECKED_SEGMENT_CREATE = 'CHECKED_SEGMENT_CREATE';
export const SELECT_ADD_CUSTOM_ATTR = 'SELECT_ADD_CUSTOM_ATTR';
export const SET_SEGMENT_NAME = 'SET_SEGMENT';
export const PEOPLE_UPLOADING = 'PEOPLE_UPLOADING';
export const UPDATE_SEGMENT_TAG = 'UPDATE_SEGMENT_TAG';
export const UPDATE_UNIQUE_ID_MAP = 'UPDATE_UNIQUE_ID_MAP';
export const PEOPLE_UPLOADED = 'PEOPLE_UPLOADED';
export const SHOW_ADD_TAG_LOADER = 'SHOW_ADD_TAG_LOADER';
export const SHOW_TAG_DELETE_DIALOG = 'SHOW_TAG_DELETE_DIALOG';
export const SHOW_DELETE_TAG_LOADER = 'SHOW_DELETE_TAG_LOADER';
export const SET_COUNTRY = 'SET_COUNTRY';

const PEOPLE_IMPORT_ACTIONS = {
    SET_TAG_LIST: 'SET_TAG_LIST',
    SHOW_IMPORT_WIZARD: 'SHOW_IMPORT_WIZARD',
    PEOPLE_IMPORTED: 'PEOPLE_IMPORTED'
};

export default PEOPLE_IMPORT_ACTIONS;

let shoutout = new ShoutOUTService();

export const fileSelected = (file) => {
    let fileType = file.type;
    if (fileType) {
        if (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || fileType === 'application/vnd.ms-excel') {
            fileType = 'excel';
        } else if (fileType === 'text/csv' || fileType === 'application/csv' || fileType === 'text/comma-separated-values') {
            fileType = 'csv';
        } else {
            notify.show('Invalid file type. Select EXCEL/CSV file', 'error');
            return;
        }
    } else {
        fileType = file.name.substr(file.name.lastIndexOf('.'));
        if (fileType === '.xls' || fileType === '.xlsx') {
            fileType = 'excel';
        } else if (fileType === '.csv') {

        } else {
            notify.show('Invalid file type. Select EXCEL/CSV file', 'error');
            return;
        }
    }
    return {
        type: FILE_SELECTED,
        fileName: file.name,
        file: file,
        fileType: fileType,
        fileNameOnly: file.name.substr(0, file.name.lastIndexOf('.'))
    }
};

export const removeFile = () => {
    return {
        type: REMOVE_FILE
    }
};

export const fileUploaded = (res) => {
    return {
        type: FILE_UPLOADED,
        fileSample: res.data,
        fileId: res.file_id,
        fileHeaders: res.headers
    }
};

export const uploadFile = (file) => {

    return (dispatch) => {
        dispatch({ type: FILE_UPLOADING });
        const fileFormData = new FormData();
        fileFormData.append("file", file);
        //let reader = new FileReader();
        // reader.onload = (readerEvt) => {
        //     let binaryString = readerEvt.target.result;
            return ShoutOUTService.uploadContactsFile(fileFormData).then(res => {
                dispatch(fileUploaded(res));
            }, err => {
                dispatch({
                    type: FILE_UPLOAD_ERROR
                });
                notify.show('Can not getting attributes. Please select CSV/Excel file', 'error');
            });
        // };
        // reader.readAsBinaryString(file);
    };
};

export const addCustomColumn = (columnName, columnType, mapToHeader) => {
    return {
        type: ADD_CUSTOM_COLUMN,
        columnName: columnName,
        columnType: columnType,
        mapToHeader
    }
};

export const mapHeader = (header, mapWithColumn) => {
    if (mapWithColumn === '_custom') {
        return {
            type: SELECT_ADD_CUSTOM_ATTR,
            isSelect: true
        }
    }
    return {
        type: MAP_HEADER,
        header: header,
        mapWithColumn: (mapWithColumn !== "_null" ? mapWithColumn : null)
    }
};

export const setCountry = (countryCode, countryName) => {
    return {
        type: SET_COUNTRY,
        country: {
            code: countryCode,
            name: countryName
        }
    }
};

export const showTagDeleteDialog = (isShow) => {
    return {
        type: SHOW_TAG_DELETE_DIALOG,
        isShow: isShow
    }
};

export const showDeleteTagLoader = (isShow) => {
    return {
        type: SHOW_DELETE_TAG_LOADER,
        isShow: isShow
    }
};

export const checkedCreateSegment = (isChecked) => {
    return {
        type: CHECKED_SEGMENT_CREATE,
        isChecked: isChecked
    }
};

export const showAddTagLoader = (isShow) => {
    return {
        type: SHOW_ADD_TAG_LOADER,
        isShow: isShow
    }
};


export const selectAddCustomAttr = (isSelect) => {
    return {
        type: SELECT_ADD_CUSTOM_ATTR,
        isSelect: isSelect
    }
};

export const setSegmentName = (segmentName) => {
    return {
        type: SET_SEGMENT_NAME,
        segmentName: segmentName
    }
};

export const updateSegmentTag = (tagIndex) => {
    return {
        type: UPDATE_SEGMENT_TAG,
        tagIndex: tagIndex
    }
};

export const uploadPeople = (columnNameMap, tags, fileId, isCreateSegment, segmentName, segmentTagIndex, userId, country) => {
    return (dispatch) => {
        if (isCreateSegment) {
            if (!tags[segmentTagIndex]) {
                notify.show('Invalid tag selected for segment', 'error');
            }
            else if (!segmentName) {
                notify.show('Segment name is required', 'error');
            }
        }//TODO: mapping validation

        let defaultImportTag = '_import_' + moment().utc().format("YYYY-MM-DD_HH:mm:ss");
        tags.push(defaultImportTag);

        let mappedColumns = {};
        Object.keys(columnNameMap).forEach(column => {
            if (columnNameMap[column].mapWith !== null) {
                mappedColumns[column] = columnNameMap[column];
            }
        });
        mappedColumns['user_id'] = {
            label: 'User ID',
            type: 'string',
            visible: false,
            mapWith: userId
        };

        dispatch({
            type: PEOPLE_UPLOADING
        });
        shoutout.uploadPeople(fileId, mappedColumns, tags, country).then(res => {
            dispatch({
                type: PEOPLE_UPLOADED
            });
            if (isCreateSegment) {
                dispatch(createSegment([{
                    'columnName': 'tags',
                    '_selectedFilter': 7,
                    'searchKey': tags[segmentTagIndex]
                }], segmentName));
            }
            dispatch({
                type: PEOPLE_IMPORT_ACTIONS.PEOPLE_IMPORTED,
                filters: [{
                    'columnName': 'tags',
                    '_selectedFilter': 7,
                    'searchKey': defaultImportTag
                }],
                segmentName
            });
            dispatch(getPeople());

            dispatch(showAlertBox('Import People','Your request will be processed in the background. Please refresh and check in a while.',true,'success'));//Alert for async contact import
            //notify.show('People imported successfully', 'success');
        }, err => {
            dispatch({
                type: PEOPLE_UPLOADED
            });
            notify.show('People can not import', 'error');
        });
    }
};

export const updateUniqueIdMap = (mapWith) => {
    return {
        type: UPDATE_UNIQUE_ID_MAP,
        mapWith: mapWith
    }
};

const setTags = (tagList) => {
    return {
        type: PEOPLE_IMPORT_ACTIONS.SET_TAG_LIST,
        tagList
    }
};

const showImportWizard = (isShow) => {
    return {
        type: PEOPLE_IMPORT_ACTIONS.SHOW_IMPORT_WIZARD,
        isShow
    }
}
export { setTags, showImportWizard };