/**
 * Created by madura on 1/27/17.
 */
import PEOPLE_IMPORT_ACTIONS, {
    FILE_UPLOADING,
    FILE_SELECTED,
    REMOVE_FILE,
    FILE_UPLOADED,
    ADD_CUSTOM_COLUMN,
    MAP_HEADER,
    CHECKED_SEGMENT_CREATE,
    SELECT_ADD_CUSTOM_ATTR,
    SET_SEGMENT_NAME,
    UPDATE_SEGMENT_TAG,
    UPDATE_UNIQUE_ID_MAP,
    FILE_UPLOAD_ERROR,
    PEOPLE_UPLOADING,
    PEOPLE_UPLOADED,
    SHOW_ADD_TAG_LOADER,
    SHOW_TAG_DELETE_DIALOG,
    SHOW_DELETE_TAG_LOADER,
    SET_COUNTRY
} from './../actions/PeopleImportActions';

import update from 'immutability-helper';
import { snakeCase, startCase, forIn, omit } from 'lodash';
import { RECEIVE_COLUMN_NAME_MAP } from './../actions/CommonActions';
import Constants from './../../Constants';
// dev states


/*const initialState = {
 fileName: "x2.xlsx",
 file: null,
 fileType: null,
 fileSample: [{
 "Name": "Madura",
 "Number": "778845713",
 "email": "madura@sqrmobile.com",
 "age": "26"
 }, {
 "Name": "Asanka",
 "Number": "718121914",
 "email": "asanka@sqrmobile.com",
 "age": "27"
 }, {
 "Name": "Tharindu",
 "Number": "94716550512",
 "email": "tharindu@sqrmobile.com",
 "age": "28"
 }],
 fileHeaders: ["Name", "Number", "email", "age"],
 fileId: "abc",
 columnNameMap: {'mobile_number': {label: 'Mobile Number', mapWith: null}, 'email': {label: 'Email', mapWith: null}},
 headerMap: {},
 tags: ['Import_' + new Date().toISOString().replace('T', '-').split('.', 1)[0]],
 isCreateSegment: false,
 isAddMoreTags: false,
 isSelectCustomAttr: false,
 segmentName: null,
 segmentTagIndex: 0,
 _uniqueId: '',
 steps: {
 uploadFile: {fileUploading: false},
 uploadPeople: {peopleUploading: false}
 }
 };*/


const initialState = {
    fileName: null,
    file: null,
    fileType: null,
    fileSample: [],
    fileHeaders: [],
    fileId: null,
    columnNameMap: {
        'mobile_number': { label: 'Mobile Number', mapWith: null, type: 'string' },
        'email': { label: 'Email', mapWith: null, type: 'string' },
        'name': { label: 'Name', mapWith: null, type: 'string' }
    },
    headerMap: {},
    tags: [],
    country: {
        code: null,
        name: null
    },
    isCreateSegment: false,
    showAddTagLoader: false,
    showTagDeleteDialog: false,
    showDeleteTagLoader: false,
    isSelectCustomAttr: false,
    segmentName: null,
    segmentTagIndex: 0,
    _uniqueId: '',
    steps: {
        uploadFile: { fileUploading: false },
        uploadPeople: { peopleUploading: false }
    },
    showImportWizard: false
};
const peopleImport = (state = initialState, action) => {
    switch (action.type) {
        case FILE_UPLOADING: {
            return update(state, {
                steps: {
                    uploadFile: { fileUploading: { $set: true } }
                }
            });
        }
        case FILE_SELECTED: {
            return update(state, {
                fileName: { $set: action.fileName },
                file: { $set: action.file },
                fileType: { $set: action.fileType },
                tags: { $set: [snakeCase(action.fileNameOnly)] }
            });
        }
        case REMOVE_FILE: {
            return update(state, {
                fileName: { $set: null },
                file: { $set: null },
                fileType: { $set: null }
            });
        }
        case FILE_UPLOADED: {
            return update(state, {
                fileSample: { $set: action.fileSample },
                fileId: { $set: action.fileId },
                fileHeaders: { $set: action.fileHeaders },
                steps: {
                    uploadFile: { fileUploading: { $set: false } }
                }
            });
        }

        case FILE_UPLOAD_ERROR: {
            return update(state, {
                steps: {
                    uploadFile: { fileUploading: { $set: false } }
                }
            });
        }

        case ADD_CUSTOM_COLUMN: {
            let updateRequest = {
                columnNameMap: {
                    $merge: {
                        [snakeCase(action.columnName)]: {
                            label: startCase(action.columnName),
                            type: action.columnType,
                            visible: false,
                            mapWith: action.mapToHeader || null
                        }
                    }
                },
                isSelectCustomAttr: { $set: false }
            };
            if (action.mapToHeader) {
                updateRequest.headerMap = { [action.mapToHeader]: { $set: snakeCase(action.columnName) } };
            }

            return update(state, updateRequest);
        }

        case MAP_HEADER: {

            let currentMapping = state.headerMap[action.header];
            let columnNameMapUpdate = {};
            if (action.mapWithColumn) {
                columnNameMapUpdate[action.mapWithColumn] = { mapWith: { $set: action.header } }
            }
            if (currentMapping) {
                columnNameMapUpdate[currentMapping] = { mapWith: { $set: null } }
            }
            if (action.mapWithColumn === 'mobile_number') {
                state = peopleImport(state, { type: UPDATE_UNIQUE_ID_MAP, mapWith: action.header });
            } else if (action.mapWithColumn === 'email' && !state._uniqueId) {
                state = peopleImport(state, { type: UPDATE_UNIQUE_ID_MAP, mapWith: action.header });
            }
            return update(state, {
                headerMap: { [action.header]: { $set: action.mapWithColumn } },
                columnNameMap: columnNameMapUpdate
            });
        }

        case PEOPLE_IMPORT_ACTIONS.SET_TAG_LIST: {
            return update(state, {
                tags: { $set: action.tagList }
            });
        }

        case CHECKED_SEGMENT_CREATE: {
            return update(state, {
                isCreateSegment: { $set: action.isChecked }
            });
        }

        case SET_COUNTRY: {
            return update(state, {
                country: { $set: action.country }
            });
        }

        case SHOW_ADD_TAG_LOADER: {
            return update(state, {
                showAddTagLoader: { $set: action.isShow }
            });
        }


        case SHOW_TAG_DELETE_DIALOG: {
            return update(state, {
                showTagDeleteDialog: { $set: action.isShow }
            });
        }

        case SHOW_DELETE_TAG_LOADER: {
            return update(state, {
                showDeleteTagLoader: { $set: action.isShow }
            });
        }

        case SELECT_ADD_CUSTOM_ATTR: {
            return update(state, {
                isSelectCustomAttr: { $set: action.isSelect }
            });
        }

        case SET_SEGMENT_NAME: {
            return update(state, {
                segmentName: { $set: action.segmentName }
            });
        }
        case UPDATE_SEGMENT_TAG: {
            if (state.tags[action.tagIndex]) {
                return update(state, {
                    segmentTagIndex: { $set: action.tagIndex }
                });
            }
            return state;
        }

        case UPDATE_UNIQUE_ID_MAP: {
            return update(state, {
                _uniqueId: { $set: action.mapWith }
            });
        }

        case PEOPLE_UPLOADING: {
            return update(state, {
                steps: {
                    uploadFile: { peopleUploading: { $set: true } }
                }
            });
        }

        case PEOPLE_UPLOADED: {
            let newInitialState = initialState;
            let newColumnNameMap = state.columnNameMap;
            newColumnNameMap = forIn(newColumnNameMap, column => {
                column.mapWith = null;
            });
            newInitialState.columnNameMap = newColumnNameMap;
            return update(state, {
                $set: newInitialState
            });
        }

        case RECEIVE_COLUMN_NAME_MAP: {
            let newColumnNameMap = forIn(action.columnNameMap, (column) => {
                column.mapWith = null;
            });
            newColumnNameMap = omit(newColumnNameMap, Constants.RESERVED_ATTRIBUTES);
            return update(state, {
                columnNameMap: { $merge: newColumnNameMap }
            })
        }
        case PEOPLE_IMPORT_ACTIONS.SHOW_IMPORT_WIZARD: {
            return update(state, {
                showImportWizard: { $set: action.isShow }
            });
        }
        default:
            return state;
    }
};

export default peopleImport;