// import {
//     connect
// } from '';
import {connect} from './../../contexts/ContextUtils';
import {
    saveTemplate,
    showTemplateNameInput, onChangeTemplateName, chooseTemplate, deleteTemplate,loadTemplates,filterTemplate
} from './../actions/TemplateActions';
import {
    loadTemplate
} from './../actions/CampaignCreateActions';

import { TemplateNameInput, TemplateGallery ,TemplatePage,TemplateGalleryModal} from './../../components/engagement_module/templates/TemplateComponents';


const TemplateNameInputContainer = connect(
    (state) => { //map state to props
        return {
            show: state.template.showTemplateNameInput,
            templateName: state.template.templateName,
            templateContent: state.template.templateContent,
            templateType: state.template.templateType,
            showLoader: state.template.isTemplateSaving
        };
    },

    (dispatch, ownProps) => {
        return {
            closeTemplateNameInput: () => {
                dispatch(showTemplateNameInput(false));
            },
            onChangeTemplateName: (name) => {
                dispatch(onChangeTemplateName(name));
            },
            saveTemplate: (templateContent, templateType, templateName) => {
                dispatch(saveTemplate(templateContent, templateType, templateName));
            }
        };

    }
)(TemplateNameInput);

const TemplateGalleryContainer = connect(
    (state) => { //map state to props
        return {
            show: state.template.showTemplateGallery,
            showLoader: state.template.isTemplateLoading,
            templates: state.template.templates,
            templateType: state.template.templateType,
            isDeleting:state.template.actions.delete.isDeleting,
            filteredTemplate:state.template.actions.filter.template
        };
    },

    (dispatch, ownProps) => {
        return {
            
            loadTemplate: (template, templateType) => {
                dispatch(loadTemplate(template, templateType));
                dispatch(chooseTemplate(false));
            },
            deleteTemplate: (templateId) => {
                dispatch(deleteTemplate(templateId))
            },
            filterTemplate:(selectedTemplate)=>{
                dispatch(filterTemplate(selectedTemplate))
            }
        };

    }
)(TemplateGallery);


const TemplatePageContainer = connect(
    (state) => { //map state to props
    
        return {
            show: state.template.showTemplateGallery,
            showLoader: state.template.isTemplateLoading,
            templates: state.template.templates,
            templateType: state.template.templateType,
            isDeleting:state.template.actions.delete.isDeleting
        };
    },

    (dispatch, ownProps) => {
        return {
            loadTemplates:(transportType)=>{
                dispatch(loadTemplates(transportType));
            }
        };

    }
)(TemplatePage);

const TemplateGalleryModalContainer = connect(
    (state) => { //map state to props
        return {
            show: state.template.showTemplateGallery
        };
    },

    (dispatch, ownProps) => {
        return {
            closeGallery: () => {
                dispatch(chooseTemplate(false));
            }
        };

    }
)(TemplateGalleryModal);

export {
    TemplateNameInputContainer, TemplateGalleryContainer,
    TemplatePageContainer,TemplateGalleryModalContainer
};
