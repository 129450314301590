import React, { Component } from 'react';

import { BarChart, ResponsiveContainer, YAxis, XAxis, Legend, Tooltip, Bar } from 'recharts';
import {LoadingComponent} from './UtilComponents';
class BarChartWidget extends Component {

    render() {
        const { dataset, title, isLoading = false, barOptions = { xAxis: {}, yAxis: {}, bar: [] } ,layout="vertical"} = this.props;


        return (
            <div className="bar-chart-widget">
           {title && <h6 className="chart_title text-center font-weight-bold">{title}</h6>}
                {isLoading ? <LoadingComponent/> : <ResponsiveContainer width="100%" height={250}>
                    <BarChart
                        layout={layout}
                        data={dataset}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >

                        <XAxis type={barOptions.xAxis.type || "number"} dataKey={barOptions.xAxis.key} tick={{ fontSize: 10 }} />
                        <YAxis allowDecimals={false} type={barOptions.yAxis.type || "category"} dataKey={barOptions.yAxis.key} tick={{ fontSize: 10 }} />
                        <Tooltip />
                        <Legend />
                        {barOptions.bar.map(item => {
                            return (<Bar key={item.key} dataKey={item.key} fill={item.color} name={item.label} />)
                        })}
                    </BarChart>
                </ResponsiveContainer>
                }
            </div>
        );
    }
};

export default BarChartWidget;