import React, { PureComponent } from "react";
import { Row, Col, Button, Form,  DropdownButton, Dropdown } from 'shoutout_themes';
import DatePicker from './../../lib/react-16-bootstrap-date-picker';
import moment from 'moment';
class DateRangeSelectorWithOptions extends PureComponent {
    constructor() {
        super();
        this.dateRanges = [{ label: 'Last 7 days', value: 7 }, { label: 'Last 14 days', value: 14 }, { label: 'Last 28 days', value: 28 }, { label: 'Last 90 days', value: 90 }, { label: 'Custom', value: 0 }];
        this._onFromDateChange = this._onFromDateChange.bind(this);
        this._onToDateChange = this._onToDateChange.bind(this);
        this._onClickLoad = this._onClickLoad.bind(this);
        this._changeDateRangeByOption = this._changeDateRangeByOption.bind(this);
        this.state = {
            fromDate: moment().subtract(6, 'days').format("YYYY-MM-DD"),
            toDate: moment().format("YYYY-MM-DD"),
            selectedDateRange: this.dateRanges[2]
        };
    }
    _onFromDateChange(value, formatted) {
        this.setState({ fromDate: formatted });
    }
    _onToDateChange(value, formatted) {
        this.setState({ toDate: formatted });
    }
    _onClickLoad(e) {
        e.preventDefault();
        this.props.onClickLoad(this.state.fromDate, this.state.toDate);
    }
    componentDidMount() {
        this.setState({ fromDate: this.props.defaultFromDate || this.state.fromDate, toDate: this.props.defaultToDate || this.state.toDate, selectedDateRange: this.props.selectedDateRange || this.state.selectedDateRange });
    }
    _changeDateRangeByOption(dateRangeIndex) {
  
        if (dateRangeIndex) {
            const dateRange=this.dateRanges[parseInt(dateRangeIndex)]
            let fromDate = moment().subtract(dateRange.value, 'days').format("YYYY-MM-DD"),
                toDate = moment().format("YYYY-MM-DD");
                
            this.setState({ selectedDateRange: dateRange, fromDate, toDate });

            this.props.onClickLoad(fromDate, toDate);

        } 
    }
    render() {
        return (
            <div className="date-range-selector">
                <Row>
                    <Col className="my-auto" sm={2} xs={12}>
                        <DropdownButton onSelect={this._changeDateRangeByOption}
                            title={this.state.selectedDateRange.label}
                            id="date-selection"
                            size="sm"
                            variant="link"
                        >
                            {this.dateRanges.map((item ,index)=> {
                                return <Dropdown.Item key={item.value} eventKey={index}>{item.label}</Dropdown.Item>
                            })}
                        </DropdownButton>
                    </Col>
                    

                        {this.state.selectedDateRange.value === 0 &&  <Col><Form horizontal onSubmit={this._onClickLoad}>

                                <div className="d-flex justify-content-between">
                                    
                                        <div controlId="fromDate" size="sm" className="d-flex">
                                            <div className="my-auto" as={Form.Label}>
                                                From:
                                            </div>
                                           
                                                <DatePicker id="fromDate" value={this.state.fromDate}
                                                    onChange={this._onFromDateChange}
                                                    dateFormat="YYYY-DD-MM" showClearButton={false} />
                                        </div>

                                  
                                 
                                        <div controlId="toDate" size="sm" className="d-flex">
                                            <div className="my-auto" as={Form.Label}>
                                                To:
                                            </div>
                                         
                                                <DatePicker id="toDate" value={this.state.toDate}
                                                    onChange={this._onToDateChange}
                                                    dateFormat="YYYY-DD-MM" showClearButton={false} />
                            

                                        </div>
                                  
                                   
                                    
                                        <Button size="sm" variant="primary" onClick={this._onClickLoad} type="button">Load</Button>
                                   
                                </div>

                            </Form> </Col>}
                   
                </Row>

            </div>
        );
    }
}

export default DateRangeSelectorWithOptions;