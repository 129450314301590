import React, {
    PureComponent
} from "react";
import { Button, Card, Row, Col, CardColumns, InputGroup, Typeahead, FaIcon } from 'shoutout_themes';
import { faSearch, faTrash } from 'shoutout_themes/es/FaIconsSolid';
import moment from 'moment';
import {
    find
} from 'lodash';
import Avatar from 'react-user-avatar';
import { LoadingRocket } from './../../utils/UtilComponents';
import { ConfirmBox } from './../../utils/UtilComponents';
import UserContext from './../../../contexts/UserContext';
import './TemplateGallery.css';
import Constants from "../../../Constants";
class TemplateGallery extends PureComponent {
    constructor() {
        super();
        this.state = { showConfirmDelete: false };
        this._selectTemplate = this._selectTemplate.bind(this);
        this._showConfirmDelete = this._showConfirmDelete.bind(this);
        this._onCloseConfirmDelete = this._onCloseConfirmDelete.bind(this);

    }

    _selectTemplate(templateId) {
        this.props.loadTemplate(find(this.props.templates, {
            id: templateId
        }), this.props.templateType);
    }

    _showConfirmDelete(templateId) {
        this.setState({ showConfirmDelete: true, selectedTemplateId: templateId });
    }
    _onCloseConfirmDelete(selectedTemplateId = null) {
        this.setState({ showConfirmDelete: false, selectedTemplateId });
    }

    render() {
        const {
            showLoader,
            templates,
            deleteTemplate,
            isDeleting,
            hideSelect,
            filterTemplate,
            filteredTemplate
        } = this.props;
        const templateList = filteredTemplate ? [filteredTemplate] : templates;
        return (
            <UserContext.Consumer>
                {({ scopes }) => (


                    <div>
                        {showLoader ? <LoadingRocket /> :
                            <div className="template-gallery">
                                <Row>
                                    {
                                        templateList.length ?
                                            <>
                                                <Col xs={12} className="mb-4">
                                                    <InputGroup className="flex-nowrap flex-row">
                                                        <Typeahead
                                                            options={templateList}
                                                            placeholder="Template name"
                                                            labelKey={option => `${option.name}`}
                                                            onChange={filterTemplate}
                                                            clearButton
                                                            selected={filteredTemplate ? [filteredTemplate] : []}
                                                        />

                                                        <InputGroup.Append className="bring-to-top">
                                                            <Button className="rounded-circle search-icon" variant="primary"><FaIcon icon={faSearch} /></Button>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </Col>
                                                <Col>
                                                    <CardColumns>
                                                        {
                                                            templateList.map(item => {
                                                                return (

                                                                    <Card className="template-container border-0" key={item.id}>
                                                                        <Card.Body className="shadow-sm">
                                                                            {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.CAMPAIGNS.moduleId].delete &&
                                                                                <Button className="position-absolute top-right" variant="link" size="xsmall" onClick={
                                                                                    () => { this._showConfirmDelete(item.id); }
                                                                                }>
                                                                                    <FaIcon size="sm" icon={faTrash} className="text-danger" />
                                                                                </Button>
                                                                            }
                                                                            <Row>

                                                                                <Col sm={2} className="my-auto mr-3">
                                                                                    <Avatar className="d-block" name={(item.name.toUpperCase()).charAt(0)} size={40} />
                                                                                </Col>

                                                                                <Col sm={9} className="my-auto">
                                                                                    <h6 className="text-truncate mb-0 font-weight-bold">{item.name}</h6>
                                                                                    <small className="d-block template-name text-muted">{moment(item.createdOn).format("YYYY-MM-DD HH:mm")}</small>
                                                                                </Col>
                                                                            </Row>
                                                                            {item.type === "SMS" && <Row className="m-1">
                                                                                <small className="text-truncate bg-label">{item.content.body || null}</small>
                                                                            </Row>}



                                                                            {hideSelect ? null : <div className="text-center">
                                                                                <br />
                                                                                <Button variant="info" size="sm"
                                                                                    onClick={
                                                                                        () => { this._selectTemplate(item.id); }
                                                                                    } > Select </Button>
                                                                            </div>
                                                                            }


                                                                        </Card.Body>
                                                                    </Card>

                                                                );

                                                            })
                                                        }
                                                    </CardColumns>
                                                </Col>
                                            </>
                                            : <Col xs={12}>No templates found.</Col>
                                    }
                                </Row>

                                <ConfirmBox show={this.state.showConfirmDelete} onHide={this._onCloseConfirmDelete} title="Confirm Delete" message="Are you sure that you want to delete this template?" confirmCallback={() => { deleteTemplate(this.state.selectedTemplateId); this._onCloseConfirmDelete(); }}
                                    disableActions={isDeleting} />

                            </div>}
                    </div>
                )}
            </UserContext.Consumer>

        );
    }
};

export default TemplateGallery;
