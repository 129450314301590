import update from 'immutability-helper';
import BILLING_ACTIONS from './../actions/BillingActions';
const initialState = {
    plans: [],
    currentPlan: {
        id: 'sp_1',
        smsPlanId: 'sps_2',
        emailPlanId: 'spe_2'
    },
    billingInfo: {
        cardHolderName: '',
        streetAddress: '',
        streetAddress2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        email: '',
        phone: ''
    },
    isUpdating: false,
    invoices: [],
    creditConversionMap:{
        'lk':1,
        'no':5.75
    },
    selectedPlan:{

    }
};
const BillingReducer = (state = initialState, action) => {
    switch (action.type) {


        case BILLING_ACTIONS.UPDATING_BILLING_INFO: {
            return update(state, {
                isUpdating: {
                    $set: true
                }
            });
        }

        case BILLING_ACTIONS.RECEIVED_BILLING_INFO: {
            return update(state, {
                billingInfo: {
                    $set: action.billingInfo||{}
                },
                isUpdating: {
                    $set: false
                }
            });
        }
        case BILLING_ACTIONS.RECEIVED_INVOICES: {
            return update(state, {
                invoices: {
                    $set: action.invoiceList
                }
            });
        }

        case BILLING_ACTIONS.SELECT_PURCHASE_PLAN:{
            return update(state, {
                selectedPlan: {
                    $set: action.selectedPlan
                }
            });
        }

        case BILLING_ACTIONS.RECEIVED_BILLING_PLANS:{
            return update(state,{
                plans:{$set:action.plans}
            })
        }
        default:
            return state;
    }
};

export default BillingReducer;
