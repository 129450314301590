/**
 * Created by madura on 3/2/17.
 */
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import BasicEmailWrapperTemplate from '../templates/BasicEmailWrapperTemplate';
import ReactMustache from './../components/custom/ReactMustache';
import string from 'lodash';
import {exportHtml} from 'shoutout_email_editor';
class HtmlCompilerService {
    static compileStateToHtml(editorState, sampleContact) {
        let basicPreview = ReactDOMServer.renderToStaticMarkup(exportHtml(editorState));

        let data = string.unescape(ReactDOMServer.renderToStaticMarkup(BasicEmailWrapperTemplate({
            emailBody: (sampleContact ? <ReactMustache template={basicPreview} data={sampleContact}/> : basicPreview)
        })));
        return data;
    }
}

export default HtmlCompilerService;