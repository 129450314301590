/**
 * Created by madura on 2/9/17.
 * based on https://github.com/yury-dymov/react-bootstrap-time-picker
 */
import React      from 'react';
import {Form} from 'shoutout_themes';
import {timeToInt, timeFromInt} from 'time-number';
import PropTypes from 'prop-types';
const propTypes = {
    end: PropTypes.string,
    format: PropTypes.number,
    initialValue: PropTypes.any,
    onChange: PropTypes.func,
    start: PropTypes.string,
    step: PropTypes.number,
    value: PropTypes.any,
};

function TimePicker({
    end = '23:59',
    format = 12,
    initialValue = '00:00',
    onChange = () => {
    },
    start = '00:00',
    step = 30,
    value = null,
    ...rest
}) {
    function generateFormattedTime(time) {
        const ret = time;
        if (format === 24) {
            return ret;
        }

        const found = ret.match(/^(\d+):/);
        const hour = parseInt(found[1], 10);

        if (hour === 0) {
            return `${ret.replace(/^\d+/, '12')} AM`;
        } else if (hour < 12) {
            return `${ret} AM`;
        } else if (hour === 12) {
            return `${ret} PM`;
        }

        const newHour = hour < 22 ? `0${hour - 12}` : (hour - 12).toString();

        return `${ret.replace(/^\d+/, newHour)} PM`;
    }

    function generateTimeRange() {
        const times = [];
        const iend = timeToInt(end, false);

        for (let i = timeToInt(start, false); i <= iend; i += step * 60) {
            times.push(i);
        }

        return times;
    }

    function listTimeOptions() {
        return generateTimeRange().map((unformattedTime) => {
            let time = timeFromInt(unformattedTime, false);
            const formattedTime = generateFormattedTime(time);

            return {
                key: time,
                val: formattedTime,
            };
        });
    }

    const timeOptions = listTimeOptions();
    const optionWidgets = timeOptions.map(({key, val}) => (

        <option key={key} value={key}>
            {val}
        </option>
    ));

    let currentValue = value || initialValue;
    return (
        <Form.Control
            as="select"
            onChange={(e) => {
                onChange(e.target.value);
            }}
            value={currentValue}
            {...rest}
        >
            {optionWidgets}
        </Form.Control>
    );
}

TimePicker.propTypes = propTypes;

export default TimePicker;
