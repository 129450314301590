import Constants from './../Constants';
const { USER_ACCESS_CONTROLS } = Constants;
const DELETE_PERMISSION = {
    read: true,
    write: true,
    delete: true
}
const READ_WRITE_ONLY={
    read:true,
    write:true
}
const READ_ONLY={
    read:true
}
const READ_PERMISSION = {
    read: true,
    write: false,
    delete: false
}
const WRITE_PERMISSION = {
    read: true,
    write: true,
    delete: false
}

const DEFAULT_ACCESS_KEYS = {
    read: false,
    write: false,
    delete: false
}
const DEFAULT_ACCESS_READ_WRITE_ONLY = {
    read: false,
    write: false
}
const DEFAULT_ACCESS_READ_ONLY = {
    read: false
}

const ACCESS_CONTROL_DATA = [
    {
        moduleId: USER_ACCESS_CONTROLS.MODULES.APPS.moduleId,
        ...DEFAULT_ACCESS_KEYS
    },
    {
        moduleId: USER_ACCESS_CONTROLS.MODULES.CAMPAIGNS.moduleId,
        ...DEFAULT_ACCESS_KEYS
    },
    // {
    //     moduleId: USER_ACCESS_CONTROLS.MODULES.COMMON.moduleId,
    //     ...DEFAULT_ACCESS_KEYS
    // },
    {
        moduleId: USER_ACCESS_CONTROLS.MODULES.LITE_MESSAGES.moduleId,
        ...DEFAULT_ACCESS_READ_WRITE_ONLY
    },
    {
        moduleId: USER_ACCESS_CONTROLS.MODULES.LOYALTY_ANALYTICS.moduleId,
        ...DEFAULT_ACCESS_READ_ONLY
    },
    {
        moduleId: USER_ACCESS_CONTROLS.MODULES.LOYALTY_CONFIGURATIONS.moduleId,
        ...DEFAULT_ACCESS_READ_WRITE_ONLY
    },
    {
        moduleId: USER_ACCESS_CONTROLS.MODULES.LOYALTY_POINTS.moduleId,
        ...DEFAULT_ACCESS_KEYS
    },
    {
        moduleId: USER_ACCESS_CONTROLS.MODULES.LOYALTY_REWARDS.moduleId,
        ...DEFAULT_ACCESS_KEYS
    },
    {
        moduleId: USER_ACCESS_CONTROLS.MODULES.LOYALTY_TRANSACTIONS.moduleId,
        ...DEFAULT_ACCESS_READ_ONLY
    },
    {
        moduleId: USER_ACCESS_CONTROLS.MODULES.LOYALTY_USERS.moduleId,
        ...DEFAULT_ACCESS_READ_WRITE_ONLY
    },
    {
        moduleId: USER_ACCESS_CONTROLS.MODULES.PEOPLE.moduleId,
        ...DEFAULT_ACCESS_KEYS
    },
    {
        moduleId: USER_ACCESS_CONTROLS.MODULES.POLL_COMPETITION.moduleId,
        ...DEFAULT_ACCESS_KEYS
    },
    {
        moduleId: USER_ACCESS_CONTROLS.MODULES.POLL_EXPORTS.moduleId,
        ...DEFAULT_ACCESS_KEYS
    },
    {
        moduleId: USER_ACCESS_CONTROLS.MODULES.POLL_REPORTS.moduleId,
        ...DEFAULT_ACCESS_KEYS
    },
    {
        moduleId: USER_ACCESS_CONTROLS.MODULES.RESPONSE.moduleId,
        ...DEFAULT_ACCESS_READ_ONLY
    },
    // {
    //     moduleId: USER_ACCESS_CONTROLS.MODULES.ROOT.moduleId,
    //     ...DEFAULT_ACCESS_KEYS
    // },
    // {
    //     moduleId: USER_ACCESS_CONTROLS.MODULES.SYSTEM.moduleId,
    //     ...DEFAULT_ACCESS_KEYS
    // }
];

const USER = [
    {
        moduleId: USER_ACCESS_CONTROLS.MODULES.APPS.moduleId,
        ...DELETE_PERMISSION
    },
    {
        moduleId: USER_ACCESS_CONTROLS.MODULES.CAMPAIGNS.moduleId,
        ...DELETE_PERMISSION
    },
    {
        moduleId: USER_ACCESS_CONTROLS.MODULES.LITE_MESSAGES.moduleId,
        ...READ_WRITE_ONLY
    },
    {
        moduleId: USER_ACCESS_CONTROLS.MODULES.LOYALTY_ANALYTICS.moduleId,
        ...READ_ONLY
    },
    {
        moduleId: USER_ACCESS_CONTROLS.MODULES.LOYALTY_CONFIGURATIONS.moduleId,
        ...READ_WRITE_ONLY
    },
    {
        moduleId: USER_ACCESS_CONTROLS.MODULES.LOYALTY_POINTS.moduleId,
        ...DELETE_PERMISSION
    },
    {
        moduleId: USER_ACCESS_CONTROLS.MODULES.LOYALTY_REWARDS.moduleId,
        ...DELETE_PERMISSION
    },
    {
        moduleId: USER_ACCESS_CONTROLS.MODULES.RESPONSE.moduleId,
        ...READ_ONLY
    },
    {
        moduleId: USER_ACCESS_CONTROLS.MODULES.LOYALTY_TRANSACTIONS.moduleId,
        ...READ_ONLY
    },
    {
        moduleId: USER_ACCESS_CONTROLS.MODULES.LOYALTY_USERS.moduleId,
        ...READ_WRITE_ONLY
    },
    {
        moduleId: USER_ACCESS_CONTROLS.MODULES.PEOPLE.moduleId,
        ...DELETE_PERMISSION
    }
]

const COLLECT_USER = [
    {
        moduleId: USER_ACCESS_CONTROLS.MODULES.LOYALTY_USERS.moduleId,
        ...READ_WRITE_ONLY
    }
]
export default {
    user: USER,
    collect: COLLECT_USER
}

export { ACCESS_CONTROL_DATA }