import { connect } from './../../contexts/ContextUtils';
import { BillingPage, BillingPlans, BillingInvoices, BillingPurchasePlan } from './../../components/billing/BillingComponents';
import { requestInvoices, navigateToPurchase } from './../actions/BillingActions';
import {showAlertBox} from './../actions/UtilActions';

const BillingPageContainer = connect(
    (state) => {//map state to props
        return {
        };
    },
    (dispatch, ownProps) => {// map dispatch to props
        return {
           // init: () => { dispatch(requestBillingInfo()) },
            navigateToPurchase: (selectedPlan) => { dispatch(navigateToPurchase(selectedPlan)) }
        }
    }
)(BillingPage);

const BillingPurchasePlanContainer = connect(
    (state) => {//map state to props
        return {
            selectedPlan: state.billing.selectedPlan
        };
    },
    (dispatch, ownProps) => {// map dispatch to props
        return {
            showAlert:(title,message,isShow,alertType)=>dispatch(showAlertBox(title,message,isShow,alertType))
        }
    }
)(BillingPurchasePlan);

const BillingPlansContainer = connect(
    (state) => {//map state to props
        return {
            plans:state.billing.plans
        };
    },
    (dispatch, ownProps) => {// map dispatch to props
        return {
        }
    }
)(BillingPlans);

const BillingInvoicesContainer = connect(
    (state) => {//map state to props
        return {
            invoiceList: state.billing.invoices
        };
    },
    (dispatch, ownProps) => {// map dispatch to props
        return {
            requestInvoices: () => { dispatch(requestInvoices()) }
        }
    }
)(BillingInvoices);


export default BillingPageContainer;
export {
    BillingPlansContainer,
    BillingInvoicesContainer,
    BillingPurchasePlanContainer
}