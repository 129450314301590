/**
 * Created by madura on 2/20/17.
 */
import React from 'react';
import { Form, Card, Row, Col } from 'shoutout_themes';

const CampaignSelectEvent = ({ events, onSelectEvent, selectedEvent }) => {
    return (
        <Row className="justify-content-center">
            <Col lg={8} sm={10} xs={12}>
                <Card className="text-left">
                    <Card.Body>
                        <br />
                        <p>Select an event which you want to trigger messages</p>
                        <Form.Control as="select" required onChange={onSelectEvent} value={selectedEvent}>
                            <option value="" disabled>Select Event</option>
                            {events.map(event => {
                                return (<option key={event._id} value={event._id}>{event.title}</option>)
                            })}
                        </Form.Control>
                    
                        <br />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
};
export default CampaignSelectEvent;
