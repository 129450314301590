/**
 * Created by madura on 1/24/17.
 */
import PeopleListView from './../../components/people/PeopleList';
import PeopleActionsBar from './../../components/people/PeopleActionBar';
import PeopleActionsBarOnboarding from './../../components/people/PeopleActionBarOnboarding';
import ConfirmDeleteDialog from './../../components/people/PeopleDelete';
import PeopleFilters from './../../components/people/PeopleFiltersV2';
import PeopleSegments from './../../components/people/PeopleSegments';
import CreateOrEditPersonDialog from './../../components/people/PersonCreateOrEdit';
import PeoplePageContent from './../../components/people/PeoplePageContent';
import PersonProfile from './../../components/people/profile/PersonProfile';
import PeopleSegmentCreate from './../../components/people/PeopleSegmentCreate';
import AddTagDialog from './../../components/people/PeopleTag';
import PeopleGettingStarted from './../../components/people/PeopleGettingStarted';
import { connect } from './../../contexts/ContextUtils';
import {
    sortPeople,
    selectAll as clickSelectAll,
    selectPeople,
    showConfirmDelete,
    deleteSelectedPeople,
    search,
    applySelectedFilters,
    showAddEditPerson,
    createPerson,
    createCampaign,
    onSelectSegment,
    createSegmentName,
    loadMorePeople,
    createTag,
    deleteTag,
    clearAllFilters,
    onChangeSearchText,
    showAddTagDialog,
    getPeople
} from './../actions/PeopleActions';
import {
    deleteSegment,
    createSegment
} from './../actions/CommonActions';
import {
    showTagDeleteDialog,
    showImportWizard
} from './../actions/PeopleImportActions';
import {
    showSendMessage,
    showAlertBox
} from './../actions/UtilActions';
import {
    find, isEqual, map, omit
} from 'lodash';
import Constants from './../../Constants';
import { withRouter } from 'react-router-dom';
const shouldShowGettingStarted = (state) => {
    return !state.people.peopleCount && !state.people.searchText && !state.people.selectedFilters.length && !state.people.isLoadingPeople;
};
const PeopleListContainer = connect(
    (state) => { //map state to props
        return {
            peopleList: state.people.peopleList,
            peopleCount: state.people.peopleCount,
            totalPeopleCount: state.people.totalPeopleCount,
            sortBy: state.people.sortBy,
            sortDirection: state.people.sortDirection,
            selectAll: state.people.selectAll,
            selectAllStatus: state.people.selectAllStatus,
            selectedPeopleCount: state.people.selectedPeopleCount,
            isLoadingPeople: state.people.isLoadingPeople,
            selectedFilters: state.people.selectedFilters,
            showPeopleLoader: state.people.showPeopleLoader,
            showGettingStartedGuide: shouldShowGettingStarted(state),
            isDisableSelectAll: state.people.isDisableSelectAll,
            tagList: map(state.common.tags, (item) => item.label)
        }
    },
    (dispatch, ownProps) => { // map dispatch to props
        return {
            sort: ({
                sortBy,
                sortDirection
            }) => dispatch(sortPeople({
                sortBy,
                sortDirection
            })),
            onClickSelectAll: (isChecked, selectAll, selectAllStatus) => dispatch(clickSelectAll(isChecked, selectAll, selectAllStatus)),
            selectPeople: (personData, isChecked, rowIndex) => dispatch(selectPeople(personData, isChecked, rowIndex)),
            showAddEditPerson: (isShow, person) => dispatch(showAddEditPerson(isShow, person)),
            loadMorePeople: (lastEvalKey, selectedFilters) => {
                dispatch(loadMorePeople(lastEvalKey, selectedFilters));
            },
            showSendMessage: (contact) => dispatch(showSendMessage(true, contact, '', 'people', Constants.TRANSPORT_SMS))
        }
    }
)(PeopleListView);

export default PeopleListContainer;

export const PeopleActionsContainer = connect(
    (state) => { //map state to props
        return {
            selectedPeopleCount: state.people.selectedPeopleCount,
            showConfirmDeleteDialog: state.people.actions.delete.showConfirmDeleteDialog,
            isSelectAll: state.people.selectAll,
            selectedSegment: state.people.selectedSegment,
            selectedPeople: state.people.selectedPeople,
            searchText: state.people.searchText,
            selectAllStatus: state.people.selectAllStatus,
            selectedFilters: state.people.selectedFilters,
            isFilterSelected: state.people.isFilterSelected,
            peopleList: state.people.peopleList
        }
    },
    (dispatch, ownProps) => {
        return {
            onClickDelete: () => dispatch(showConfirmDelete(true)),
            onSearch: (searchText, selectedFilters) => dispatch(search(searchText, selectedFilters)),
            onChangeSearchText: (searchText) => dispatch(onChangeSearchText(searchText)),
            showAddEditPerson: (isShow) => dispatch(showAddEditPerson(isShow)),
            createCampaign: (selectAllStatus, selectedSegment, selectedPeople, selectedFilters, isFilterSelected, selectedPeopleCount, excludedPeople) => {
                dispatch(createCampaign(selectAllStatus, selectedSegment, selectedPeople, selectedFilters, isFilterSelected, selectedPeopleCount, excludedPeople))
            },
            clickedAddMoreTags: (isShow) => dispatch(showAddTagDialog(isShow)),
            clickedImportPeople: () => dispatch(showImportWizard(true))
        }
    }
)(PeopleActionsBar);

export const PeopleActionsOnboardingContainer = connect(
    (state) => { //map state to props
        return {
            selectedPeopleCount: state.people.selectedPeopleCount,
            showConfirmDeleteDialog: state.people.actions.delete.showConfirmDeleteDialog,
            isSelectAll: state.people.selectAll,
            selectedSegment: state.people.selectedSegment,
            selectedPeople: state.people.selectedPeople,
            searchText: state.people.searchText,
            selectAllStatus: state.people.selectAllStatus,
            selectedFilters: state.people.selectedFilters,
            isFilterSelected: state.people.isFilterSelected,
            peopleList: state.people.peopleList,
            contactsAvailable: state.people.peopleCount > 0
        }
    },
    (dispatch, ownProps) => {
        return {
            onClickDelete: () => dispatch(showConfirmDelete(true)),
            onSearch: (searchText, selectedFilters) => dispatch(search(searchText, selectedFilters)),
            onChangeSearchText: (searchText) => dispatch(onChangeSearchText(searchText)),
            showAddEditPerson: (isShow) => dispatch(showAddEditPerson(isShow)),
            createCampaign: (selectAllStatus, selectedSegment, selectedPeople, selectedFilters, isFilterSelected, selectedPeopleCount, excludedPeople) => {
                dispatch(createCampaign(selectAllStatus, selectedSegment, selectedPeople, selectedFilters, isFilterSelected, selectedPeopleCount, excludedPeople))
            },
            clickedAddMoreTags: (isShow) => dispatch(showAddTagDialog(isShow)),
            clickedImportPeople: () => dispatch(showImportWizard(true))
        }
    }
)(PeopleActionsBarOnboarding);

export const AddTagDialogContainer = connect(
    (state) => {
        return {
            showAddTagDialog: state.people.isAddMoreTags,
            selectedPeople: state.people.selectedPeople,
            showLoader: state.peopleImport.showAddTagLoader
        }
    },
    (dispatch, ownProps) => {
        return {
            closeAddTagDialog: (isShow) => dispatch(showAddTagDialog(isShow)),
            createTag: (tagName, selectedPeople) => dispatch(createTag(tagName, selectedPeople))
        }
    }
)(AddTagDialog);

export const PeopleDeleteContainer = connect(
    (state) => { //map state to props
        return {
            selectedPeopleCount: state.people.selectedPeopleCount,
            showConfirmDeleteDialog: state.people.actions.delete.showConfirmDeleteDialog,
            selectedPeople: state.people.selectedPeople,
            selectAll: state.people.selectAll,
            selectedFilters: state.people.selectedFilters,
            showLoader: state.people.actions.delete.showDeleteLoader,
            selectedSegmentId: state.people.selectedSegment.id,
            selectAllStatus: state.people.selectAllStatus,
            peopleList: state.people.peopleList
        }
    },
    (dispatch, ownProps) => {
        return {
            closeConfirmDeleteDialog: () => dispatch(showConfirmDelete(false)),
            onConfirm: (selectedPeople, selectAll, selectAllStatus, selectedFilters, selectedSegmentId, peopleList) => dispatch(deleteSelectedPeople(selectedPeople, selectAll, selectAllStatus, selectedFilters, selectedSegmentId, peopleList))
        }
    }
)(ConfirmDeleteDialog);

export const PeopleFilterContainer = connect(
    (state) => { //map state to props
        const columnNameMap = state.common.columnNameMap;
        if (columnNameMap['tags']) {
            columnNameMap['tags']['dataList'] = map(state.common.tags, (tag, key) => {
                return { 'id': key, 'label': tag.label }
            }) || [];
        }
        return {
            columnNameMap: columnNameMap,
            selectedFilters: state.people.selectedFilters
        }
    },
    (dispatch, ownProps) => {
        return {
            applyFilters: (selectedFilters) => dispatch(applySelectedFilters(selectedFilters)),
            clearFilters: () => dispatch(clearAllFilters())
        };
    }
)(PeopleFilters);

export const PeopleSegmentContainer = connect(
    (state) => { //map state to props
        return {
            segmentNameMap: state.common.segments,
            selectedSegmentName: state.people.selectedSegment.segment.name
        }
    },

    (dispatch, ownProps) => {
        return {
            onSelectSegment: (segmentId, selectedSegment) => {
                dispatch(onSelectSegment(segmentId, selectedSegment))
            },
            deleteSegment: (deletedSegment) => dispatch(deleteSegment(deletedSegment)),
        };
    }
)(PeopleSegments);

const isSegmentNameExists = (state) => {
    if (state.people.actions.createSegment.segmentName) {
        let newSegmentName = state.people.actions.createSegment.segmentName.toLowerCase();
        let filteredExisitngSegmentByName = find(state.common.segments, item => {
            return item.name.toLowerCase() === newSegmentName;
        });
        return !!filteredExisitngSegmentByName;
    }
    return false;
};

const isFiltersExists = (state) => {
    if (state.people.selectedFilters.length) {
        let selectedFilters = map(state.people.selectedFilters, item => {
            return omit(item, ['columnType', '_selected']);
        }).sort();
        return find(state.common.segments, item => {
            return isEqual(item.data.sort(), selectedFilters);
        });
    } else {
        return null;
    }
};
export const PeopleSegmentCreateContainer = connect(

    (state) => { //map state to props
        return {
            showPanel: state.people.isFilterSelected,
            selectedFilters: state.people.selectedFilters,
            segmentName: state.people.actions.createSegment.segmentName,
            exisitngSegments: state.common.segments,
            isSegmentNameExist: !state.people.actions.createSegment.isCreating && isSegmentNameExists(state),
            matchingSegmentWithSameFlters: isFiltersExists(state)
        }
    },
    (dispatch, ownProps) => {
        return {
            createSegment: (filters, segmentName, exisitngSegments) => dispatch(createSegment(filters, segmentName, exisitngSegments)),
            onChangeCreateSegmentName: (segmentName, exisitngSegments) => dispatch(createSegmentName(segmentName))
        };
    }
)(PeopleSegmentCreate);

export const AddOrEditPersonContainer = connect(
    (state) => { //map state to props
        return {
            showCreatePersonDialog: state.people.actions.add.showCreatePersonDialog,
            customColumnNames: state.people.customColumnNameMap,
            selectedPerson: state.people.actions.add.selectedPerson,
            showLoader: state.people.showAddEditPersonLoader
        };
    },
    (dispatch, ownProps) => {
        return {
            showAddEditPerson: (isShow) => dispatch(showAddEditPerson(isShow)),
            onSave: (person) => dispatch(createPerson(person)),
            closeCreatePersonDialog: () => {
                dispatch(showAddEditPerson(false))
            }
        };
    }
)(CreateOrEditPersonDialog);

export const PeoplePageContentContainer = connect(
    (state) => { //map state to props
        return {
            showGettingStarted: shouldShowGettingStarted(state)
        };
    },
    (dispatch, ownProps) => {
        return {};
    }
)(PeoplePageContent);

const customEventTemplatesColors = ["#1E88E5", "#3949AB", "#00897B", "#00ACC1", "#FB8C00", "#795548", "#F4511E"];

const _preProcessEvents = (event) => {

    let icon, iconColor, templateColor = "#7B1FA2", title;
    if (event.moduleId === Constants.MODULE_IDS.LOYALTY_MODULE) {
        switch (event.templateId) {
            case Constants.EVENT_TEMPLATES.LOYALTY_POINT_COLLECTED: {

                icon = "heart";
                iconColor = "#388E3C";
                title = "Collect Points";
                break;
            }

            case Constants.EVENT_TEMPLATES.LOYALTY_POINT_REDEEMED: {
                icon = "heart";
                iconColor = "#FF5252";
                title = "Redeem Points";
                break;
            }
            case Constants.EVENT_TEMPLATES.LOYALTY_REWARD_REDEEMED: {
                icon = "gift";
                iconColor = "#FFEB3B";
                title = "Redeem Reward";
                break;
            }
            case Constants.EVENT_TEMPLATES.LOYALTY_REGISTERED: {
                icon = "user-plus";
                iconColor = "#78909C";
                title = "Enroll";
                break;
            }
            default: {
                icon = "tag";
                iconColor = "red";
                title = event.title || "Other";
            }
        }
        templateColor = "#D32F2F";
    } else {
        //let eventTitle = has(this.eventIdMap, event.eventId) ? this.eventIdMap[event.eventId].title : "";
        switch (event.templateId) {
            case 2:
                icon = 'long-arrow-alt-down';
                iconColor = '#4DB6AC';
                title = "Incoming";
                break;

            case -1:
                icon = 'comment';
                iconColor = '#81C784';
                title = "Receive SMS";
                break;
            case -2:
                icon = 'envelope';
                iconColor = '#FFD54F';
                title = "Receive Email";
                break;
            default:
                icon = 'pencil-alt';
                iconColor = '#A1887F';
                title = event.title || "Other";


        }
        let keyIndex = 0;
        for (let keyCode in title) {
            keyIndex += keyCode;

        }
        templateColor = customEventTemplatesColors[keyIndex % customEventTemplatesColors.length];
    }

    event._icon = icon;
    event._iconColor = iconColor;
    event._title = title;
    event._templateColor = templateColor;
    return event;
}
export const PersonProfileContainer = withRouter(connect(
    (state) => { //map state to props
        return {
            showModal: state.peopleImport.showTagDeleteDialog,
            showLoader: state.peopleImport.showDeleteTagLoader,
            events: state.event.events.reduce((result, item) => {
                result[item._id] = _preProcessEvents(item);
                return result;
            }, {})
        };
    },
    (dispatch, ownProps) => {
        return {
            onDeleteTag: (tagIndex, person) => dispatch(deleteTag(tagIndex, person)),
            showDialog: (isShow) => dispatch(showTagDeleteDialog(isShow)),
            showSendMessage: (contact) => dispatch(showSendMessage(true, contact, '', 'people', Constants.TRANSPORT_SMS)),
            navigateToPeople: () => { dispatch(getPeople()) },
            showAlert: (title, message, alertType) => dispatch(showAlertBox(title, message, true, alertType))
        };
    }
)(PersonProfile));



export const PeopleGettingStartedGuideContainer = connect(
    (state) => { //map state to props
        return {

        }
    },
    (dispatch, ownProps) => {
        return {
            showAddEditPerson: () => dispatch(showAddEditPerson(true)),
            showImportPeople: () => dispatch(showImportWizard(true))
        }
    }
)(PeopleGettingStarted);
