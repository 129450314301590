import React, {
    PureComponent
} from "react";
import {
    Tabs,Tab
} from 'shoutout_themes';
import Constants from './../../../Constants';
import { TemplateGalleryContainer } from './../../../redux/containers/TemplateContainer';

class TemplatePage extends PureComponent {
    constructor() {
        super();
        this.state = { transportType: Constants.TRANSPORT_EMAIL };
        this._selectFilter = this._selectFilter.bind(this);
    }
    componentDidMount() {
        this.props.loadTemplates(this.state.transportType);
    }

    _selectFilter(key) {
        this.setState({ transportType: key });
        this.props.loadTemplates(key);
    }
    render() {
        return (<div className="container" id="template-page">
            <Tabs defaultActiveKey={Constants.TRANSPORT_EMAIL} onSelect={this._selectFilter} id="template-filters" className="border-0">
                <Tab eventKey={Constants.TRANSPORT_EMAIL} title="EMAIL"></Tab>
                <Tab eventKey={Constants.TRANSPORT_SMS} title="SMS"></Tab>
                <Tab eventKey={Constants.TRANSPORT_MESSENGER} title="MESSENGER"></Tab>
                <br/>
            
            </Tabs>
            <br/>
            <TemplateGalleryContainer hideSelect={true}/>
        </div>);
    }
}

export default TemplatePage;