import React from "react";
import {
    Button,
    Modal,Form
} from 'shoutout_themes';

const TemplateNameInput = ({show,closeTemplateNameInput,showLoader,templateName,onChangeTemplateName,templateType,templateContent,saveTemplate}) => {
    return (
        <Modal show={show} onHide={closeTemplateNameInput}>
        <Modal.Header closeButton>
            <Modal.Title> Save Template</Modal.Title> </Modal.Header>
            <Modal.Body>
                <form>
        <Form.Group
          controlId="template-name-input"
        >
          <Form.Label>Enter the name of your template</Form.Label>
          <Form.Control
            type="text"
            placeholder="Daily Promotion Template"
              value={templateName} onChange={(e) => {
                     e.preventDefault();
                                onChangeTemplateName(e.target.value);
                            }}
          />
        </Form.Group>
      </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="info" onClick={(e) => {
                        e.preventDefault();
                saveTemplate(templateContent,templateType,templateName);
                }} disabled={showLoader||!templateName}>{showLoader ? 'Saving...' : 'Save'}</Button>
                <Button onClick={closeTemplateNameInput} disabled={showLoader}>Cancel</Button>
            </Modal.Footer>

        </Modal>
    );
};

export default TemplateNameInput;
