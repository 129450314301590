import React, { PureComponent } from "react";
import { Card, FaIcon, Form } from 'shoutout_themes';
import { faTrash } from 'shoutout_themes/es/FaIconsSolid';
import ConfirmSegmentDeleteDialog from './PeopleSegmentDelete';
import "./People.css";
import Constants from './../../Constants';
import UserContext from "../../contexts/UserContext";

class PeopleSegments extends PureComponent {

    constructor(props) {
        super(props);
        this.showDialog = this.showDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.state = { showModal: false, deleteSegmentKey: 0 };
    }

    showDialog(e) {
        e.stopPropagation();
        this.setState({ showModal: true, deleteSegmentKey: e.currentTarget.dataset.key });
    }

    closeDialog() {
        this.setState({ showModal: false, deleteSegmentKey: 0 });
    }


    onSelectSegment = (e) => {
        const selectedKey = e.currentTarget.dataset.key;
        this.props.onSelectSegment(selectedKey, this.props.segmentNameMap[selectedKey])
        // console.log("key",this.props.segmentNameMap[e.currentTarget.dataset.key]);
    }


    render() {

        const { segmentNameMap, selectedSegmentName, deleteSegment } = this.props;
        let keys = Object.keys(segmentNameMap);
        let defaultSegmentId = Constants.ALL_SEGMENT.id.toString();
        return (
            <UserContext.Consumer>
                {({ scopes }) => (
                    <Card className="mb-3 border-0 rounded-0">
                        <Card.Body>
                            <h6 className="font-weight-bold ">Select Segment</h6>

                            {keys.map((key) => {
                                return (key !== defaultSegmentId) ? (
                                    <Card className="rounded-0 border-0 shadow-sm mb-2 p-2 cursor-pointer" key={key} data-key={key} onClick={this.onSelectSegment}>
                                        <div className="d-flex">
                                            <div className="segment-section" ><Form.Check custom type="radio" name="segment" checked={selectedSegmentName === segmentNameMap[key].name} label={''} /></div>
                                            <div>{segmentNameMap[key].name}</div>
                                            {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.PEOPLE.moduleId].delete && <div className=" ml-auto text-danger" data-key={key} onClick={this.showDialog} ><FaIcon icon={faTrash} /></div>}
                                        </div>
                                    </Card>) :
                                    (<Card className="rounded-0 border-0 shadow-sm mb-2 p-2 cursor-pointer" key={Constants.ALL_SEGMENT.id} data-key={Constants.ALL_SEGMENT.id} onClick={this.onSelectSegment}>
                                        <div className="d-flex">
                                            <div className="segment-section"><Form.Check custom type="radio" name="segment" data-key={key} onChange={this.onSelectSegment} checked={selectedSegmentName === segmentNameMap[key].name} label={''} /></div>
                                            <div>{segmentNameMap[key].name}</div>
                                        </div>
                                    </Card>)
                            })}


                            <ConfirmSegmentDeleteDialog show={this.state.showModal} onHide={this.closeDialog} okCallback={() => { deleteSegment(this.state.deleteSegmentKey); this.closeDialog(); }} segmentName={segmentNameMap[this.state.deleteSegmentKey].name} />
                        </Card.Body>
                    </Card>
                )
                }
            </UserContext.Consumer>
        );
    }
}

export default PeopleSegments;