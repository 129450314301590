//Migrate all admin endpoints to this service

import { fetchGet, fetchPut, fetchPost } from './CommonServiceUtils';
const ACCOUNT_SERVICE = 'https://api.getshoutout.com/accountservice/';
const SHOUTOUT_API_SVR = 'https://api2.getshoutout.com/api/';
const CAMPAIGN_SERVICE = 'https://api.getshoutout.com/campaignservice/';
export default class ShoutOUTAdminServices {

    static getAllInvoices({ ownerId }) {
        let url = ACCOUNT_SERVICE + 'admin/invoices';
        if (ownerId) {
            url += '?ownerId=' + ownerId
        }
        return fetchGet(url);

    }

    static createInvoice(createInvoiceReq) {
        return fetchPost(ACCOUNT_SERVICE + 'admin/paymentprocess', createInvoiceReq);
    }
    static approveTransaction(payload) {
        return fetchPut(ACCOUNT_SERVICE + 'admin/paymentprocess', payload);
    }

    static getUser(userId) {
        return fetchGet(ACCOUNT_SERVICE + 'admin/users/' + userId);

    }

    static updateUser(userId, updatePayload) {
        return fetchPost(SHOUTOUT_API_SVR + 'accounts/admin/users/' + userId, updatePayload);
    }

    static updateBillingInfo({ ownerId, createdOn, modifiedOn, billingInfo }) {
        return fetchPut(ACCOUNT_SERVICE + 'admin/users', { ownerId, createdOn, modifiedOn, billingInfo });
    }

    static getSenderIDList(userId) {
        let url = SHOUTOUT_API_SVR + 'accountservice/admin/senders';
        if (userId) {
            url += "?ownerId=" + userId;
        }
        return fetchGet(url);
    }
    static getProvidersList() {
        return fetchGet(SHOUTOUT_API_SVR + 'accountservice/admin/providers');
    }

    static updateAccountSenders(updatePayload) {
        return fetchPost(SHOUTOUT_API_SVR + 'accountservice/admin/senders', updatePayload);
    }

    static downloadSMSSenderIdAgreement(userId, senderId, status) {
        return fetchGet(`${SHOUTOUT_API_SVR}accountservice/admin/senders?senderId=${senderId}&ownerId=${userId}${status ? 'status=' + status : ''}`);
    }

    static getCampaigns(progressReviewing) {
        return fetchGet(`${CAMPAIGN_SERVICE}admin/campaigns?limit=20&&progressReviewing=${progressReviewing}`);
    }

    static updateCampaignReviewStatus(campaignId, status) {
        return fetchPut(`${CAMPAIGN_SERVICE}admin/campaigns`, { _id: campaignId, progress: { "reviewing": status } });
    }
}