/**
 * Created by madura on 2/1/17.
 */
import React from "react";
import {
    Button,
    Modal,
    Form
} from 'shoutout_themes';
import { LoadingComponent } from './../utils/UtilComponents';

let tagName = '';
const AddTagDialog = ({ showAddTagDialog, createTag, closeAddTagDialog, selectedPeople, showLoader }) => {
    return (
        <div>
            <Modal show={showAddTagDialog} onHide={() => { closeAddTagDialog(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Tag</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showLoader ? <LoadingComponent /> :
                        <Form.Group
                            controlId="tags"
                        >
                            <Form.Label>Tag Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Eg: premium" onChange={(e) => {
                                    tagName = e.target.value
                                }}
                            />
                        </Form.Group>}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { closeAddTagDialog(false) }}>Cancel</Button>
                    <Button variant="primary" onClick={() => { createTag(tagName, selectedPeople) }}>Add</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default AddTagDialog;