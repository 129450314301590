/**
 * Created by madura on 2/7/17.
 */
import React, {Component} from 'react';
import './CampaignCreate.css';
import CampaignCreateContainer from './../../../../redux/containers/CampaignCreateContainer';
class CampaignCreatePage extends Component {
    render() {
        return (
            <div className="container text-center campaign-create flex-fill d-flex flex-column">
                <h4>Create a Campaign</h4>
                <CampaignCreateContainer {...this.props}/>
            </div>
        );
    }
}

export default CampaignCreatePage;