
class AnalyticsService {
    static trackActivity(activity) {
        try {
            this.trackOnGoogle(activity);
        } catch (err) {
            console.error("Analytics error:", err);
        }
    }
    static trackFailedActivity(activity) {
        try {
            this.trackOnGoogleFailedActivity(activity);
        } catch (err) {
            console.error("Analytics error:", err);
        }
    }


    static trackOnGoogle(activity) {
        let dataLayer = window.dataLayer || [];
        dataLayer.push({ 'event': activity.name, 'data': activity.data||{} ,'category':activity.category});
    }

    static trackOnGoogleFailedActivity(activity) {
        let dataLayer = window.dataLayer || [];
        dataLayer.push({
            'event': 'exception',
            'exDescription': activity.data||{}
        });
    }
}
export default AnalyticsService;