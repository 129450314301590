import React, { useState, useCallback, useMemo } from 'react';
import { ShoutOUTHooksService } from './../../../services/Services';
import { notify } from 'react-notify-toast';
import { Button, Card, Avatar, CardColumns,Col } from 'shoutout_themes';
import { Row } from 'react-bootstrap';



const TypeFormConnect = ({ forms = { items: [] }, isLoading, closeShowSelectForm, accessToken, getIntegrations, connectedForms }) => {

    const [selectedFromId, setSelectedFormId] = useState(null);


    const connectTypeForm = useCallback(async (e) => {
        try {
            const formIndex = parseInt(e.target.dataset['formindex']);
            const form = forms.items[formIndex];
            const { id, title } = form;

            if (form) {

                setSelectedFormId(id);
                await ShoutOUTHooksService.connectTypeForm(title, id, 'TYPEFORM', accessToken, id, title);
                getIntegrations();

                notify.show("TypeForm connected successfully!", "success");
                closeShowSelectForm();

            }
        } catch (e) {
            console.error(e);
            notify.show("Facing a problem while connecting your TypeForms. Please retry!", "error");
        }
    }, [forms])


    const connectedFormsIds = useMemo(() => {
        const connectedIds = new Set();
        connectedForms.forEach(form => {
            connectedIds.add(form.identifier)
        });
        return connectedIds
    }, connectedForms);


    return (<div>

        <Card>
            <Card.Header>
                <strong>New TypeForm Integration</strong>
            </Card.Header>
            <Card.Body   xl={3} lg={3} md={4} sm={6} xs={12}>
                {isLoading ? (<p className="text-center">Loading...</p>) : forms.items.length === 0 && (<p className="text-center">You don't have any TypeForms which can connect with ShoutOUT.</p>)}
                <Row>
                    {forms.items && forms.items.map(({ id, title }, index) => {

                        return (
                            
                            connectedFormsIds.has(id) ? null :
                            <Col key={id} xl={3} lg={3} md={4} sm={6} xs={12}>
                                <Card className="shadow border-0 rounded-0 text-center h-100">
                                    <Card.Body>
                                        <Avatar name={title} size="64" />
                                        <h6 className="mt-2">{title}</h6>

                                        <Button className="mt-4" variant="outline-dark" size="sm" data-formindex={index} onClick={connectTypeForm} disabled={selectedFromId === id}>{selectedFromId === id ? 'Connecting...' : 'Connect'}</Button>

                                    </Card.Body>
                                </Card>
                                </Col>

                        )
                    })
                    }
                </Row>
            </Card.Body>
        </Card>

    </div>)
}
export default TypeFormConnect;