import React, { Component } from 'react';
import {BillingPlansContainer} from './../../redux/containers/BillingContainer';

class BillingPage extends Component {


    render() {
        return (
            <div className="billings container-fluid">
                        <BillingPlansContainer {...this.props}/>
            </div>
        );
    }
}

export default BillingPage;