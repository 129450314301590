/**
 * Created by madura on 2/7/17.
 */
import React, { useContext } from 'react';
import { Button, OverlayTrigger,FaIcon } from 'shoutout_themes';
import { faInfoCircle,faBullhorn } from 'shoutout_themes/es/FaIconsSolid';
import UserContext from './../../../../contexts/UserContext';


const renderTooltip = props => (
    <div
        {...props}
        style={{
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            padding: '2px 10px',
            color: 'white',
            borderRadius: 3,
            zIndex: 2000,
            ...props.style,

        }}
    >
        You can not create a campaign till you verify the account.
    </div>
);

const CampaignCreateButton = ({ onCreateCampaign, btnStyle, btnSize, history, className }) => {
    const { emailVerified } = useContext(UserContext);
    return (
        <React.Fragment>
            <Button disabled={!emailVerified} className={`text-nowrap ${className}`} variant={btnStyle || "primary"} size={btnSize} onClick={() => { onCreateCampaign(); history.push('/engagement/campaigns/create/new') }}><FaIcon className="mr-2" icon={faBullhorn} />Create Campaign</Button>
            {!emailVerified &&
                <OverlayTrigger
                    placement="left-start"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                >
                    <Button variant="link"><FaIcon icon={faInfoCircle} /></Button>
                </OverlayTrigger>
            }
        </React.Fragment>

    );
}


export default CampaignCreateButton;