/**
 * Created by madura on 2/7/17.
 */
import moment from 'moment';
import Constants from './../../Constants';
import {
    ShoutOUTService,
    HtmlCompilerService,
    UtilService
} from './../../services/Services';
import { DraftJS, exportText } from 'shoutout_email_editor';

import {
    notify
} from "react-notify-toast";
import {
    isObject
} from 'lodash';
import {
    requestBillingInfo
} from './BillingActions';
import { NO_ACTION } from './CommonActions';
const { EditorState, ContentState, convertFromRaw } = DraftJS;
let shoutout = new ShoutOUTService();

export const ON_CHANGE_EMAIL_SUBJECT = 'ON_CHANGE_EMAIL_SUBJECT';
export const ON_SELECT_SENDER_ID = 'ON_SELECT_SENDER_ID';
export const ON_CHANGE_CAMPAIGN_NAME = 'ON_CHANGE_CAMPAIGN_NAME';
export const SET_SCHEDULE_CAMPAIGN = 'SET_SCHEDULE_CAMPAIGN';
export const SET_SCHEDULE_ON = 'SET_SCHEDULE_ON';
export const ON_CAMPAIGN_CREATING = 'ON_CAMPAIGN_CREATING';
export const SELECT_EDITOR = 'SELECT_EDITOR';
export const ON_CHANGE_EDITOR_STATE = 'ON_CHANGE_EDITOR_STATE';
export const ON_SELECT_EVENT = 'ON_SELECT_EVENT';

const CAMPAIGN_CREATE_ACTIONS = {
    SELECT_SEGMENT: 'CREATE_CAMPAIGN_SELECT_SEGMENT',
    SELECT_PEOPLE: 'CREATE_CAMPAIGN_SELECT_PEOPLE',
    CREATE_FROM_PEOPLE: 'CREATE_CAMPAIGN_FROM_PEOPLE',
    CAMPAIGN_CREATED: 'CAMPAIGN_CREATED',
    CAMPAIGN_CREATE_ERROR: 'CAMPAIGN_CREATE_ERROR',
    ADD_DATA_TO_SELECTED_BLOCK: 'ADD_DATA_TO_SELECTED_BLOCK',
    TOGGLE_TRANSPORT: 'TOGGLE_TRANSPORT',
    LOAD_TEMPLATE: 'LOAD_TEMPLATE',
    SELECT_CAMPAIGN_TYPE: 'SELECT_CAMPAIGN_TYPE',
    SELECT_IMPORT_PEOPLE: 'CREATE_CAMPAIGN_SELECT_IMPORT',
    SELECT_CREATE_PERSON: 'CREATE_CAMPAIGN_SELECT_CREATE_PERSON',
    CLICK_CREATE_CAMPAIGN: 'CLICK_CREATE_CAMPAIGN',
    SET_CAMPAIGN_METADATA: 'SET_CAMPAIGN_METADATA',
    RELOAD_EDITOR: 'RELOAD_EDITOR',
    MESSENGER_ONCHANGE_TEMPLATE: 'MESSENGER_ONCHANGE_TEMPLATE',
    MESSENGER_NEW_TEMPLATE_SELECT: 'MESSENGER_NEW_TEMPLATE_SELECT',
    CHANGE_ACTIVE_STEP: 'CHANGE_ACTIVE_STEP',
    CHANGE_SMS_CAMPAIGN_TYPE: 'CHANGE_SMS_CAMPAIGN_TYPE'
};
export default CAMPAIGN_CREATE_ACTIONS;

export const onChangeEmailSubject = (subject) => {
    return {
        type: ON_CHANGE_EMAIL_SUBJECT,
        subject
    }
};

export const onSelectSenderId = (senderId, transport,senderIdList) => {
    const senderIdObj=senderIdList[transport].find(({senderId:sid})=>sid===senderId);
    return {
        type: ON_SELECT_SENDER_ID,
        senderIdObj:senderIdObj,
        transport: transport.toLowerCase()
    }
};

export const onChangeCampaignName = (campaignName) => {
    return {
        type: ON_CHANGE_CAMPAIGN_NAME,
        campaignName
    }
};

export const setCampaignSchedule = (isSchedule) => {
    return {
        type: SET_SCHEDULE_CAMPAIGN,
        isSchedule
    }
};

export const setScheduleDate = (currentDate, date) => {
    return (dispatch) => {
        let newDate = moment(date);
        let existingDate = moment(currentDate);
        const today = moment();
        existingDate.set({
            'year': newDate.year(),
            'month': newDate.month(),
            'date': newDate.date()
        });

        if (newDate.isAfter(today, 'day')) {
            existingDate.set({
                hour: "00",
                minute: "00"
            });
        }
        else {
            existingDate.set({
                hour: today.hour(),
                minute: today.add(30, 'm').minute()
            });
        }
        dispatch({
            type: SET_SCHEDULE_ON,
            date: existingDate
        });
    }
};


export const setScheduleTime = (currentDate, time) => {
    let newTime = moment(time, 'HH:mm');
    let existingDate = moment(currentDate);
    existingDate.set({
        'hour': newTime.hour(),
        'minute': newTime.minute()
    });
    return {
        type: SET_SCHEDULE_ON,
        date: existingDate
    }
};




export const selectEditor = (editorType) => {
    return {
        type: SELECT_EDITOR,
        editorType
    }
};

export const onChangeEditorState = (editorState, transport) => {
    let characterOverLimit = false;
    if (transport === Constants.TRANSPORT_SMS) {
        let textContent = exportText(editorState);
        let smsLength = UtilService.getSmsLength(textContent);
        let isUnicode = false;
        if (textContent && !UtilService.isGSMAlphabet(textContent)) {
            isUnicode = true;
            if (smsLength > 201) {
                characterOverLimit = true;
            }
        }
        else if (smsLength > 459) {
            characterOverLimit = true;
        }
        return {
            type: ON_CHANGE_EDITOR_STATE,
            editorState,
            transport: transport.toLowerCase(),
            characterOverLimit,
            isUnicode
        }
    }
    return {
        type: ON_CHANGE_EDITOR_STATE,
        editorState,
        transport: transport.toLowerCase()
    }
};



export const onCreateCampaign = (name, isScheduled, launchOn, transports, selectedPeople, sms, email, selectedSegment, campaignType, selectedEvent, excludedPeople, selectedFilters, history, messenger) => {
    return (dispatch) => {
        let campaignRequest = {

            name: name,
            transports: transports.map(item => item.toLowerCase()),
            type: campaignType.toLowerCase(),
            isScheduled: isScheduled ? 1 : 0

        };
        if (isScheduled) {
            campaignRequest.launchOn = launchOn;
        }
        if (transports.indexOf(Constants.TRANSPORT_SMS) > -1) {
            campaignRequest.sms = {
                from: sms.selectedSenderId,
                body: exportText(sms.editorState),
                campaignType:sms.campaignType
            }
        }
        if (transports.indexOf(Constants.TRANSPORT_EMAIL) > -1) {
            campaignRequest.email = {
                from: email.selectedSenderId,
                body: HtmlCompilerService.compileStateToHtml(email.editorState),
                subject: email.subject,
                htmlType: true
            }
        }


        if (campaignType !== Constants.CAMPAIGN_TRIGGER) {
            if (selectedSegment.id !== '') {
                campaignRequest.segments = [
                    {
                        name: selectedSegment.segment.name,
                        filters: selectedSegment.segment.data
                    }
                ]
                //campaignRequest.filters = selectedSegment.segment.data;
                if (excludedPeople.length) {
                    campaignRequest.excludeIds = excludedPeople;
                }
            } else if (selectedFilters.length) {
                campaignRequest.segments = [
                    {
                        name: "NO_SEGMENT",
                        filters: selectedFilters
                    }
                ]

                if (excludedPeople.length) {
                    campaignRequest.excludeIds = excludedPeople;
                }
            } else {
                campaignRequest.includeIds = selectedPeople;
            }
        } else {
            campaignRequest.eventId = selectedEvent;
            if (transports.indexOf(Constants.TRANSPORT_MESSENGER) > -1) {
                // const body = {};
                // if (messenger.text) {
                //     body.text = messenger.text;
                // }
                // if (Object.keys(messenger.template) > 0) {
                //     body.attachment = {
                //         type: 'template',
                //         payload: messenger.template
                //     }
                // }
                campaignRequest[Constants.TRANSPORT_MESSENGER.toLowerCase()] = {
                    from: messenger.selectedSenderId,
                    body: messenger.template
                }
            }
        }


        dispatch({
            type: ON_CAMPAIGN_CREATING
        });
        shoutout.createCampaign(campaignRequest).then(res => {
            history.push('/engagement/campaigns');
            dispatch({
                type: CAMPAIGN_CREATE_ACTIONS.CAMPAIGN_CREATED
            })
            notify.show('Campaign created', 'success');
        }, err => {
            notify.show('Cannot create campaign', 'error');
            dispatch({
                type: CAMPAIGN_CREATE_ACTIONS.CAMPAIGN_CREATE_ERROR
            });
        });
    }
};

export const onSelectEvent = (eventId) => {
    return {
        type: ON_SELECT_EVENT,
        eventId
    }
};


export const onSelectSegment = (segmentId, segment, isFromPeople) => {
    return (dispatch) => {
        dispatch({
            type: CAMPAIGN_CREATE_ACTIONS.SELECT_SEGMENT,
            selectedSegment: {
                id: segmentId,
                segment
            },
            isFromPeople: isFromPeople || false
        });
    };

};

export const createCampaignFromPeople = (selectedSegment, isSelectAll, selectedPeople, selectedFilters, selectedPeopleCount, excludedPeople) => {
    return (dispatch) => {
        dispatch({
            type: CAMPAIGN_CREATE_ACTIONS.CREATE_FROM_PEOPLE,
            selectedSegment,
            isSelectAll,
            selectedFilters,
            selectedPeople: Object.keys(selectedPeople),
            excludedPeople: excludedPeople,
            selectedPeopleCount
        });

    }
};

const toggleTransport = (transport) => {
    return {
        type: CAMPAIGN_CREATE_ACTIONS.TOGGLE_TRANSPORT,
        transport
    }
};

const reloadEditor = (transport, status) => {
    return {
        type: CAMPAIGN_CREATE_ACTIONS.RELOAD_EDITOR,
        transport,
        status
    }
}

const onSelectMessengerTemplate = (isSelect) => {
    return {
        type: CAMPAIGN_CREATE_ACTIONS.MESSENGER_NEW_TEMPLATE_SELECT,
        isSelect
    }
}
const loadTemplate = (template, transport) => {
    return (dispatch) => {
        let editorState;
        if (transport === Constants.TRANSPORT_EMAIL) {
            let body;
            if (isObject(template.content)) {
                body = template.content.body;
                dispatch(onChangeEmailSubject(template.content.subject));
            } else {
                body = template.content;
            }
            let raw = JSON.parse(body);
            //transform existing templates
            try {
                let transformedBlocks = raw.blocks.map((val) => {
                    let key = Object.keys(raw.entityMap).length;
                    if (val.type === "atomic") {
                        if (val.data.type === "image") {
                            val.entityRanges.push({ offset: 0, length: 1, key: key });

                            raw.entityMap[key] = {
                                "type": "IMAGE",
                                "mutability": "IMMUTABLE",
                                data: { width: (val.data.display === "small" ? 50 : (val.data.display === "medium" ? 75 : 100)), alignment: val.data.alignment || "center", src: val.data.src }
                            };
                            val.text = " ";
                            val.data = {};
                        } else if (val.data.type === "button") {
                            val.entityRanges.push({ offset: 0, length: 1, key: key });

                            raw.entityMap[key] = {
                                "type": "button",
                                "mutability": "IMMUTABLE",
                                data: { alignment: val.data.alignment || "center", name: val.data.name, url: val.data.url }
                            };
                            val.text = " ";
                            val.data = {};
                        }
                    }
                    return val;
                });
                raw.blocks = transformedBlocks;
            } catch (err) {
                notify.show("Error parsing template", "error");
            }
            editorState = EditorState.createWithContent(convertFromRaw(raw));
            dispatch(onChangeEditorState(editorState, transport));
            dispatch(reloadEditor(transport, true));
        }
        else if (transport === Constants.TRANSPORT_MESSENGER) {
            const body = template.content.bodyObject;
            // // if (body.attachment && body.attachment.payload) {
            // //     dispatch(onChangeMessengerTemplate(body.attachment.payload));
            // // }
            // if(body.text){
            //    // dispatch(onChangeMessengerTemplate({}));
            // }else{
            //     dispatch(onChangeMessengerTemplate(body));
            // }
            dispatch(onChangeMessengerTemplate(body));
            dispatch(onSelectMessengerTemplate(true))

        }
        else {
            dispatch(initSMSEditor(template.content.body));
            dispatch(reloadEditor(transport, true));
        }

    }
}


const onSelectCampaignType = (campaignType) => {
    return {
        type: CAMPAIGN_CREATE_ACTIONS.SELECT_CAMPAIGN_TYPE,
        campaignType
    };
}

const selectImportPeople = () => {
    return {
        type: CAMPAIGN_CREATE_ACTIONS.SELECT_IMPORT_PEOPLE
    };
}

const selectCreatePerson = () => {
    return {
        type: CAMPAIGN_CREATE_ACTIONS.SELECT_CREATE_PERSON
    };
}

const clickCreateCampaign = () => {
    return {
        type: CAMPAIGN_CREATE_ACTIONS.CLICK_CREATE_CAMPAIGN
    }
}

const initSMSEditor = (text = null) => {

    return (dispatch) => {
        let editorState;
        if (text) {
            editorState = EditorState.createWithContent(ContentState.createFromText(text));
        } else {
            editorState = EditorState.createEmpty();
        }
        return dispatch(onChangeEditorState(editorState, Constants.TRANSPORT_SMS));
    }
}

const updateMetadata = (newMetadata) => {
    return (dispatch) => {
        let transformedRequest = {};
        Object.keys(newMetadata).forEach(item => {
            if (newMetadata[item]) {
                transformedRequest[item] = newMetadata[item];
            }
        });
        if (Object.keys(transformedRequest).length) {
            ShoutOUTService.updateCampaignInfo(transformedRequest).then(res => {
                /* if (res.Attributes && res.Attributes.campaignInfo) {//TODO:Backend send old attributes in the response
                     console.debug({
                         type: CAMPAIGN_CREATE_ACTIONS.SET_CAMPAIGN_METADATA,
                         metadata: res.Attributes.campaignInfo
                     })
                     dispatch({
                         type: CAMPAIGN_CREATE_ACTIONS.SET_CAMPAIGN_METADATA,
                         metadata: res.Attributes.campaignInfo
                     });
                 } else {*/
                dispatch(requestBillingInfo());
                //}

            });
        } else {
            dispatch({ type: NO_ACTION });
        }
    }
}

const onChangeMessengerTemplate = (template) => {
    return { type: CAMPAIGN_CREATE_ACTIONS.MESSENGER_ONCHANGE_TEMPLATE, template };
}

const changeActiveStep = (newActiveStep) => {

    return {
        type: CAMPAIGN_CREATE_ACTIONS.CHANGE_ACTIVE_STEP,
        activeStep: newActiveStep
    }
}


const changeSmsCampaignType = (campaignType) => {
    return {
        type: CAMPAIGN_CREATE_ACTIONS.CHANGE_SMS_CAMPAIGN_TYPE,
        campaignType
    }
}
export {
    toggleTransport,
    loadTemplate,
    onSelectCampaignType,
    selectImportPeople,
    selectCreatePerson,
    clickCreateCampaign,
    initSMSEditor,
    updateMetadata,
    reloadEditor,
    onChangeMessengerTemplate,
    changeActiveStep,
    onSelectMessengerTemplate,
    changeSmsCampaignType
}
