/**
 * Created by madura on 2/7/17.
 */
import Shoutout from './../../services/Shoutout';
import AccountService from "./../../components/account/service/Account";
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { notify } from "react-notify-toast";

import { getPeople, onSelectSegment } from './PeopleActions';
import { getEvents } from './EventActions';
import PEOPLE_ACTIONS, { SEGMENT_CREATED, SEGMENT_CREATE_ERROR } from './PeopleActions';
import { requestBillingInfo, loadBillingPlans } from './BillingActions';


import { transform, map, omit, groupBy } from 'lodash';
import Constants from './../../Constants';

let accountService = new AccountService();

export const RECEIVE_COLUMN_NAME_MAP = 'RECEIVE_COLUMN_NAME_MAP';
export const RECEIVED_SENDER_IDS = 'RECEIVED_SENDER_IDS';
export const RECEIVED_SEGMENTS = 'RECEIVED_SEGMENTS';
export const RECEIVED_TAGS = 'RECEIVED_TAGS';
export const NO_ACTION = 'NO_ACTION';

const COMMON_ACTIONS = {
    RECEIVED_APIKEYS: 'RECEIVED_API_KEYS',
    SET_LOADING_SENDER_IDS:'SET_LOADING_SENDER_IDS'
};
export default COMMON_ACTIONS;

//Convert legacy payload to new payload
const _preProcessSegment = (segments) => {
    return transform(segments, (result, segment, key) => {
        segment.data = map(segment.data, segmentData => {
            return omit(segmentData, ['columnType']);
        });
        result[key] = segment;
    }, {});
};

export const getSegments = (user) => {
    return (dispatch) => {
        let segmentsRef = firebase.database().ref("segments").child(user.uid);
        segmentsRef.on('value', function (snapshot) {
            let segments = _preProcessSegment(snapshot.val());
            dispatch({
                type: RECEIVED_SEGMENTS,
                segments: segments || {}
            });
        });
    }
};


export const getTags = (user) => {
    return (dispatch) => {
        let tagsRef = firebase.database().ref("tags").child(user.uid);
        tagsRef.on('value', function (snapshot) {
            dispatch({
                type: RECEIVED_TAGS,
                tags: snapshot.val() || {}
            });
        });
    }
};

export const getColumnNameMap = (user) => {
    return (dispatch) => {
        let contactAttrRef = firebase.database().ref("contact_attributes").child(user.uid);
        contactAttrRef.on('value', function (snapshot) {
            dispatch({
                type: RECEIVE_COLUMN_NAME_MAP,
                columnNameMap: snapshot.val() || {}
            });
        });
    }
};

export const createSegment = (filters, segmentName) => {

    return (dispatch) => {
        if (segmentName) {
            dispatch({
                type: PEOPLE_ACTIONS.SEGMENT_CREATING
            })
            let user = firebase.auth().currentUser;
            let newSegmentRef = firebase.database().ref("segments").child(user.uid).push();
            newSegmentRef.set({ 'name': segmentName, 'data': filters }).then(res => {
                notify.show('Segment created', 'success');
                return dispatch({
                    type: SEGMENT_CREATED
                })
            }, err => {
                notify.show('Can not create segment', 'error');
                return dispatch({
                    type: SEGMENT_CREATE_ERROR
                })
            });
        } else {
            notify.show('Segment name is required', 'error');
            return dispatch({ type: NO_ACTION });
        }
    }
};

export const deleteSegment = (segmentId) => {
    return (dispatch) => {
        let user = firebase.auth().currentUser;
        let segmentRef = firebase.database().ref("segments").child(user.uid);
        segmentRef.child(segmentId).remove().then(res => {
            notify.show('Segment deleted', 'success');
            dispatch(onSelectSegment(Constants.ALL_SEGMENT.id, Constants.ALL_SEGMENT.segment));
        }, err => {
            notify.show('Can not delete segment', 'error');
        });
    }
};

export const receivedServiceToken = (serviceToken) => {
    return (dispatch) => {
        firebase.auth().signInWithCustomToken(serviceToken);
    }
};

export const getServiceToken = () => {
    return (dispatch) => {
        return accountService.getServiceToken().then(res => {
            dispatch(receivedServiceToken(res['token']));
        })
    }
};

export const syncFirebaseData = () => {
    return (dispatch) => {
        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                dispatch(getColumnNameMap(user));
                dispatch(getTags(user));
                dispatch(getSegments(user));
            }
        });
    }
};

export const signOutFirebase = () => {
    return (dispatch) => {
        firebase.auth().signOut();
    }
};

export const receivedSenderIds = (senderIds) => {
    return {
        type: RECEIVED_SENDER_IDS,
        senderIds
    }
};

export const getSenderIds = () => {

    return (dispatch) => {
        dispatch({type:COMMON_ACTIONS.SET_LOADING_SENDER_IDS,isLoading:true})
        return Shoutout.getSenderIds().then(res => {
            dispatch(receivedSenderIds(groupBy(res, 'transport')));
        }, err => { })
    }
};

const receivedApiKeys = (apiKeyList) => {
    return {
        type: COMMON_ACTIONS.RECEIVED_APIKEYS,
        apiKeyList
    }
};

const getApiKeys = () => {
    return (dispatch) => {
        return Shoutout.getCreatedApiKeys().then(res => {
            dispatch(receivedApiKeys(res));
        }, err => { })
    }
};

export const initialize = (token) => {
    return (dispatch) => {
        dispatch(getSenderIds());
        dispatch(getServiceToken());
        dispatch(syncFirebaseData());
        dispatch(getPeople());
        dispatch(getEvents());
        dispatch(getApiKeys());
        dispatch(loadBillingPlans());
        dispatch(requestBillingInfo());
   
    }
};

// export const updateWidgets = (token) => {
//     return (dispatch) => {
//         if (token) {
//             let decodedToken = jwtDecode(token);

//             setTimeout(() => {
//                 try {
//                     let dataLayer = window.dataLayer || [];
//                     dataLayer.push({
//                         'event': 'login',
//                         name: decodedToken['so_user_name'],
//                         email: decodedToken['so_user_email'],
//                         user_id: decodedToken['so_user_id']
//                     });
//                 } catch (e) {

//                 }


//             }, 4000);

//         } else {
//             dispatch({ type: NO_ACTION });
//         }
//     }
// };



const signout = () => {
    return (dispatch) => {
        dispatch(signOutFirebase());
        try {
            let dataLayer = window.dataLayer || [];
            dataLayer.push({
                'event': 'logout'
            });
        } catch (e) { }
    }
};

export {
    getApiKeys,
    signout
}
