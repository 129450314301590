import Loadable from 'react-loadable';
import { LoadingComponent } from './../utils/UtilComponents';

const EngagementModule = Loadable({
    loader: () => import('./EngagementModule'),
    loading: LoadingComponent
});

const EngagementOverview = Loadable({
    loader: () => import('./overview/EngagementOverview'),
    loading: LoadingComponent
});
const EngagementCampaigns = Loadable({
    loader: () => import('./campaigns/Campaigns'),
    loading: LoadingComponent
});
const EngagementResponses = Loadable({
    loader: () => import('./responses/Responses'),
    loading: LoadingComponent
});

const EngagementCoupons = Loadable({
    loader: () => import('./coupons/Coupons'),
    loading: LoadingComponent
});

const EngagementInbound = Loadable({
    loader: () => import('./inbound/InboundRequests'),
    loading: LoadingComponent
});

const EngagementCampaignReport = Loadable({
    loader: () => import('./campaigns/reports/CampaignReport'),
    loading: LoadingComponent
});
const EngagementCampaignEmailReport = Loadable({
    loader: () => import('./campaigns/reports/CampaignEmailReport'),
    loading: LoadingComponent
});
const EngagementCampaignSmsReport = Loadable({
    loader: () => import('./campaigns/reports/CampaignSmsReport'),
    loading: LoadingComponent
});

const EngagementCampaignMessengerReport = Loadable({
    loader: () => import('./campaigns/reports/CampaignMessengerReport'),
    loading: LoadingComponent
});

const EngagementCampaignGettingStarted = Loadable({
    loader: () => import('./campaigns/CampaignGettingStarted'),
    loading: LoadingComponent
});

const AddCouponCodes = Loadable({
    loader: () => import('./coupons/AddCouponCodes'),
    loading: LoadingComponent
});

const CreateCouponType = Loadable({
    loader: () => import('./coupons/CreateCouponType'),
    loading: LoadingComponent
});


export {
    EngagementModule,
    EngagementOverview,
    EngagementCampaigns,
    EngagementResponses,
    EngagementCoupons,
    EngagementCampaignReport,
    EngagementCampaignEmailReport,
    EngagementCampaignSmsReport,
    EngagementCampaignMessengerReport,
    EngagementCampaignGettingStarted,
    AddCouponCodes,
    EngagementInbound,
    CreateCouponType
}