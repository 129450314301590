import { parseJson, handleErrors, fetchGet } from './CommonServiceUtils';
const BASE_TYPEFORM_URL = "https://api.getshoutout.com/hookservice/typeform";
//usr access token should b passd
//b4v that code should b passed to typeFv  url
class TypeFormServices {

    //load forms
    static loadTypeForms(accessToken) {

        return fetchGet(`${BASE_TYPEFORM_URL}/forms`,null,{
            'X-Typeform-Token': accessToken
        });
    }



    // retrieve a typeForm (single)
    static async retrieveForm(formId,accessToken) {
        return fetchGet(`${BASE_TYPEFORM_URL}/forms/${formId}`, {
          
            headers: {
                'X-Typeform-Token': accessToken
            }
        }).then(handleErrors).then(parseJson);
        
    }// retrive type forms ends..

}


export default TypeFormServices;