import React, { Component } from "react";
import LoadingComponent from './LoadingComponent';
export default function asyncComponent(importComponent) {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);

      this.state = {
        component: null
      };
      this.mounted = false;

    }

    async componentDidMount() {
      this.mounted = true;
      const { default: component } = await importComponent();
      if (this.mounted) {
        this.setState({
          component: component
        });
      }

    }
    componentWillUnmount() {
      this.mounted = false
    }

    render() {
      const C = this.state.component;
      if (this.mounted) {
        return C ? <C {...this.props} /> : <LoadingComponent />;
      }
      return null;
    }
  }

  return AsyncComponent;
}
