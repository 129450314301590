import React, {
    Component
} from 'react';
import {
    Form, Button
} from 'shoutout_themes';
import Constants from './../../Constants';
class SendSmsMessage extends Component {

    render() {
        const { senderIds, mobileNumber, history, smsSenderId, changeSenderId, onChangeMessage, message, onChangeMobileNumber, actionFrom, hideSendMessage } = this.props;

        return (
            <Form>

                <Form.Group controlId="from">
                    <Form.Label>
                        From
                                        </Form.Label>
                    <Form.Control as="select" onChange={(e) => { changeSenderId(e.target.value, Constants.TRANSPORT_SMS) }}
                        value={smsSenderId} required disabled={actionFrom === "campaigns"}>

                        {senderIds.SMS && senderIds.SMS.map(item => {
                            return item.status === 1 ? <option key={item.senderId} value={item.senderId}>{item.senderId}</option> : null;
                        })}
                    </Form.Control>
                    {/* helpblock was here */}
                    <div className="text-center">
                        <small><Button variant="link" size="xsmall" onClick={(e) => { e.preventDefault(); history.push("/my-profile"); hideSendMessage() }}>Request
                                                your
                                                Sender ID</Button> to send messages from your business name.</small>
                    </div>
                </Form.Group>
                <Form.Group controlId="to">
                    <Form.Label>
                        To
                                        </Form.Label>
                    <Form.Control
                        placeholder="Format: country code+mobile number without leading zero. Eg: 94777123456" value={mobileNumber} onChange={(e) => { onChangeMobileNumber(e.target.value) }} disabled={actionFrom === "people"} />
                </Form.Group>

                <Form.Group controlId="message">
                    <Form.Label>
                        Message
                                        </Form.Label>
                    <Form.Control as="textarea"
                        placeholder="Hello!"
                        onChange={(e) => { onChangeMessage(e.target.value); }} required value={message} disabled={actionFrom === "campaigns"} />
                </Form.Group>
            </Form>
        );
    }

}

export default SendSmsMessage;
