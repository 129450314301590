import React, { Component } from 'react';

import { BillingService } from './../../services/Services';

import { notify } from 'react-notify-toast';
import { BillingInfo, CheckoutForm } from './BillingComponents';
import UserContext from './../../contexts/UserContext';
import './BillingPurchasePlan.css';
import { Row, Col, Form, Card, Button, FaIcon } from 'shoutout_themes';
import { faCreditCard,faUniversity } from 'shoutout_themes/es/FaIconsSolid';
const stepNames = ['Billing Info', 'Purchase'];
class BillingPurchasePlan extends Component {
    constructor() {
        super();
        this.state = {
            selectedPlan: {}, selectedPaymentOption: "card-payment", billingInfo: {
                cardHolderName: '',
                streetAddress: '',
                streetAddress2: '',
                city: '',
                state: '',
                zip: '',
                country: '',
                email: '',
                phone: ''
            },
            isProcessingPurchase: false,
            activeStep: 0,
            saveBillingInfo: false,
            isValidBillingInfo: false,
            isLoadingBillingInfo: false,
            currency: ''
        };

        this._onChangePaymentOption = this._onChangePaymentOption.bind(this);
        this._onChangeBillingInfo = this._onChangeBillingInfo.bind(this);

        this._placeOrder = this._placeOrder.bind(this);
        this._onSelectPlan = this._onSelectPlan.bind(this);
        this._gotoPrevious = this._gotoPrevious.bind(this);
        this._onClickNextBillingInfo = this._onClickNextBillingInfo.bind(this);
        this._onChangeSaveBillingInfo = this._onChangeSaveBillingInfo.bind(this);
        this._onChangeCurrency = this._onChangeCurrency.bind(this);
    }
    componentDidMount() {

        if (this.props.location.search) {
            let queryParams = new URLSearchParams(this.props.location.search);
            const message = queryParams.get('message'),
                paymentStatus = queryParams.get('paymentStatus');
            if (paymentStatus === "success") {

                this.props.showAlert("Payment Success", "Payment is success. Credit has been added to your account", true, "success");


            } else {
                this.props.showAlert("Payment Failed", message, true, "danger");
            }

        }


        if (this.props.selectedPlan.id) {
            this.setState({ isLoadingBillingInfo: true });
            BillingService.getBillingInfo().then(res => {

                let billingInfo = res && res.billingInfo ? res.billingInfo : {};

                this.setState({ billingInfo: billingInfo, isLoadingBillingInfo: false });

            }, err => {
                this.setState({ isLoadingBillingInfo: false });
            });
        } else {
            this.props.history.push('/billing');
        }

    }
    _onChangePaymentOption(option) {

        this.setState({ selectedPaymentOption: option, currency: (option === "card-payment" ? "USD" : this.state.currency) });
    }

    _onChangeBillingInfo(billingInfo, isValid) {
        this.setState({ billingInfo, isValidBillingInfo: isValid });
    }

    _updateBillingInfo(billingInfo) {
        BillingService.updateBillingInfo(billingInfo).then(res => {

        }, err => {

        });
    }


    _placeOrder(customerName, organization) {
        this.setState({ isProcessingPurchase: true });
        this._updateBillingInfo(this.state.billingInfo);

        let { name, quantity, id } = this.props.selectedPlan;
        let {
            streetAddress,
            streetAddress2,
            city,
            state,
            zip,
            country,
            email,
            phone } = this.state.billingInfo;

        let orderRequest = {
            planName: name,
            planQuantity: quantity,
            planId: id,
            billingInfo: {
                name: customerName,
                streetAddress,
                streetAddress2,
                city,
                state,
                zip,
                country,
                email,
                phone
            },
            currencyCode: this.state.currency || "USD"
        };
        if (organization) {
            orderRequest.organization = organization;
        }

        BillingService.placeBillingOrder(orderRequest).then(res => {
            this.props.showAlert("Order Placed", "Your order has been placed. Please do the payment and reply back to the email you recived with the payment slip.", true, "success");
            this.setState({ isProcessingPurchase: false });
            this.props.history.push('/billing');
        }, err => {
            this.setState({ isProcessingPurchase: false });
            notify.show("Couldn't place the order. Please contact support.", "error");
        });

    }


    getClassName(className, i) {
        let { activeStep } = this.state;
        return className + "-" + (activeStep === i ? 'doing' : (activeStep > i ? 'done' : 'todo'));
    }

    _onSelectPlan(selectedPlan) {
        this.setState({ selectedPlan, activeStep: 1 });
    }

    _onClickNextBillingInfo() {
        this.setState({ activeStep: 1 });
        if (this.state.saveBillingInfo) {
            BillingService.updateBillingInfo(this.state.billingInfo);
        }
    }
    _gotoPrevious() {
        let { activeStep } = this.state;
        activeStep = activeStep - 1;
        this.setState({ activeStep });
    }
    _onChangeSaveBillingInfo(e) {
        this.setState({ saveBillingInfo: e.target.checked });
    }
    _showBankTransfer() {
        return this.props.selectedPlan.metaData.type !== "subscription";
    }

    _onChangeCurrency(e) {
        this.setState({ currency: e.target.value });
    }
    render() {
        const { selectedPaymentOption, isProcessingPurchase, activeStep, currency } = this.state;
        const { selectedPlan } = this.props;

        return (
            <UserContext.Consumer>
                {({ ownerId, name, organization, username }) => (


                    <div className="container-fluid billing-purchase text-center">
                        <ol className="progtrckr py-4 px-0">
                            {stepNames.map((name, i) => (
                                <li className={this.getClassName("progtrckr", i)} key={i} value={i}>
                                    <em>{i + 1}</em>
                                    <small className="step-name">{name}</small>
                                    <span className="progress-line"></span>
                                </li>)
                            )
                            }
                        </ol>

                        <Row className="justify-content-center">
                            {
                                activeStep === 0 ? (



                                    <Col lg={8} sm={10} xs={12}>
                                        <Card>
                                            <Card.Body>
                                                <h4 className="font-weight-bold text-left">Billing Info</h4>
                                                <div className="text-left">
                                                    <BillingInfo onChangeBillingInfo={this._onChangeBillingInfo} billingInfo={this.state.billingInfo} isLoadingBillingInfo={this.state.isLoadingBillingInfo} />
                                                    <Form.Group>

                                                        <Form.Check name="save-billing" id="save-settings" onChange={this._onChangeSaveBillingInfo} checked={this.state.saveBillingInfo} label="Save billing details in my account" />
                                                    </Form.Group>
                                                </div>
                                                <br />
                                            </Card.Body>
                                            <Card.Footer>
                                                <Row>
                                                    <Col xs={6}>
                                                        <div className="text-left">
                                                            <Button variant="dark" onClick={() => this.props.history.push('/billing')}>Previous</Button>
                                                        </div>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <div className="text-right">
                                                            <Button variant="primary" onClick={this._onClickNextBillingInfo} disabled={!this.state.isValidBillingInfo}>&nbsp;&nbsp;Next&nbsp;&nbsp;</Button>
                                                        </div>
                                                    </Col>
                                                </Row>


                                            </Card.Footer>
                                        </Card>





                                    </Col>


                                ) : (

                                        <Col sm={8} md={6} xs={12}>
                                            <CheckoutForm billingInfo={this.state.billingInfo} merchantId={ownerId} planName={selectedPlan.name} amount={selectedPlan.amount} quantity={selectedPlan.quantity} planId={selectedPlan.id}
                                                customerName={name} customerOrganization={organization}
                                                recurrencePeriod={selectedPlan.metaData.recurrencePeriod} billingType={selectedPlan.metaData.type}
                                            >
                                                <Card className="text-center w-100">
                                                    <Card.Body>
                                                        <br />


                                                        <h4>Select Payment Method</h4><br />
                                                        <Row className="justify-content-center">
                                                            <Col xs={6}>
                                                                <Card className={`select-option ${selectedPaymentOption === "card-payment" ? "bg-success" : "bg-default"} py-3`} id="card-payment" onClick={() => this._onChangePaymentOption("card-payment")} >
                                                                    <Card.Body>
                                                                        <div><FaIcon icon={faCreditCard} size="2x" /></div>
                                                                        <p>Card Payment</p>
                                                                    </Card.Body>

                                                                </Card>
                                                            </Col>
                                                            {this._showBankTransfer() && <Col>
                                                                <Card className={`select-option ${selectedPaymentOption === "bank-transfer" ? "bg-success" : "bg-default"} py-3`} id="bank-transfer" onClick={() => this._onChangePaymentOption("bank-transfer")}>
                                                                    <Card.Body>

                                                                        <div><FaIcon icon={faUniversity} size="2x" /></div>
                                                                        <p>Bank Transfer</p>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Col>}
                                                        </Row>
                                                        <br />
                                                        <Card>
                                                            <Card.Body>
                                                                <h4>Plan Details</h4>
                                                                <p>Plan Name: {selectedPlan.name}</p>
                                                                <p>Quantity: {selectedPlan.quantity}</p>
                                                                <p>Total {selectedPlan.metaData.packageInclude}: {selectedPlan.blockSize * selectedPlan.quantity}</p>

                                                                <p><strong>Total Amount: {currency || "USD"}&nbsp;
                                                    {
                                                                        currency === "LKR" ? (selectedPlan.lkr_amount * selectedPlan.quantity) : (currency === "NGN" ? (selectedPlan.ngn_amount * selectedPlan.quantity) : (selectedPlan.amount * selectedPlan.quantity))
                                                                    }</strong>&nbsp;&nbsp;

                                                                {selectedPaymentOption === "bank-transfer" && <Form.Group controlId="Form.ControlsSelect" size="sm">
                                                                        <Form.Control as="select" className="mx-auto" placeholder="Change Currency" onChange={this._onChangeCurrency} value={currency}>
                                                                            <option disabled value="">Change Currency</option>
                                                                            <option value="LKR">Sri Lankan Rupee (LKR)</option>
                                                                            <option value="NGN">Nigerian Naira (NGN)</option>
                                                                            <option value="USD">United State (USD)</option>
                                                                        </Form.Control>
                                                                    </Form.Group>}

                                                                </p>

                                                            </Card.Body>
                                                        </Card>

                                                    </Card.Body>

                                                    <Card.Footer className="bg-white border-0">
                                                        <Row>
                                                            <Col xs={6} className="text-left">
                                                                <Button variant="dark" onClick={this._gotoPrevious}>Previous</Button>
                                                            </Col>
                                                            <Col xs={6} className="text-right">
                                                                {selectedPaymentOption === "bank-transfer" ?
                                                                    <Button className="float-right" type="button" variant="success" disabled={isProcessingPurchase} onClick={(e) => { e.preventDefault(); this._placeOrder(name || username, organization) }}>{isProcessingPurchase ? "Sending Request..." : "Place Order"}</Button> :
                                                                    <Button type="submit" className="float-right" variant="success" disabled={isProcessingPurchase}>{isProcessingPurchase ? "Sending Request..." : `Pay $${selectedPlan.amount * selectedPlan.quantity}`}</Button>
                                                                }
                                                            </Col>
                                                        </Row>


                                                    </Card.Footer>
                                                </Card>
                                            </CheckoutForm>

                                        </Col>

                                    )

                            }
                        </Row>
                    </div>
                )}
            </UserContext.Consumer>
        );
    }
}

export default BillingPurchasePlan;