/**
 * Created by madura on 3/30/17.
 */
import React from "react";
import { Row, Col, Button, Card, FaIcon} from 'shoutout_themes';
import {faUsers,faUserPlus,faUpload} from 'shoutout_themes/es/FaIconsSolid';

const PeopleGettingStartedGuide = ({ showAddEditPerson, showImportPeople }) => {

    return (
        <Row className="justify-content-center">
            <Col sm={10} className="text-center">
                <FaIcon icon={faUsers} size="3x" />
                <h6 className="font-weight-bold mb-0">Create new people</h6>
                <small>Upload all contacts with their respective details to be managed on ShoutOUT</small>
                <br /><br />

                <Button size="sm" variant="outline-primary" className="w-100 shadow zoom rounded-0 border-0 mb-3" onClick={showAddEditPerson}>

                    <div className="d-flex flex-row p-3">
                        <FaIcon className="mr-2" icon={faUserPlus} size="2x" />
                        <div className="text-left">
                            <h6 className="font-weight-bold mb-0">Create a Person</h6>
                            <p className="extra-small">Add contacts by manually entering all their details</p>
                        </div>
                    </div>
                </Button>
                {/* <Button size="sm" variant="outline-pink" className="w-100 shadow zoom rounded-0 border-0 mb-3" onClick={showImportPeople}>

                    <div className="d-flex flex-row p-3">
                        <FaIcon className="mr-2" icon={faUpload} size="2x" />
                        <div className="text-left">
                            <h6 className="font-weight-bold mb-0">Import People</h6>
                            <p className="extra-small">A long list of people? Easily upload them by importing a CSV/Excel file of their details</p>
                        </div>
                    </div>

                </Button> */}


                <br /><br />
            </Col>
        </Row >
    )
};

export default PeopleGettingStartedGuide;