/**
 * Created by madura on 2/7/17.
 */
import React, { PureComponent } from "react";
import {Form, Row, Col, Card, OverlayTrigger, Tooltip, Button,FaIcon} from 'shoutout_themes';
import { faCommentAlt,faQuestionCircle,faEnvelope,faPlus,faComment} from 'shoutout_themes/es/FaIconsSolid';
import DatePicker from './../../../../lib/react-16-bootstrap-date-picker';
import TimePicker from './../../../custom/TimeSelector';
import Constants from './../../../../Constants';
import moment from 'moment';


let today = new Date();
let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
let time;

class CampaignSettings extends PureComponent {

    _navigateToMyProfile = () => {
        this.props.history.push('/my-profile');
    }

    

    navigateToFBMessengerApp=()=>{
        this.props.history.push('/integrations/fbmessenger');
    }

    navigateToFBLeadAd = () =>{
        this.props.history.push('/integrations/fbleadad');
    }

    _toggleTransport = (e) => {
        this.props.toggleTransport(e.target.name);
    }

    componentDidMount(){
        const {scheduleOn,setScheduleTime,scheduleDate}=this.props;
        if (!moment(scheduleDate).isSame(moment(), 'date')) {
            // time = "00:00";
            setScheduleTime(scheduleOn,"00:00")
        } else {
            const nextScheduleTime=moment().add(30,'m');
            setScheduleTime(scheduleOn,nextScheduleTime.format("HH:mm"));
        }

    }

    render() {
        const { isSchedule, setCampaignSchedule, scheduleDate, scheduleTime, setScheduleDate, setScheduleTime, scheduleOn, campaignType, transports, senderIdsSms, selectedSenderIdSms, senderIdsEmail, selectedSenderIdEmail, onSelectSenderId ,senderIdsMessenger,selectedSenderIdMessenger,senderIds} = this.props;
        // if (!moment(scheduleDate).isSame(moment(), 'date')) {
        //     time = "00:00";
        // } else {
        //     time = today.getHours() + ":" + (today.getMinutes() + 30);
        // }

        return (
            <Row className="justify-content-center">
                <Col lg={12} sm={12} xs={12}>
                    <Card className="text-left">
                        <Card.Body>
                            <br />
                            <Form.Group controlId="campaign-channels">
                                <Form.Label>Select channel(s) you want to send this message:</Form.Label>
                                <br /><br />
                                <Row>
                                    <Col>
                                        <div className={`${transports.indexOf(Constants.TRANSPORT_SMS) > -1 ? "" : "text-muted"} card shadow p-4`}>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <span ><FaIcon className="mr-2" icon={faCommentAlt} size="1x" />SMS</span>
                                                <Form.Check name={Constants.TRANSPORT_SMS} onClick={this._toggleTransport} type="switch" id="sms-switch" label="" checked={transports.indexOf(Constants.TRANSPORT_SMS) > -1} />

                                                {/* <span onClick={() => toggleTransport(Constants.TRANSPORT_SMS)} className="border border-muted rounded-circle p-2 line-height-1">
                                                    <FaIcon className={`text-${transports.indexOf(Constants.TRANSPORT_SMS) > -1 ? "success" : "white"}`} icon={faCheck} />
                                                </span> */}
                                            </div>

                                            <Form.Group controlId="sms-sender-id" className="flex-fill" size="sm">
                                                <Form.Label className="font-weight-bold" size="sm">SMS Sender ID
                                                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Select channel SMS by click on the box to change the Sender ID</Tooltip>}>
                                                        <Button variant="link" size="sm"><span className="text-dark"><FaIcon icon={faQuestionCircle} /></span></Button>
                                                    </OverlayTrigger>
                                                </Form.Label>

                                                <Form.Control as="select" required onChange={(e) => {
                                                    onSelectSenderId(e.target.value, Constants.TRANSPORT_SMS,senderIds)
                                                }}
                                                    disabled={transports.indexOf(Constants.TRANSPORT_SMS) < 0}
                                                >
                                                    {senderIdsSms.map(item => {
                                                        return (<option key={item}
                                                            selected={item === selectedSenderIdSms}>{item}</option>)
                                                    })}
                                                </Form.Control>

                                            </Form.Group>
                                            <div className="text-right">
                                                <Button variant="dark" size="sm" onClick={this._navigateToMyProfile}> <FaIcon className="mr-2" icon={faPlus} />Sender ID</Button>
                                            </div>
                                        </div>

                                    </Col>


                                    <Col>

                                        <div className={`${transports.indexOf(Constants.TRANSPORT_EMAIL) > -1 ? "" : "text-muted"} card shadow p-4`}>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <span><FaIcon className="mr-2" icon={faEnvelope} size="1x" />Email</span>
                                                <Form.Check name={Constants.TRANSPORT_EMAIL} onClick={this._toggleTransport} type="switch" id="email-switch" label="" checked={transports.indexOf(Constants.TRANSPORT_EMAIL) > -1} disabled = {(senderIdsEmail.length) <= 0} />

                                                {/* <span onClick={() => toggleTransport(Constants.TRANSPORT_EMAIL)} className="border border-muted rounded-circle p-2 line-height-1 mr-2">
                                                    <FaIcon className={`text-${transports.indexOf(Constants.TRANSPORT_EMAIL) > -1 ? "success" : "white"}`} icon={faCheck} />
                                                </span> */}

                                            </div>

                                            <Form.Group controlId="sms-sender-id" className="flex-fill" size="sm">
                                                <Form.Label className="font-weight-bold" size="sm">Email Sender ID
                                                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Select channel Email by click on the box to change the Sender ID</Tooltip>}>
                                                        <Button variant="link" size="sm"><span className="text-dark"><FaIcon icon={faQuestionCircle} /></span></Button>
                                                    </OverlayTrigger>
                                                </Form.Label>


                                                <Form.Control as="select" required onChange={(e) => {
                                                    onSelectSenderId(e.target.value, Constants.TRANSPORT_EMAIL,senderIds)
                                                }} disabled={transports.indexOf(Constants.TRANSPORT_EMAIL) < 0}>
                                                    {senderIdsEmail.map(item => {
                                                        return (<option key={item}
                                                            selected={item === selectedSenderIdEmail}>{item}</option>)
                                                    })}
                                               

                                                </Form.Control>



                                            </Form.Group>

                                            <div className="text-right">
                                                <Button variant="dark" size="sm" onClick={this._navigateToMyProfile}> <FaIcon className="mr-2" icon={faPlus} />Sender ID</Button>
                                            </div>
                                        </div>
                                    </Col>

                                    {campaignType === Constants.CAMPAIGN_TRIGGER &&
                                        <Col>

                                            <div className={`${transports.indexOf(Constants.TRANSPORT_MESSENGER) > -1 ? "" : "text-muted"} card shadow p-4`}>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <span><FaIcon className="mr-2" icon={faComment} size="1x" />Messenger</span>
                                                    <Form.Check name={Constants.TRANSPORT_MESSENGER} onClick={this._toggleTransport} type="switch" id="messenger-switch" label="" checked={transports.indexOf(Constants.TRANSPORT_MESSENGER) > -1} />

                                                    {/* <span onClick={() => toggleTransport(Constants.TRANSPORT_EMAIL)} className="border border-muted rounded-circle p-2 line-height-1 mr-2">
            <FaIcon className={`text-${transports.indexOf(Constants.TRANSPORT_EMAIL) > -1 ? "success" : "white"}`} icon={faCheck} />
        </span> */}

                                                    
                                                </div>
                                                <Form.Group controlId="messenger-sender-id" className="flex-fill" size="sm">
                                                        <Form.Label className="font-weight-bold" size="sm">Messenger Page
                                            
                                                        </Form.Label>


                                                        <Form.Control as="select" required onChange={(e) => {
                                                            onSelectSenderId(e.target.value, Constants.TRANSPORT_MESSENGER,senderIds)
                                                        }} disabled={transports.indexOf(Constants.TRANSPORT_MESSENGER) < 0}>
                                                            {senderIdsMessenger.map(item => {
                                                                return (<option key={item._id} value={item.senderId}
                                                                    selected={item.senderId === selectedSenderIdMessenger}>{item.displayName}</option>)
                                                            })}
                                                        </Form.Control>



                                                    </Form.Group>
                                                    <div className="text-right">
                                                <Button variant="dark" size="sm" onClick={this.navigateToFBMessengerApp}> Manage</Button>
                                            </div>
                                            </div>
                                        </Col>
                                    }
                                </Row>
                            </Form.Group>

                            <br />
                            {campaignType !== Constants.CAMPAIGN_TRIGGER && <Form.Group className="d-flex">
                                <Form.Label className="mr-2">Send this campaign:</Form.Label>
                                <Form.Check type="radio" id="now" inline name="schedule" checked={!isSchedule} onChange={() => setCampaignSchedule(false)} label="Now" />

                                {'     '}
                                <Form.Check type="radio" id="later" inline name="schedule" checked={isSchedule} onChange={() => setCampaignSchedule(true)} label="Later" />
                            </Form.Group>}

                            {isSchedule && <Form.Group>

                                <Row>
                                    <Col className="my-auto">
                                        <Form.Label className="mb-0">Select Date and Time:</Form.Label>
                                    </Col>
                                    <Col className="my-auto">
                                        <DatePicker id="schedule-date" calendarPlacement="top" bsClass="pt-0" minDate={date} dateFormat="YYYY-MM-DD" showClearButton={false}
                                            value={scheduleDate}
                                            onChange={(value, formatted) => setScheduleDate(scheduleOn, formatted)} />
                                    </Col>
                                    <Col className="my-auto">
                                        <TimePicker step={15} start={scheduleTime} value={scheduleTime}
                                            onChange={(time) => setScheduleTime(scheduleOn, time)} />
                                    </Col>
                                </Row>
                            </Form.Group>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        )
    }


};
export default CampaignSettings;