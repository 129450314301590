import React from 'react';
const LoadingRocket = () => {
    return (

        <div className="position-absolute top-p40 left-p45">
            <img src="/assets/images/loading-paper-plane.gif" alt="Loading..." />
        </div>
        
    );
};
export default LoadingRocket;