import { fetchDelete, fetchGet } from './CommonServiceUtils';
const AccountServiceBaseUrl = "https://api.getshoutout.com/accountservice/";
const ACCOUNT_SERVICE2 = 'https://api.getshoutout.com/authservice/';
class AccountService {
    static deleteUserAccount() {
        return fetchDelete(AccountServiceBaseUrl + "gdpr/users");
    }

    static deleteSenderId(senderId) {
        return fetchDelete(AccountServiceBaseUrl + "accounts/senders/" + senderId);
    }


    static resendVerificationLink() {
        return fetchGet(ACCOUNT_SERVICE2 + "resendverification");
    }

}

export default AccountService;