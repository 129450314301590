import TEMPLATE_ACTIONS from './../actions/TemplateActions';
import update from 'immutability-helper';
import { findIndex } from 'lodash';


const initialState = {
    showTemplateNameInput: false,
    templateName: '',
    templateContent: null,
    templateType: null,
    isTemplateSaving: false,
    isTemplateLoading: false,
    showTemplateGallery: false,
    templates: [],
    actions: {
        delete: {
            isDeleting: false
        },
        filter:{
            template:null
        }
    }
};

const templateReducer = (state =initialState, action) => {
    switch (action.type) {
        case TEMPLATE_ACTIONS.SHOW_NAME_INPUT_DIALOG:
            {
                return update(state, {
                    showTemplateNameInput: {
                        $set: action.isShow
                    }
                });
            }

        case TEMPLATE_ACTIONS.SET_NAME:
            {
                return update(state, {
                    templateName: {
                        $set: action.templateName
                    }
                });
            }
        case TEMPLATE_ACTIONS.SAVE:
            {
                return update(state, {
                    templateName: {
                        $set: action.templateName
                    },
                    templateContent: {
                        $set: action.templateContent
                    },
                    templateType: {
                        $set: action.templateType
                    }
                });
            }
        case TEMPLATE_ACTIONS.SAVING:
            {
                return update(state, {
                    isTemplateSaving: {
                        $set: true
                    }
                });
            }
        case TEMPLATE_ACTIONS.SAVED:
            {
                return update(state, {
                    $set: initialState
                });
            }
        case TEMPLATE_ACTIONS.SAVE_ERROR:
            {
                return update(state, {
                    isTemplateSaving: {
                        $set: false
                    }
                });
            }
        case TEMPLATE_ACTIONS.SHOW_TEMPLATE_GALLERY:
            {
                return update(state, {
                    showTemplateGallery: {
                        $set: action.isShow
                    },
                    templateType: { $set: action.templateType }
                });
            }

        case TEMPLATE_ACTIONS.LOADING_TEMPLATES:
            {
                return update(state, {
                    isTemplateLoading: {
                        $set: true
                    }
                });
            }
        case TEMPLATE_ACTIONS.LOADED_TEMPLATES:
            {
                return update(state, {
                    isTemplateLoading: {
                        $set: false
                    },
                    templates: { $set: action.templates }
                });
            }
        case TEMPLATE_ACTIONS.LOADING_TEMPLATES_ERROR:
            {
                return update(state, {
                    isTemplateLoading: {
                        $set: false
                    }
                });
            }

        case TEMPLATE_ACTIONS.DELETING_TEMPLATE: {
            return update(state, {
                actions: {
                    delete:
                    {
                        isDeleting:
                        {
                            $set: true
                        }
                    }
                }
            });
        }
        case TEMPLATE_ACTIONS.DELETED_TEMPLATE: {
            let templateIndex = findIndex(state.templates, (item) => { return item.id === action.templateId });
            return update(state, {
                actions: {
                    delete:
                    {
                        isDeleting:
                        {
                            $set: false
                        }
                    }
                },
                templates: { $splice: [[templateIndex,1]] }
            });
        }
        case TEMPLATE_ACTIONS.DELETE_TEMPLATE_ERROR: {
            return update(state, {
                actions: {
                    delete:
                    {
                        isDeleting:
                        {
                            $set: false
                        }
                    }
                }
            });
        }
        case TEMPLATE_ACTIONS.FILTER_TEMPLATE:{
            return update(state, {
                actions: {
                    filter:
                    {
                        template:
                        {
                            $set: action.selectedTemplate
                        }
                    }
                }
            });
        }
        default:
            {
                return state;
            }
    }
};


export default templateReducer;


export {initialState as state};