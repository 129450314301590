/**
 * Created by madura on 2/20/17.
 */
import {ShoutOUTService} from './../../services/Services';
import {map} from 'lodash';


const EVENT_ACTIONS = {
    REQUEST_EVENTS: 'REQUEST_EVENTS',
    RECEIVED_EVENTS: 'RECEIVED_EVENTS',
    EVENT_CREATING:'EVENT_CREATING',
    EVENT_CREATE_ERROR:'EVENT_CREATE_ERROR',
    EVENT_CREATED:'EVENT_CREATED',
    SELECT_EVENT_INTEGRATION:'SELECT_EVENT_INTEGRATION'
};
export default EVENT_ACTIONS;
const getEvents = () => {
    return (dispatch) => {
        dispatch({type: EVENT_ACTIONS.REQUEST_EVENTS});
        const SYSTEM_EVENTS=["Email Open","Email Spamreport","Email Delivered","Email Unsubscribe","Email Bounce","Email Click","Email Deferred"];
        ShoutOUTService.getEvents().then(res => {
            //TODO:Add system event delegation to backend
            let events=map(res,event=>{
                if(SYSTEM_EVENTS.indexOf(event.title)>-1){
                    event.type='_system';
                }
                return event;
            });
            dispatch(receivedEvents(events));
        }).catch(e=>{
            dispatch(receivedEvents([]))
        });
    }
};

const receivedEvents = (events) => {
    return {
        type: EVENT_ACTIONS.RECEIVED_EVENTS,
        events
    }
};

/*const createEvent=(event)=>{
    return (dispatch) => {
        dispatch({
            type:EVENT_ACTIONS.EVENT_CREATING
        });
        ShoutOUTService.createEvent(event).then(res => {
            notify.show("Event Created","success");
            dispatch({
                type:EVENT_ACTIONS.EVENT_CREATED,
                event:res
            });
            dispatch(onselectEventIntegration(res));
            },err=>{
            notify.show("Cannot create event","error");
            dispatch({
                type:EVENT_ACTIONS.EVENT_CREATE_ERROR
            })
        });
    }
};

const onselectEventIntegration=(selectedEvent)=>{
    return {
        type: EVENT_ACTIONS.SELECT_EVENT_INTEGRATION,
        selectedEvent
    }
}*/
export{
    receivedEvents,
        getEvents,
      /*  createEvent,
        onselectEventIntegration*/
}
