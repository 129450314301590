/**
 * Created by madura on 1/23/17.
 */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, InputGroup, Button,FaIcon } from 'shoutout_themes';
import {faSearch} from 'shoutout_themes/es/FaIconsSolid';

const SearchPeople = ({ onSearch, searchText, onChangeSearchText, selectedFilters }) => {
    const onChange = useCallback((e) => {
        e.preventDefault();
        const value = e.target.value;
        onChangeSearchText(value);
        if (value === "") {
            onSearch(value, selectedFilters);
        }

    }, [onChangeSearchText, selectedFilters])

    const onPressEnter=useCallback((e)=>{
        if (e.key === 'Enter') {
            onSearch(searchText, selectedFilters);
        }
    },[searchText,onSearch,selectedFilters])
    return (

        <InputGroup className="pt-0 flex-nowrap flex-row">
            <Form.Control  type="text" placeholder="Search people by Name, Email or Mobile"
                onChange={onChange}
                value={searchText}
                onKeyPress={onPressEnter}
            />

            <InputGroup.Append className="bring-to-top">
                <Button className="rounded-circle search-icon" variant="primary" onClick={(e) => {
                    e.preventDefault();
                    onSearch(searchText, selectedFilters);
                }
                }
                >
                    <FaIcon icon={faSearch} />
                </Button>
            </InputGroup.Append>
        </InputGroup>

    );

}

SearchPeople.propTypes = {
    onSearch: PropTypes.func.isRequired
};
export default SearchPeople;
