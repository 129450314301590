import React, { createContext, useReducer, useContext, useEffect, useState, useCallback } from "react";

const Context = createContext();


const ContextProvider = ({ children, reducer = () => { } }) => {
    const [store, dispatch] = useReducer(reducer);
    const [state, setState] = useState({ isLoaded: false });
    const customDispatch = useCallback(async (action) => {
        if (action) {
            if (typeof action === "function")
                action(customDispatch)
            else {
                dispatch(action);
            }
        }
    }, []);
    useEffect(() => {
        dispatch({ type: '@init' });
        setState({ isLoaded: true });
    }, []);

    const value = { store, dispatch: customDispatch };
    return (
        <Context.Provider value={value}>{state.isLoaded ? children : false}</Context.Provider>
    );
}

const ContextConsumer = Context.Consumer;

const connect = (mapState, mapDispatch) => {
    return WrappedComponent => {
        return (props) => {
            const { store, dispatch } = useContext(Context);
            return (
                <WrappedComponent {...props} {...mapState(store)} {...mapDispatch(dispatch)} />
            );
        };
    };
};


const combineReducer = reducer => {
    return (state = {}, action) => {
        const keys = Object.keys(reducer);
        const nextReducers = {};
        for (let i = 0; i < keys.length; i++) {
            const invoke = reducer[keys[i]](state[keys[i]], action);
            nextReducers[keys[i]] = invoke;
        }
        return nextReducers;
    };
};
export default Context;


export { connect, combineReducer, ContextProvider, ContextConsumer,Context };