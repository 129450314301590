/**
 * Created by madura on 2/7/17.
 */
import React from 'react';
import {
    Tabs, Tab
} from 'shoutout_themes';
import {
    SmsEditorContainer,
    EmailEditorContainer,
    MessengerEditorContainer
} from './../../../../redux/containers/CampaignCreateContainer';
import Constants from './../../../../Constants';

const CampaignMessageConfig = ({ transports, selectedEditor, selectEditor, campaignType }) => {
    return (


        <Tabs activeKey={selectedEditor} id="message-config" onSelect={
            selectEditor
        }>
            <Tab eventKey={Constants.TRANSPORT_SMS} title="SMS" disabled={transports.indexOf(Constants.TRANSPORT_SMS) < 0}>
                {transports.indexOf(Constants.TRANSPORT_SMS) > -1 && selectedEditor === 'SMS' && <SmsEditorContainer />}
            </Tab>
            <Tab eventKey={Constants.TRANSPORT_EMAIL} title="EMAIL"
                disabled={transports.indexOf(Constants.TRANSPORT_EMAIL) < 0}>
                {transports.indexOf(Constants.TRANSPORT_EMAIL) > -1 && selectedEditor === 'EMAIL' && <EmailEditorContainer />}
            </Tab>
            {campaignType === Constants.CAMPAIGN_TRIGGER && <Tab eventKey={Constants.TRANSPORT_MESSENGER} title="MESSENGER" disabled={transports.indexOf(Constants.TRANSPORT_MESSENGER) < 0}>
                <MessengerEditorContainer />
            </Tab>
            }
        </Tabs>


    )
};

export default CampaignMessageConfig;
