import React from 'react';
import { Button } from 'shoutout_themes';
import {AnalyticsService} from './../../services/Services';
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
        this._reload = this._reload.bind(this);
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        console.error(error, info);
        AnalyticsService.trackFailedActivity({data:{error,info}});
    }
    _reload(e) {
        e.stopPropagation(); 
        window.location.replace(window.location.origin);
    }

    render() {
        if (this.state.hasError||!this.props.children) {
            // You can render any custom fallback UI
            return (
                <div className="text-center">
                    <h1 className="mb-0 font-weight-bold">Something went wrong.</h1>
                    <Button variant="dark" onClick={this._reload}>Go Back to Dashboard</Button>
                </div>);
        }

        return this.props.children;
    }
}

export default ErrorBoundary;