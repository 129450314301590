import React, { Component, Suspense } from "react";
import Notifications from "react-notify-toast";
import ErrorBoundary from './components/utils/ErrorBoundaryComponent';
import { Route, Switch } from 'react-router-dom';
import UserContext from './contexts/UserContext';
import { LoadingRocket } from './components/utils/UtilComponents';
import AuthRedirectRoute from './AuthRedirectRoute';
import Home from './components/home/Home';
import VerifyPage from './components/verify/VerifyPage';
import Login from './components/account/views/Login';
import UnsubscriptionPage from './components/unsubscription/UnsubscriptionPage';

import "react-joyride/lib/react-joyride-compiled.css";
// import "bootstrap/dist/css/bootstrap.min.css"; 
import 'shoutout_themes/es/themes/shoutout/bootstrap.min.css';
import 'react-virtualized/styles.css';

import "./index.css";

class App extends Component {
    static contextType = UserContext;

    render() {
        return (
            <div className="App overflow-hidden">
                <Notifications options={{ zIndex: 10020 }} />

                {/* <NotificationBar /> */}



                {/*
                <Loader loaded={this.props.isLoaderHidden}/>
*/}
                <ErrorBoundary>
                    <Suspense fallback={<LoadingRocket />}>
                        <Switch>
                            <Route name="Unsubscribe" exact path="/unsubscribe/:cId/:oId/:addr" component={UnsubscriptionPage} />
                            <Route name="Verify" exact path="/verify/:type" component={VerifyPage} />
                            <Route name="Login" exact path="/login" component={Login} />
                            <AuthRedirectRoute name="Home" component={Home} />
                        </Switch>
                    </Suspense>

                </ErrorBoundary>
            </div>
        )
    }
}

export default App;
