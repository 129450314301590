import React, { useCallback, useState, useContext, useMemo, useEffect } from 'react';
import { Button, Card, Avatar, TopNavigationBar } from 'shoutout_themes';
import Constants from './../../../../src/Constants';
import IntegrationContext from './../../../contexts/IntegrationContext';
import TypeFormConnect from './TypeFormConnect';
import queryString from 'query-string'
import { withRouter } from 'react-router-dom';
import TypeFormServices from './../../../services/TypeFormServices'
import { ShoutOUTHooksService } from '../../../services/Services';
import './TypeForm.css';

const URL = window.location.origin;

const TypeForm = withRouter(({ history, location }) => {
    const [showSelectForm, setShowSelectForm] = useState(false);
    const [forms, setForms] = useState({ items: [] });
    const [showLoadingMsg, setShowLoadingMsg] = useState(false);
    const [connectedFormId , setConnetedFormId] = useState(null);

    const { integrations, getIntegrations, isLoading: isLoadingIntegrations } = useContext(IntegrationContext);


    const getTypeForms = useCallback(async (codeValue) => {

        try {
            const redirect_uri = `${URL}/integrations/typeform`;
            setShowSelectForm(true);
            setShowLoadingMsg(true);
            const accessTokenResponse = await ShoutOUTHooksService.getTypeFormAcccessToken(codeValue, redirect_uri);
            sessionStorage.setItem('typeFormAccessToken', accessTokenResponse.access_token);
            history.push("/integrations/typeform");
            const formsList = await TypeFormServices.loadTypeForms(accessTokenResponse.access_token);
            console.log("Forms List detils: ",formsList);
            setForms(formsList);

        } catch (e) {

            console.error("ERROR: ", e);
        } finally {
            setShowLoadingMsg(false);
        }

    }
        , [setForms]
    )

    useEffect(() => {
        const queryParams = queryString.parse(location.search);
        const codeValue = queryParams.code;
        if (codeValue) {
            getTypeForms(codeValue);
        }
    }, []);

    const connectedForms = useMemo(() => {
        return integrations[Constants.INTEGRATION_APPS.TYPEFORM_APP.id] || []; 
    }, [integrations]);


    const disconnect = useCallback(async (event) => {
        setConnetedFormId(event.target.dataset.integrationid);
        await ShoutOUTHooksService.disconnectFromHook(event.target.dataset.integrationid);   
        getIntegrations();
    }, [])

    const closeShowSelectForm = useCallback(() => {
        setShowSelectForm(false);
    }, [setShowSelectForm])

    return (
        <div className="px-4 messenger">
            <TopNavigationBar>
                <h6 className=" py-1 mb-0 text-center"><strong>TypeForm integrations</strong></h6>
            </TopNavigationBar>
            <div className="mt-3">
                {isLoadingIntegrations ? <p className="text-center">Loading...</p> :

                    showSelectForm &&
                    <TypeFormConnect isLoading={showLoadingMsg} forms={forms} getIntegrations={getIntegrations} closeShowSelectForm={closeShowSelectForm} connectedForms={connectedForms} accessToken={sessionStorage.getItem('typeFormAccessToken')
                    } />}
                <React.Fragment>
                    {(connectedForms.length > 0 ?

                        <div className="d-block">
                             {/* {isLoadingIntegrations ? <p>loading..</p>: */}
                            <Card className="mt-2">
                                
                                <div className="card-header text-center">{/*TODO: Use cardHeader*/}
                                   
                                    <div className="d-flex justify-content-between" >
                                    <strong>Connected typeForms</strong>
                                    {!showSelectForm &&<a href={`https://api.typeform.com/oauth/authorize?state=xyz789&client_id=HdSfzW6fjZ8WnvPH83sBLLHmbd1rjhRFVSgMhycGKifU&redirect_uri=${URL}/integrations/typeform&scope=accounts:read+webhooks:write+forms:read`}>
                                        <Button size="sm" variant="dark">Connect a New TypeForm</Button>{/*TODO: move this button to the card*/}
                                    </a>}
                                </div>
                               
                                  
                                </div>
                                {

                                    connectedForms.map(({ name, _id }, index) => {

                                        return (


                                            <Card className="shadow border-0 rounded-0  p-4 m-2" key={_id}>
                                                <div className="d-flex align-items-center">
                                                    <Avatar name={name} size="45" />
                                                    <div className="ml-2">
                                                        <h6 className="mb-0">{name} </h6>
                                                    </div>
                                                    <Button className="ml-auto mr-2" variant="outline-danger" size="sm" id="disconnectBtn" data-integrationid={_id} data-formindex={index} onClick={disconnect} disabled={connectedFormId == _id}>{(connectedFormId == _id)?'Disconnecting...':'Disconnect'}
                                                            </Button>
                                                </div>
                                            </Card>

                                        )
                                    }


                                    )

                                }

                            </Card>
                        </div> :

                        <div className="text-center mt-4 px-5">
                            {!isLoadingIntegrations&&
                                <div>
                                    <div className="my-2 text-left text-small">Type Form</div>
                                    <div className="d-flex justify-content-between align-items-center bg-white shadow-sm p-2 mb-2">
                                        <small>Connect your TypeForms</small>

                                        <div >
                                            <a href={`https://api.typeform.com/oauth/authorize?state=xyz789&client_id=HdSfzW6fjZ8WnvPH83sBLLHmbd1rjhRFVSgMhycGKifU&redirect_uri=${URL}/integrations/typeform&scope=accounts:read+webhooks:write+forms:read`}
                                                className="ml-auto mr-2"
                                                variant="outline-danger"
                                                size="sm">Connect TypeForm
                                             </a> <br />
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    )
                    }

                </React.Fragment>


            </div>
        </div>)
})

export default TypeForm;
