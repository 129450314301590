import React from "react";
import {
    AlertBoxConfirm
} from 'shoutout_themes';

class ConfirmBox extends React.PureComponent {
    render() {
        const { show, onHide, confirmCallback, title, message, disableActions = false, variant } = this.props;
        return (
            <AlertBoxConfirm show={show} handleClose={onHide} handleOk={confirmCallback} body={message} title={title} variant={variant} btnDisable={disableActions} />
        );
    }
};

export default ConfirmBox;