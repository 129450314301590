/**
 * Created by madura on 1/27/17.
 */
import React from "react";
import {
    Badge, Row, Col, Card,FaIcon
} from 'shoutout_themes';
import {faCheckCircle,} from 'shoutout_themes/es/FaIconsSolid';
import {LoadingComponent} from './../../utils/UtilComponents';

const PeopleUpload = ({tags, isCreateSegment, fileName, columnMap, segmentTagIndex, segmentName, peopleUploading}) => {


    return (
        <Card>
            <Card.Body>
            <Row>
                <Col sm={10}  className="text-left">
                    <Row>
                        <Col className="margin-top-15" sm={1}><span className="text-success"><FaIcon icon={faCheckCircle} size="2x"/></span></Col>
                        <Col sm={3} className="import-summary">Selected File:</Col>
                        <Col sm={8} className="import-summary">{fileName}</Col>
                    </Row><br/>
                    <hr/>
                    <Row>
                        <Col className="margin-top-15" sm={1}><span className="text-success"><FaIcon icon={faCheckCircle} size="2x"/></span></Col>
                        <Col sm={3} className="import-summary">Mapped Attributes:</Col>
                        <Col sm={8} className="import-summary">
                            {Object.keys(columnMap).map(column => {
                                return (columnMap[column].mapWith ?
                                    <span className="mapped-attribute"
                                          key={column}>{columnMap[column].label}</span> : null)
                            })}
                        </Col>
                    </Row>
                    <br/>
                    <hr/>
                    <Row>
                        <Col  className="margin-top-15" sm={1}><span className="text-success"><FaIcon icon={faCheckCircle} size="2x"/></span></Col>
                        <Col sm={3} className="import-summary">Tags:</Col>
                        <Col sm={8} className="import-summary">{tags.map(tag => {
                            return (<Badge key={tag} className="mr-1" variant="secondary">{tag}</Badge>)
                        })}
                        </Col>

                    </Row>
                    <br/>
                    <hr/>
                    {isCreateSegment && <Row>
                        <Col className="margin-top-15" sm={1}><span className="text-success"><FaIcon icon={faCheckCircle} size="2x"/></span></Col>
                        <Col sm={3} className="import-summary">Segment:</Col>
                        <Col sm={8} className="import-summary">
                            <p>{segmentName}&nbsp;&nbsp;
                                <small className="text-muted">Based on tag: <Badge>{tags[segmentTagIndex]}</Badge>
                                </small>
                            </p>

                        </Col>
                    </Row>}
                </Col>
            </Row>
            {peopleUploading && <LoadingComponent/>}
            </Card.Body>
        </Card>
    );
};
export default PeopleUpload;