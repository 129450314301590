import SendMessage from './../../components/utils/SendMessage';

import AlertBox from './../../components/utils/AlertBox';
import DateRangeSelector from './../../components/utils/DateRangeSelectorV2';
import {Preview} from 'shoutout_email_editor';
import { connect } from './../../contexts/ContextUtils';
import {
    sendDirectMessage, showSendMessage, showAlertBox, showEmailPreview
} from './../actions/UtilActions';
const SendMessageContainer = connect(
    (state) => { //map state to props
        let transportData = state.util.sendMessage[state.util.sendMessage.transport.toLowerCase()];
        return {
            show: state.util.sendMessage.show,
            isSending: state.util.sendMessage.isSending,
            transport: state.util.sendMessage.transport,
            message: transportData.message,
            senderId: transportData.senderId,
            destination: transportData.to
        };
    },

    (dispatch, ownProps) => {
        return {
            sendMessage: (from, to, message, transport) => dispatch(sendDirectMessage(from, to, message, transport)),
            showSendMessage: (isShow) => dispatch(showSendMessage(false))
        };

    }
)(SendMessage);





const AlertBoxContainer = connect(
    (state) => { //map state to props
        return {
            message: state.util.alertBox.message,
            title: state.util.alertBox.title,
            show: state.util.alertBox.show,
            type: state.util.alertBox.type
        };
    },

    (dispatch, ownProps) => {
        return {
            onClose: () => dispatch(showAlertBox('', '', false))
        };

    }
)(AlertBox);

const EmailPreviewContainer = connect(
    (state) => { //map state to props
        return {
            show: state.util.email.preview.show,
            html: state.util.email.preview.body
        };
    },

    (dispatch, ownProps) => {
        return {
            onClose: () => dispatch(showEmailPreview(false))
        };

    }
)(Preview);

const DateRangeSelectorContainer = connect(
    (state) => { //map state to props
        return {

        };
    },

    (dispatch, ownProps) => {
        return {

        };

    }
)(DateRangeSelector);
export default SendMessageContainer;
export {  AlertBoxContainer, EmailPreviewContainer, DateRangeSelectorContainer};
