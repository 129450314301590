/**
 * Created by madura on 2/2/17.
 */
import React, { Component } from "react";
import {
    Button, Card, Form, OverlayTrigger, Tooltip, Typeahead,FaIcon
} from 'shoutout_themes';
import {faQuestionCircle} from 'shoutout_themes/es/FaIconsSolid';

import { map, omitBy, isObject } from 'lodash';

class PeopleImportSettings extends Component {

    constructor(props) {
        super(props);
        this._showOtherUserIdOptions = this._showOtherUserIdOptions.bind(this);
        this._onChangeUserId = this._onChangeUserId.bind(this);
        this.state = { showOtherUserIdOptions: false };
        this._onChangeTags = this._onChangeTags.bind(this);
    }

    componentDidMount() {
        let shouldUpdateUniqueId = !this.props.uniqueId || (this.props.columnMap[this.props.uniqueId] && !this.props.columnMap[this.props.uniqueId].mapWith);
        if (this.props.columnMap['mobile_number'].mapWith) {
            if (shouldUpdateUniqueId) {
                this.props.updateUniqueIdMap('mobile_number');
            }
        } else if (this.props.columnMap['email'].mapWith) {
            if (shouldUpdateUniqueId) {
                this.props.updateUniqueIdMap('email');
            }
        }
    }

    _showOtherUserIdOptions(show) {
        this.props.updateUniqueIdMap('');
        this.setState({ showOtherUserIdOptions: show });
    }

    _onChangeUserId(newId) {
        this._showOtherUserIdOptions(false);
        this.props.updateUniqueIdMap(newId);
    }

    _getOtherUserIdOptions(columnMap) {
        let filteredMap = omitBy(columnMap, (column, key) => {
            return (key === 'mobile_number' || key === 'email' || !column.mapWith);
        });
        return map(filteredMap, (item, key) => {
            return <option key={key} value={item.mapWith}>{item.label}</option>;
        })
    }
    _onChangeTags(newTagList) {
        let tagList = map(newTagList, (tag) => {
            if (isObject(tag)) {
                return tag.label;
            }
            return tag;
        });
        this.props.setTags(tagList);
    }

    _updateUniqueIdMap = (e) => {
        this.props.updateUniqueIdMap(e.target.value);
    }

    render() {
        const { tags, exisitngTagList, isCreateSegment, checkedCreateSegment, setSegmentName, updateSegmentTag, segmentTagIndex, segmentName, uniqueId, columnMap } = this.props;
        const { showOtherUserIdOptions } = this.state;
        return (<Card className="text-left">
            <Card.Body>
                <p><strong>Uniquely identify these people by</strong> <OverlayTrigger placement="right" overlay={<Tooltip id="unique-id-tooltip">Unique id is use by ShoutOUT for uniquely identify the person</Tooltip>}>
                    <Button variant="link" size="xsmall"><span className="text-primary"><FaIcon icon={faQuestionCircle} /></span></Button>
                </OverlayTrigger></p>
                <Form.Check
                    name="unique_id" checked={!showOtherUserIdOptions && uniqueId === columnMap['mobile_number'].mapWith}
                    onChange={() => {
                        this._onChangeUserId(columnMap['mobile_number'].mapWith)
                    }} disabled={!columnMap['mobile_number'].mapWith} label="Mobile Number" id="mobileNumberMap" />
                <Form.Check
                    name="unique_id" checked={!showOtherUserIdOptions && uniqueId === columnMap['email'].mapWith}
                    onChange={() => {
                        this._onChangeUserId(columnMap['email'].mapWith)
                    }} disabled={!columnMap['email'].mapWith} label="Email" id="emailMap" />
                <Form.Check
                    name="unique_id" checked={showOtherUserIdOptions} onChange={() => {
                        this._showOtherUserIdOptions(true)
                    }} label="Other Options" id="otherOptions" />


                {showOtherUserIdOptions &&
                    <div>
                        <p className="text-danger">Are you sure about this action? This will change how your contacts uniquely
                    identified inside ShoutOUT</p>

                        <Form.Control as="select" required onChange={this._updateUniqueIdMap}

                            value={uniqueId}
                        >
                            <option disabled value="">Select unique id</option>
                            {this._getOtherUserIdOptions(columnMap)}
                        </Form.Control>


                    </div>
                }
                <hr />
                <p><strong>Tags</strong> <OverlayTrigger placement="right" overlay={<Tooltip id="tag-tooltip">You can tag these people. Later on you can use that tag to filter this people list from your contacts.</Tooltip>}>
                    <Button variant="link" size="xsmall"><span className="text-primary"><FaIcon icon={faQuestionCircle} /></span></Button>
                </OverlayTrigger></p>
                <div>
                    <Form>
                        <Form.Group>
                            <Typeahead
                                clearButton
                                defaultSelected={tags}
                                multiple
                                allowNew
                                options={exisitngTagList}
                                newSelectionPrefix="Add a new tag: "
                                placeholder="Add a tag"
                                onChange={this._onChangeTags}
                            />
                        </Form.Group>
                    </Form>
                </div>
                <hr />
                <Form.Check checked={isCreateSegment} className="mt-2" onChange={(e) => {
                    checkedCreateSegment(e.target.checked)
                }} label={<>Create a segment for these people <OverlayTrigger placement="right" overlay={<Tooltip className="padding-0" id="segment-tooltip">Segment these people based on a tag. Later on you can easily select this people list from the segments.</Tooltip>}>
                    <Button variant="link" size="xsmall" className="p-0"><span className="text-primary"><FaIcon icon={faQuestionCircle} /></span></Button>
                </OverlayTrigger></>} id="segmentCreate" />
                {isCreateSegment && <div>
                    <Form.Group
                        controlId="segment"
                    >
                        <Form.Label>Segment Name:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Eg: Premium Customers"
                            onChange={(e) => setSegmentName(e.target.value)} value={segmentName || ""} />
                    </Form.Group>
                    <Form.Group
                        controlId="segment-tag"
                    >
                        <Form.Label>Create this segment using tag:</Form.Label>


                        <Form.Control as="select" required onChange={updateSegmentTag}

                            value={segmentTagIndex}
                        >
                            {tags.map((tag, i) => {
                                return (<option key={tag + "-" + i} value={i}
                                >{tag}</option>)
                            })
                            }
                        </Form.Control>

                    </Form.Group>
                </div>}
            </Card.Body>
        </Card>);

    }
}

export default PeopleImportSettings;