import { fetchGet, fetchPost, fetchPut, fetchDelete } from './CommonServiceUtils';
const BASE_URL = "https://api.getshoutout.com/couponservice/";

class ShoutOUTCouponsService {
    static getCouponTypes() {
        return fetchGet(BASE_URL + 'types');
    }
    static addCouponCodes(typeId, codes) {
        return fetchPost(BASE_URL + 'codes', { typeId, codes });
    }
    static createCoupontype(typeName) {
        return fetchPut(BASE_URL + 'types', { typeName });
    }
    static deleteCouponType(typeId){
        return fetchDelete(BASE_URL + 'types/'+typeId);
    }
}

export default ShoutOUTCouponsService;