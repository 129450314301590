import React, { createContext, useReducer, useEffect, useCallback, useContext } from "react";
import { UtilService, ShoutOUTService } from './../services/Services';
import { initialize } from './../redux/actions/CommonActions';
import { withRouter } from 'react-router-dom';
import QueryString from 'query-string';
import fetchIntercept from "fetch-intercept";
import { AnalyticsService } from './../services/Services';
import CommonContext from './ContextUtils';
import Constants from './../../src/Constants';
import UserAccessTemplates, { ACCESS_CONTROL_DATA } from './../constants/UserAccessTemplates';

const InitialUserContext = {
    isLoggedIn: false,
    initComplete: false,
    userRole: null,
    username: null,
    email: null,
    token: null,
    ownerId: null,
    smsCreditsOnDemand: 0,
    smsCreditsPlan: 0,
    emailCreditsOnDemand: 0,
    emailCreditsPlan: 0,
    emailVerified: true,
    plan: 'sub_starter',
    showGettingStarted: false,
    loadingUser: false,
    product: null,
    scopes: []
};


const USER_ACTIONS = {
    LOGIN: "login",
    LOGOUT: "logout",
    UPDATE: "updateUserInfo",
    INIT_COMPLETE: "userInitCompleted",
    GETTING_STARTED_COMPLETE: "gettingStartedCompleted",
    LOADING_USER: "loadingUser",
}

const Context = createContext();
const reducer = (state, { type, ...rest }) => {

    switch (type) {
        case USER_ACTIONS.LOGIN:
            {

                const { so_user_name: username, so_user_email: email, so_user_role: userRole, so_user_id: ownerId, product, scopes } = UtilService.decodeToken(rest.token);

                if (product !== Constants.BUILD_APP) {
                    localStorage.removeItem("token");
                    if (product === Constants.APP_LOYALTY) {
                        window.location.replace(Constants.LOYALTY_APP_URL + "?token=" + rest.token);
                    } else {
                        window.location.replace(Constants.ENGAGE_APP_URL + "?token=" + rest.token);
                    }

                }

                let userScopes = [];
                if (Array.isArray(scopes)) {
                    userScopes = Object.values([...ACCESS_CONTROL_DATA, ...scopes].reduce((result, {
                        moduleId,
                        ...rest
                    }) => {
                        result[moduleId] = {
                            ...(result[moduleId] || {}),
                            moduleId,
                            ...rest
                        };
                        return result;
                    }, {}))
                } else if (userRole) {
                    if (UserAccessTemplates[userRole]) {
                        userScopes = UserAccessTemplates[userRole];
                    } else {
                        userScopes = UserAccessTemplates.user;
                    }
                }

                const userScopeTransformed=userScopes.reduce((result,item)=>{
                    result[item.moduleId]=item;
                    return result;
                },{})

                return { ...state, username, email, userRole, ownerId, isLoggedIn: true, token: rest.token, product, scopes: userScopeTransformed }
            }

        case USER_ACTIONS.LOGOUT: {
            return { ...InitialUserContext }
        }

        case USER_ACTIONS.UPDATE: {
            return { ...state, ...rest };
        }

        case USER_ACTIONS.INIT_COMPLETE: {
            return { ...state, initComplete: true };
        }
        case USER_ACTIONS.GETTING_STARTED_COMPLETE: {
            return { ...state, showGettingStarted: false };
        }
        case USER_ACTIONS.LOADING_USER: {
            return { ...state, loadingUser: rest.isLoading }
        }

        default: {
            return state;
        }
    }
};



const UserContextContainer = (props) => {
    const [state, dispatch] = useReducer(reducer, InitialUserContext);
    const { dispatch: commonDispatch } = useContext(CommonContext);

    const customDispatch = useCallback(async (action) => {

        switch (action.type) {
            case USER_ACTIONS.LOGIN: {
                localStorage.setItem("token", action.token);

                fetchIntercept.register({
                    request: function (url, config = {}) {
                        if (config['isProtected']) {
                            config['headers']['Authorization'] = 'Bearer ' + action.token;
                            delete config.isProtected;
                        }
                        if (config['showLoader']) {
                            // ctx.props.changeLoaderVisibility(false);
                            delete config.showLoader;
                        }
                        if (config['track'] && Object.keys(config['track']).length > 0) {
                            AnalyticsService.trackActivity(config['track']);
                            delete config.track
                        }
                        return [url, config];
                    },

                    requestError: function (error) {
                        return Promise.reject(error);
                    },

                    response: function (response) {
                        // ctx.props.changeLoaderVisibility(true);
                        if ((response.status === 401) && response.url.indexOf('/api/accounts/') === -1) {
                            customDispatch({ type: USER_ACTIONS.LOGOUT });
                        }
                        if (!response.ok) {
                            AnalyticsService.trackFailedActivity({
                                'name': 'error',
                                'data': { status: response.status, url: response.url, statusText: response.statusText }
                            });
                        }
                        return response;
                    },

                    responseError: function (error) {
                        // ctx.props.changeLoaderVisibility(true);
                        AnalyticsService.trackFailedActivity({ 'name': 'error', 'data': error });
                        return Promise.reject(error);
                    }
                });


                const { so_user_name: username, so_user_email: email, so_user_role: userRole, so_user_id: ownerId } = UtilService.decodeToken(action.token);

                dispatch({ type: USER_ACTIONS.LOGIN, userRole, username, email, token: action.token, ownerId });
                if (action.callback) {
                    action.callback();
                }

                dispatch({ type: USER_ACTIONS.LOADING_USER, isLoading: true });
                ShoutOUTService.getUser().then(res => {
                    const { role, credits: smsCreditsOnDemand, credits_plan: smsCreditsPlan, email_quota: emailCreditsPlan, email_credits: emailCreditsOnDemand, plan, email_verified: emailVerified, ...rest } = res;
                    let planTransformed = plan;
                    if (plan === "engage_free" || plan === "trial" || plan === null) {
                        planTransformed = "sub_starter";
                    }
                    dispatch({ type: USER_ACTIONS.UPDATE, smsCreditsOnDemand, smsCreditsPlan, emailCreditsOnDemand, emailCreditsPlan, plan: planTransformed, emailVerified, ...rest });
                    dispatch({ type: USER_ACTIONS.LOADING_USER, isLoading: false });
                }, err => {
                });

                commonDispatch(initialize());
                setTimeout(() => {
                    try {
                        const dataLayer = window.dataLayer || [];
                        dataLayer.push({
                            'event': 'login',
                            name: username,
                            email: email,
                            user_id: ownerId
                        });
                    } catch (e) {

                    }
                }, 4000);

                break;
            }

            case USER_ACTIONS.LOGOUT: {
                localStorage.removeItem('token');
                dispatch({ type: USER_ACTIONS.LOGOUT });
                window.location.replace(`https://getshoutout.com/logout?referrer=${window.location.origin}`);
                break;
            }
            default: {
                dispatch(action);
            }
        }
    }
    );

    const hideGettingStarted = useCallback(() => {
        dispatch({ type: USER_ACTIONS.GETTING_STARTED_COMPLETE, showGettingStarted: false })
    }, [])

    useEffect(() => {
        const queryParams = QueryString.parse(props.location.search);
        if (queryParams['token']) {
            let { token } = queryParams;
            customDispatch({ type: USER_ACTIONS.LOGIN, token });
            props.history.push("/");

        } else {
            const token = localStorage.getItem('token');
            if (token) {
                customDispatch({ type: USER_ACTIONS.LOGIN, token });
            }
        }
        customDispatch({ type: USER_ACTIONS.INIT_COMPLETE });
    }, []);




    const value = { ...state, logout: () => customDispatch({ type: USER_ACTIONS.LOGOUT }), dispatch: customDispatch, hideGettingStarted };
    

    return (
        <Context.Provider value={value}>{props.children}</Context.Provider>
    );
}


export default Context;
const UserContextContainerWithRouter = withRouter(UserContextContainer);
export { InitialUserContext, UserContextContainerWithRouter as UserContextContainer };