/**
 * Created by madura on 2/7/17.
 */
import React, { useState, useContext, useEffect, useCallback } from 'react';
import {withRouter} from 'react-router-dom';
import PeopleListContainer, {
    PeopleFilterContainer,
    PeopleSegmentContainer,
    PeopleSegmentCreateContainer,
    PeopleActionsContainer,
    PeopleActionsOnboardingContainer
} from './../../redux/containers/PeopleListContainer';
import UserContext from './../../contexts/UserContext';

import { Row, Col, Card } from 'shoutout_themes';
import Constants from './../../Constants';




const PeoplePageContent = withRouter(({history,contactsAvailable}) => {

    const [showSegments, setShowSegments] = useState(true);
    const [showFilters, setShowFilters] = useState(true);
    const { showGettingStarted ,scopes} = useContext(UserContext);

    const onClickSegments = useCallback(() => {
        setShowSegments(!showSegments)
    }, [setShowSegments, showSegments])
    const onClickFilters = useCallback(() => {
        setShowFilters(!showFilters)
    }, [setShowFilters, showFilters])


    useEffect(() => {
        if (showGettingStarted) {
            setShowSegments(false);
            setShowFilters(false);
        }
    }, [showGettingStarted])

    return (
        <div className="people-wrapper container-fluid h-100">
            <Row className="h-100 pt-3">
                <Col xs={12}>
                    <Card className="mb-3 rounded-0 border-0 shadow">

                        {(showGettingStarted && contactsAvailable) ? <PeopleActionsOnboardingContainer/> :
                            <PeopleActionsContainer showSegments={showSegments} onClickSegments={onClickSegments} showFilters={showFilters} onClickFilters={onClickFilters} />
                        }
                    </Card>
                    <div className="d-flex h-100">
                        <div className={`${showSegments ? "show-segments  mr-3" : "hide-segments"}`}>
                        {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.PEOPLE.moduleId].write &&<PeopleSegmentCreateContainer />}
                            <PeopleSegmentContainer />
                        </div>
                        <div className="w-100">
                            <div className={`${showFilters ? "show-filters" : "hide-filters"}`} >
                                <PeopleFilterContainer />
                            </div>
                            <PeopleListContainer history={history} />
                        </div>
                    </div>
                </Col>
            </Row>


        </div>
    );
}
)

export default PeoplePageContent;
