/**
 * Created by madura on 1/27/17.
 */
import React from "react";
import {
    Card, Row, Col, DropdownButton, DropdownItem, Typeahead,FaIcon
} from 'shoutout_themes';
import {faCheckCircle} from 'shoutout_themes/es/FaIconsSolid';
import AddCustomAttributeDialog from './PeopleAddCustomAttribute';
import { countries } from 'country-data';
import { sortBy } from 'lodash';
import FlagIconFactory from 'react-flag-icon-css';
import { LoadingComponent } from './../../utils/UtilComponents';

const FlagIcon = FlagIconFactory(React, { useCssModules: false });

let countryList = sortBy(countries.all, ['name']);
let selectedHeader = '';

const PeopleMapAttributes = ({ fileHeaders, fileSample, columnNames, country, headerMap, mapHeader, setCountry, selectAddCustomAttr, isSelectCustomAttr, addCustomAttribute, fileUploading, reservedAttributes }) => {
    return (
        fileUploading ? <div>
            <LoadingComponent />
        </div> : <div className="map-attributes">
                <h4>Preview and map your people attribute</h4>
                <p>Map your people attributes to ShoutOUT attributes.</p>
                {
                    fileHeaders.map((header, index) => {
                        return (
                            <Card key={`header-${index}`} className="mt-2">
                                <Card.Body>
                                    <div className="d-flex flex-row">
                                        <div className="flex-fill text-left">
                                            <strong>{header}</strong>
                                            {fileSample.map(data => {
                                                return (<p key={data[header]}>{data[header]}</p>)
                                            })
                                            }

                                        </div>

                                        <div className="my-auto text-right">

                                            <DropdownButton
                                                title={headerMap[header] ? columnNames[headerMap[header]].label : "--Select--"}
                                                key={header + '-m'} id={header + '-did'} onSelect={(eventKey) => {
                                                    selectedHeader = header;
                                                    mapHeader(header, eventKey)
                                                }} className="map-selector" variant="primary">

                                                {Object.keys(columnNames).map((column, index) => {
                                                    return (<DropdownItem eventKey={column} key={column + "-" + index}
                                                        disabled={columnNames[column].mapWith !== null}>{columnNames[column].label}</DropdownItem>)
                                                })}
                                                <DropdownItem key="00" divider />
                                                <DropdownItem key="01" onClick={() => {
                                                    selectedHeader = header;
                                                    selectAddCustomAttr(true)
                                                }}><strong>Create custom attribute</strong></DropdownItem>
                                                <DropdownItem key="02" eventKey="_null">Do not upload</DropdownItem>
                                            </DropdownButton>



                                        </div>
                                        <div className="check-icon my-auto ml-2">
                                            {headerMap[header] &&
                                                <span className="header-map-correct text-success">&nbsp;&nbsp;<FaIcon
                                                    icon={faCheckCircle}
                                                /></span>}
                                        </div>
                                    </div>
                                    {/*<Col sm={2} md={2} xs={2} className="text-left">
                                    <Row>

                                        {headerMap[header] &&
                                        <span className="header-map-correct text-success">&nbsp;&nbsp;<FaIcon
                                            icon={faCheckCircle}
                                            /></span>}
                                    </Row>
                                    <Row>

                                    </Row>
                                </Col>*/}

                                    {
                                        headerMap[header] && headerMap[header] === 'mobile_number' &&
                                        <div><br />
                                            <p><strong> To which country does this number base belong?</strong></p>

                                            <Row className="justify-content-center">
                                                <Col xs={12} sm={6} >
                                                    <div className="text-left">
                                                        <Typeahead
                                                            onChange={(selections) => {
                                                                if (selections && selections.length > 0) {
                                                                    setCountry(selections[0].alpha2, selections[0].name);
                                                                } else {
                                                                    setCountry(null, null);
                                                                }
                                                            }}
                                                            placeholder={'Select Country'}

                                                            options={countryList}
                                                            labelKey={option => `${option.name}`}
                                                            renderMenuItemChildren={(result, props) => {
                                                                return <span><span className="default-flag"><FlagIcon code={result.alpha2.toLowerCase()} /></span>&nbsp;&nbsp;{result.name}</span>
                                                            }}
                                                            filterBy={['name']}
                                                            size="sm"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className="text-muted"><small>NOTE: If selected, the numbers which are not in the standard international format will be automatically corrected.</small></div>
                                        </div>
                                    }

                                </Card.Body>
                            </Card>
                        )
                    })
                }
                <AddCustomAttributeDialog showAddCustomAttrDialog={isSelectCustomAttr}
                    closeAddCustomAttrDialog={() => selectAddCustomAttr(false)}
                    onSubmit={(name, type) => addCustomAttribute(name, type, selectedHeader)}
                    reservedAttributes={reservedAttributes} />
            </div>

    );
};

export default PeopleMapAttributes;