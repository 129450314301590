import React, { Component } from 'react';
import { OverlayTrigger, Tooltip, Button, FaIcon } from 'shoutout_themes';
import { faQuestionCircle } from 'shoutout_themes/es/FaIconsSolid';
export default class MultiStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPreviousBtn: false,
            showNextBtn: true,
            compState: this.props.activeStep || 0,
            navState: this.getNavStates(this.props.activeStep || 0, this.props.steps.length),
            nextBtnText: 'Next'
        };
        this.hidden = {
            display: 'none'
        };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    getNavStates(indx, length) {
        let styles = [];
        for (let i = 0; i < length; i++) {
            if (i < indx) {
                styles.push('done')
            }
            else if (i === indx) {
                styles.push('doing')
            }
            else {
                styles.push('todo')
            }
        }
        return { current: indx, styles: styles }
    }

    checkNavState(currentStep) {

        if (currentStep > 0 && currentStep < this.props.steps.length - 1) {
            this.setState({
                showPreviousBtn: true,
                showNextBtn: true,
                nextBtnText: this.props.steps[currentStep].nextBtnName || "Next"

            })
        }
        else if (currentStep === 0) {
            this.setState({
                showPreviousBtn: false,
                showNextBtn: true,
                nextBtnText: this.props.steps[currentStep].nextBtnName || "Next"
            })
        }

        else if (currentStep === this.props.steps.length - 1) {
            this.setState({
                showPreviousBtn: true,
                showNextBtn: this.props.steps[currentStep].showNextBtn || false,
                nextBtnText: this.props.steps[currentStep].nextBtnName || "Next"
            })
        }
    }

    setNavState(next) {
        this.setState({ navState: this.getNavStates(next, this.props.steps.length) });
        if (next < this.props.steps.length) {
            this.setState({ compState: next},
                
                () => {
                    if(this.props.onChangeActiveStep){
                    this.props.onChangeActiveStep(this.state.compState);
                }
     
            }
            );

        }
        this.checkNavState(next);
    }

    next(e) {
        e.preventDefault();
        const isDisabledNextBtn = this.props.steps[this.state.compState].isDisableNextBtn;
        if (!isDisabledNextBtn) {
            this.setNavState(this.state.compState + 1);

            if (this.props.steps[this.state.compState].onClickNext) {
                this.props.steps[this.state.compState].onClickNext();
            }
           
        }
    }

    previous(e) {
        e.preventDefault();
        if (this.state.compState > 0) {
            this.setNavState(this.state.compState - 1)
        }
    }

    getClassName(className, i) {
        return className + "-" + this.state.navState.styles[i];
    }

    renderSteps() {
        return this.props.steps.map((s, i) => (
            <li className={this.getClassName("progtrckr", i)} key={i} value={i}>
                <em>{i + 1}</em>
                <small className="step-name">{this.props.steps[i].name}</small>
                <span className="progress-line"></span>
            </li>
        ));
    }


    render() {

        const nextBtnTooltipText = this.props.steps[this.state.compState].nextBtnTooltip;
        const disabledNextBtn = this.props.steps[this.state.compState].isDisableNextBtn;
        const { fixedStepBar, showNavigation } = this.props;
        let stepBtnStyles = {};
        if (fixedStepBar) {
            stepBtnStyles = { ...stepBtnStyles, "position": "fixed" };
        }
        if (!showNavigation) {
            stepBtnStyles = { ...stepBtnStyles, display: "none" };
        }
        return (
            <>
                <ol className="progtrckr py-3 px-0">
                    {this.renderSteps()}
                </ol>
                <div className="steps d-flex flex-column flex-fill">
                    {this.props.steps[this.state.compState].component}
                    <div className="flex-fill" />
                    <div className="step-buttons shadow-sm w-100">
                        <div className="d-flex justify-content-between p-3">

                            <Button variant="dark" className="multistep__btn--prev" disabled={!this.state.showPreviousBtn} onClick={this.previous}>Previous</Button>



                            <div>
                                <Button variant="primary" className="multistep__btn--next" disabled={disabledNextBtn} onClick={this.next}>{this.state.nextBtnText}</Button>
                                {nextBtnTooltipText && <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">{nextBtnTooltipText}</Tooltip>}>
                                    <Button variant="link" size="xsmall"><span className="text-primary"><FaIcon icon={faQuestionCircle} /></span></Button>
                                </OverlayTrigger>}

                            </div>


                        </div>
                    </div>
                </div>
            </>
        );
    }
}

MultiStep.defaultProps = {
    showNavigation: true
};
