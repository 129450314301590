/**
 * Created by madura on 1/27/17.
 */
import { connect } from './../../contexts/ContextUtils';
import {
    uploadFile,
    fileSelected,
    removeFile,
    addCustomColumn,
    mapHeader,
    setCountry,
    checkedCreateSegment,
    selectAddCustomAttr,
    uploadPeople,
    setSegmentName,
    updateSegmentTag,
    updateUniqueIdMap,
    setTags,
    showImportWizard
} from './../actions/PeopleImportActions';
import ImportPeople from './../../components/people/import/PeopleImport';
import UploadFile from './../../components/people/import/UploadFile';
import PeopleMapAttributes from './../../components/people/import/MapAttributes';
import PeopleUpload from './../../components/people/import/UploadPeople';
import ImportSettings from './../../components/people/import/ImportSettings';
import { keys } from 'lodash';
import Constants from './../../Constants';


const PeopleImportContainer = connect(
    (state) => {//map state to props
        return {
            fileName: state.peopleImport.fileName,
            file: state.peopleImport.file,
            fileType: state.peopleImport.fileType,
            fileId: state.peopleImport.fileId,
            columnNameMap: state.peopleImport.columnNameMap,
            tags: state.peopleImport.tags,
            country: state.peopleImport.country,
            isCreateSegment: state.peopleImport.isCreateSegment,
            segmentName: state.peopleImport.segmentName,
            segmentTagIndex: state.peopleImport.segmentTagIndex,
            uniqueId: state.peopleImport._uniqueId,
            showImportWizard: state.peopleImport.showImportWizard,
            peopleUploading: state.peopleImport.steps.uploadFile.peopleUploading 
        };
    },

    (dispatch, ownProps) => {
        return {
            uploadFile: (file, fileType) => {
                dispatch(uploadFile(file, fileType))
            },
            uploadPeople: (columnNameMap, tags, fileId, isCreateSegment, segmentName, segmentTagIndex, userId, country) => {
                dispatch(uploadPeople(columnNameMap, tags, fileId, isCreateSegment, segmentName, segmentTagIndex, userId, country))
            },
            hideImportWizard: () => {
                dispatch(showImportWizard(false))
            }
        };

    }
)(ImportPeople);
export default PeopleImportContainer;


const UploadFileContainer = connect(
    (state) => {//map state to props
        return {
            fileName: state.peopleImport.fileName
        };
    },

    (dispatch, ownProps) => {
        return {
            onChange: (files) => {
                if (files.length) dispatch(fileSelected(files[0]))
            },
            removeFile: () => {
                dispatch(removeFile())
            }
        };

    }
)(UploadFile);

const PeopleMapAttributesContainer = connect(
    (state) => {//map state to props
        return {
            fileHeaders: state.peopleImport.fileHeaders,
            fileSample: state.peopleImport.fileSample,
            columnNames: state.peopleImport.columnNameMap,
            headerMap: state.peopleImport.headerMap,
            isSelectCustomAttr: state.peopleImport.isSelectCustomAttr,
            country: state.peopleImport.country,
            fileUploading: state.peopleImport.steps.uploadFile.fileUploading,
            reservedAttributes: Constants.RESERVED_ATTRIBUTES
        };
    },

    (dispatch, ownProps) => {
        return {
            mapHeader: (header, mapWithColumn) => {
                dispatch(mapHeader(header, mapWithColumn))
            },
            selectAddCustomAttr: (isSelected) => {
                dispatch(selectAddCustomAttr(isSelected))
            },
            setCountry: (countryCode, countryName) => {
                dispatch(setCountry(countryCode, countryName))
            },
            addCustomAttribute: (columnName, columnType, mapToHeader) => {
                dispatch(addCustomColumn(columnName, columnType, mapToHeader))
            }
        };

    }
)(PeopleMapAttributes);



const UploadPeopleContainer = connect(
    (state) => {//map state to props
        return {
            tags: state.peopleImport.tags,
            isCreateSegment: state.peopleImport.isCreateSegment,
            fileName: state.peopleImport.fileName,
            columnMap: state.peopleImport.columnNameMap,
            segmentTagIndex: state.peopleImport.segmentTagIndex,
            segmentName: state.peopleImport.segmentName,
            peopleUploading: state.peopleImport.steps.uploadFile.peopleUploading 
        };
    },

    (dispatch, ownProps) => {
        return {
        };

    }
)(PeopleUpload);

const ImportSettingsContainer = connect(
    (state) => {//map state to props
        return {
            tags: state.peopleImport.tags,
            isCreateSegment: state.peopleImport.isCreateSegment,
            columnMap: state.peopleImport.columnNameMap,
            segmentName: state.peopleImport.segmentName,
            segmentTagIndex: state.peopleImport.segmentTagIndex,
            uniqueId: state.peopleImport._uniqueId,
            headerMap: state.peopleImport.headerMap,
            exisitngTagList: keys(state.common.tags) || [],
        };
    },

    (dispatch, ownProps) => {
        return {
            setSegmentName: (segmentName) => {
                dispatch(setSegmentName(segmentName))
            },
            updateSegmentTag: (e) => {
                dispatch(updateSegmentTag(e.target.value))
            },
            updateUniqueIdMap: (mapWith) => {
                dispatch(updateUniqueIdMap(mapWith))
            },
            mapHeader: (header, mapWithColumn) => {
                dispatch(mapHeader(header, mapWithColumn))
            },
            setTags: (tagList) => {
                dispatch(setTags(tagList))
            },
            checkedCreateSegment: (isChecked) => {
                dispatch(checkedCreateSegment(isChecked))
            }
        };

    }
)(ImportSettings);

export { UploadFileContainer, PeopleMapAttributesContainer, UploadPeopleContainer, ImportSettingsContainer };