import React, { lazy, useContext } from "react";
import TopNavigationBar from './../navigation/TopNavigationBar';
import { Nav } from 'shoutout_themes';

import Constants from "./../../Constants";

import { EngagementCampaigns, EngagementOverview, EngagementCoupons, EngagementCampaignReport, EngagementInbound } from './EngagementComponents';
import { TemplatePageContainer } from './../../redux/containers/TemplateContainer';

import CampaignCreatePage from './campaigns/create/CampaignCreatePage';
import { Switch, Route, Link } from 'react-router-dom';
import UserContext from './../../contexts/UserContext';
const ResponsesContainer = lazy(() => import('./../../redux/containers/EventContainer'));


const EngagementModule = ({ location }) => {
    const { emailVerified, scopes } = useContext(UserContext);
    return (
        <div className="engagement height-inherit d-flex flex-column">



            {Constants.BUILD_APP === "LOYALTY" &&
                <TopNavigationBar>
                    {/*<LinkContainer to="/engagement/overview">
                        <Nav.Link>Overview</Nav.Link>
        </LinkContainer>*/}
                    {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.CAMPAIGNS.moduleId].read &&
                        <>
                            <Nav.Link as={Link} href="/engagement/campaigns" to="/engagement/campaigns" active={location.pathname === '/engagement/campaigns'}>Campaigns</Nav.Link>

                            <Nav.Link as={Link} href="/engagement/templates" to="/engagement/templates" active={location.pathname === '/engagement/templates'}>Templates</Nav.Link>
                        </>
                    }
                    <Nav.Link as={Link} href="/engagement/coupons" to="/engagement/coupons" active={location.pathname === '/engagement/coupons'}>Coupons</Nav.Link>

                    {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.RESPONSE.moduleId].read &&
                        <Nav.Link as={Link} href="/engagement/responses" to="/engagement/responses" active={location.pathname === '/engagement/responses'}>Responses</Nav.Link>
                    }
                    <Nav.Link as={Link} href="/engagement/inbound" to="/engagement/inbound" active={location.pathname === '/engagement/inbound'}>Inbound</Nav.Link>

                </TopNavigationBar>
            }
            <br />

            <Switch>


                <Route name="Overview" exact path="/engagement/overview" component={EngagementOverview} />
                {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.CAMPAIGNS.moduleId].read &&
                    <Route name="Campaigns" exact path="/engagement/campaigns" component={EngagementCampaigns} />
                }
                {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.CAMPAIGNS.moduleId].read &&
                    <Route name="Report" exact path="/engagement/campaigns/:campaignId" component={EngagementCampaignReport} />
                }
                {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.CAMPAIGNS.moduleId].write && emailVerified && <Route name="New Campaign" exact path="/engagement/campaigns/create/new" component={CampaignCreatePage} />}

                {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.CAMPAIGNS.moduleId].read &&
                    <Route name="Templates" path="/engagement/templates" component={TemplatePageContainer} />
                }
                <Route name="Coupons" path="/engagement/coupons" component={EngagementCoupons} />
                {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.RESPONSE.moduleId].read &&
                    <Route name="Responses" path="/engagement/responses" component={ResponsesContainer} />
                }
                <Route name="Inbound" path="/engagement/inbound" component={EngagementInbound} />
                {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.CAMPAIGNS.moduleId].read &&
                    <Route component={EngagementCampaigns} />
                }
            </Switch>
        </div>
    );

}

export default EngagementModule;