import React, { lazy, useContext, useEffect } from 'react';
import SideNavigationBar from './../navigation/SideNavigationBar';
import OnboardSection from '../user_onboarding/OnboardSection';
import SendMessageContainer, { AlertBoxContainer, EmailPreviewContainer } from './../../redux/containers/UtilContainer';
import UserContext from './../../contexts/UserContext';

import PeopleImportContainer from './../../redux/containers/PeopleImportContainer';
import { AddOrEditPersonContainer } from './../../redux/containers/PeopleListContainer';
import { Switch, Route } from 'react-router-dom';
import PeoplePage from './../people/PeoplePage';
import { PersonProfileContainer } from './../../redux/containers/PeopleListContainer';
import EngagementModule from './../engagement_module/EngagementModule';
import { MyProfileContainer } from './../../redux/containers/AppContainer';
import BillingPageContainer, { BillingPurchasePlanContainer } from './../../redux/containers/BillingContainer';
import AuthRedirectRoute from './../../AuthRedirectRoute';
import NotificationBar from './../../NotificationBar';
import Constants from '../../Constants';
const DashboardModule = lazy(() => import('./../dashboard_module/DashboardModule'));
const AdministratorModule = lazy(() => import('./../administrator_module/AdministratorModule'));
const AnalyticsModule = lazy(() => import('./../analytics_module/AnalyticsModule'));
const LoyaltyModule = lazy(() => import('./../loyalty_module/LoyaltyModule'));
const IntegrationsModule = lazy(() => import('./../integrations/IntegrationsPage'));


const Home = ({ history }) => {
    const { showGettingStarted, scopes } = useContext(UserContext);

    useEffect(() => {
        if (showGettingStarted) {
            history.push("/people");
        }
    }, [showGettingStarted])

    return (
        <div className="home-wrapper d-flex">
            <SideNavigationBar />
            {showGettingStarted && <OnboardSection />}
            <div className="overflow-auto w-100">
                <NotificationBar />

                <Switch>
                    {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.PEOPLE.moduleId].read &&

                        <Route name="People" exact path="/people" component={PeoplePage} />
                    }
                    {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.PEOPLE.moduleId].read &&
                        <Route name="Profile" exact path="/people/:contactId" component={PersonProfileContainer} />
                    }

                    <Route exact name="My Profile" exact path="/my-profile" component={MyProfileContainer} />

                    <Route name="Engagement" path="/engagement" component={EngagementModule} />

                    {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.LOYALTY_ANALYTICS.moduleId].read &&
                        <Route exact={true} name="Analytics Module" path="/analytics" component={AnalyticsModule} />
                    }
                    <Route name="Loyalty Module" path="/loyalty" component={LoyaltyModule} />

                    <AuthRedirectRoute name="Administrator Module" path="/administrator" component={AdministratorModule} allowedRoles={["admin", "finance"]} />

                    <Route exact name="Billing" path="/billing" component={BillingPageContainer} />
                    <Route exact name="BillingPurchase" path="/billing/purchase" component={BillingPurchasePlanContainer} />
                    {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.APPS.moduleId].read &&
                        <Route name="Integrations" path="/integrations" component={IntegrationsModule} />
                    }
                    <Route name="Dashboard" component={DashboardModule} />
                </Switch>

            </div>
            <PeopleImportContainer />
            <AddOrEditPersonContainer />
            <SendMessageContainer history={history} />
            <AlertBoxContainer />
            <EmailPreviewContainer />
        </div>
    )

}

export default Home;
