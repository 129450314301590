/**
 * Created by madura on 2/7/17.
 */
import { connect } from './../../contexts/ContextUtils';
import {
    onChangeEmailSubject,
    onSelectSenderId,
    onChangeCampaignName,
    setCampaignSchedule,
    setScheduleDate,
    setScheduleTime,
    onCreateCampaign,
    selectEditor,
    onChangeEditorState,
    onSelectEvent,
    onSelectSegment,
    toggleTransport,
    onSelectCampaignType,
    selectImportPeople,
    selectCreatePerson,
    clickCreateCampaign,
    initSMSEditor,
    updateMetadata,
    reloadEditor,
    onChangeMessengerTemplate,
    onSelectMessengerTemplate,
    changeActiveStep,
    changeSmsCampaignType
} from './../actions/CampaignCreateActions';
import {
    saveTemplate,
    chooseTemplate
} from './../actions/TemplateActions';

import CampaignCreate from './../../components/engagement_module/campaigns/create/CampaignCreate';
import CampaignSettings from './../../components/engagement_module/campaigns/create/CampaignSettings';
import CampaignSummary from './../../components/engagement_module/campaigns/create/CampaignSummary';
import CampaignMessageConfig from './../../components/engagement_module/campaigns/create/CampaignMessageConfig';
import CampaignSelectSegment from './../../components/engagement_module/campaigns/create/CampaignSelectSegment';
import CampaignSelectEvent from './../../components/engagement_module/campaigns/create/CampaignSelectEvent';
import CampaignSelection from '../../components/engagement_module/campaigns/create/CampaignSelection';
import CampaignCreateButton from '../../components/engagement_module/campaigns/create/CampaignCreateButton';
import Constants from './../../Constants';
import moment from 'moment';
import {
    reduce,
    chain
} from 'lodash';
import {
    showSendMessage
} from './../actions/UtilActions';
import {
    showImportWizard
} from './../actions/PeopleImportActions';
import {
    showAddEditPerson
} from './../actions/PeopleActions';

import asyncComponent from './../../components/utils/AsyncComponent';
import { UtilService } from './../../services/Services';
import { withRouter } from 'react-router-dom';
import { exportText } from 'shoutout_email_editor';

const SmsEditor = asyncComponent(() => import('./../../components/engagement_module/campaigns/create/SmsEditor'));
const EmailEditor = asyncComponent(() => import('./../../components/engagement_module/campaigns/create/EmailEditor'));
const MessengerEditor = asyncComponent(() => import('./../../components/engagement_module/campaigns/create/MessengerEditor'));
const curlyBracesRegex = new RegExp("\{{[^)]*\}}", "g");
const CampaignCreateContainer = withRouter(connect(
    (state) => { //map state to props
        return {
            isDisableNextStep3: ((state.createCampaign.transports.indexOf('SMS') > -1 && (state.createCampaign.sms.editorState !== "" && !state.createCampaign.sms.editorState.getCurrentContent().hasText() || state.createCampaign.sms.characterOverLimit)) || (state.createCampaign.transports.indexOf('EMAIL') > -1 && (state.createCampaign.email.editorState !== "" && !state.createCampaign.email.editorState.getCurrentContent().hasText() || !state.createCampaign.email.subject)) ||
                (state.createCampaign.transports.indexOf(Constants.TRANSPORT_MESSENGER) > -1 && Object.keys(state.createCampaign[Constants.TRANSPORT_MESSENGER.toLowerCase()].template).length === 0 && !state.createCampaign[Constants.TRANSPORT_MESSENGER.toLowerCase()].text)),
            isDisableNextStep2: (state.createCampaign.transports.length === 0),
            isDisableNextStep1: !state.createCampaign.name || !state.createCampaign.campaignType,
            name: state.createCampaign.name,
            isScheduled: state.createCampaign.isSchedule,
            launchOn: state.createCampaign.launchOn,
            selectedSegment: state.createCampaign.selectedSegment,
            transports: state.createCampaign.transports,
            selectedPeople: state.createCampaign.selectedPeople,
            sms: state.createCampaign.sms,
            email: state.createCampaign.email,
            messenger: state.createCampaign[Constants.TRANSPORT_MESSENGER.toLowerCase()],
            showSelectSegment: state.createCampaign.showSelectSegment,
            showSelectEvent: state.createCampaign.showSelectEvent,
            isDisableEventNextBtn: !state.createCampaign.selectedEvent,
            campaignType: state.createCampaign.campaignType,
            activeStep: state.createCampaign.activeStep,
            isDisableSegmentNextBtn: !state.createCampaign.selectedSegment.id && !state.createCampaign.selectedFilters.length && state.createCampaign.selectedPeopleCount === 0,
            selectedEvent: state.createCampaign.selectedEvent,
            excludedPeople: state.createCampaign.excludedPeople,
            selectedFilters: state.createCampaign.selectedFilters,
            isCreatingCampaign: state.createCampaign.steps.create.isCreating

        };
    },

    (dispatch, ownProps) => {
        return {
            onCreateCampaign: (name, isScheduled, launchOn, transports, selectedPeople, sms, email, isSelectAll, selectedSegment, campaignType, excludedPeople, selectedFilters, history, messenger) => {
                dispatch(onCreateCampaign(name, isScheduled, launchOn, transports, selectedPeople, sms, email, isSelectAll, selectedSegment, campaignType, excludedPeople, selectedFilters, history, messenger));
            },
            onChangeActiveStep: (newActiveStep) => {
                dispatch(changeActiveStep(newActiveStep))
            }
        };

    }
)(CampaignCreate));
export default CampaignCreateContainer;

export const CampaignMessageConfigContainer = connect(
    (state) => { //map state to props
        return {
            transports: state.createCampaign.transports,
            campaignType: state.createCampaign.campaignType,
            selectedEditor: state.createCampaign.activeEditor
        };
    },

    (dispatch, ownProps) => {
        return {
            selectEditor: (editorType) => {
                dispatch(selectEditor(editorType));
            }
        };

    }
)(CampaignMessageConfig);

const getSenderIdsForTransport = (state, transport) => {
    return chain(state.common.senderIds[transport.toUpperCase()]).filter(item => {
        return item.status === 1;
    }).map(item => {
        return item.senderId;
    }).value();

};

const getSenderIdsForMessenger = (state) => {
    return state.common.senderIds[Constants.TRANSPORT_MESSENGER].filter(item => {
        return item.status === 1;
    });

};

const getSmsLength = (editorState) => {
    if (editorState) {
        let text = editorState.getCurrentContent().getPlainText();
        return UtilService.getSmsLength(text);
    }
    return 0;
};

const getSmsPageCount = (smsLength, isUnicode, optoutMessageData = { length: 0 }) => {
    const smsLengthWithOptout = smsLength + optoutMessageData.length;
    if (!isUnicode) {
        if (smsLengthWithOptout < 161) {
            return 1;
        } else {
            return Math.ceil(smsLengthWithOptout / 153);
        }
    } else {
        if (smsLengthWithOptout < 71) {
            return 1;
        } else {
            return Math.ceil(smsLengthWithOptout / 67);
        }
    }
};
const getSelectedEvent = (state) => { //Return selected event from events
    return state.event.events.find(event => {
        return event._id === state.createCampaign.selectedEvent;
    });
};

const getEventAttributes = (state) => {
    let selectedEvent = getSelectedEvent(state);
    return selectedEvent ? selectedEvent.attributes : {};
};
const getCustomAttributes = (state) => {

    let peopleAttr = reduce(state.common.columnNameMap, (result, value, key) => {
        if (['tags', '_modified_on', '_created_on', 'user_id', '_email_valid', '_mobile_number_valid'].indexOf(key) < 0) {
            result[key] = { name: value.label, attr: key, type: "person" };
        }
        return result;
    }, {});
    let eventAttr = reduce(getEventAttributes(state), (result, value, key) => {
        if (['tags', '_modified_on', '_created_on', '_email_valid', '_mobile_number_valid'].indexOf(key) < 0) {
            result[key] = { name: value.label, attr: key, type: "event" };
        }
        return result;
    }, peopleAttr);
    return Object.values(eventAttr);
};
export const SmsEditorContainer = connect(
    (state, dispatch) => { //map state to props
        let smsLength = getSmsLength(state.createCampaign.sms.editorState);
        const selectedSenderIdObject = state.common.senderIds[Constants.TRANSPORT_SMS].find(({ senderId }) => senderId === state.createCampaign.sms.selectedSenderId)
        const optoutMessageData = UtilService.calculateOptoutMessageLength(selectedSenderIdObject, state.createCampaign.sms.campaignType);
        let maxCharacterCount = (state.createCampaign.sms.isUnicode ? 201 : 459) - optoutMessageData.length;

        const isCustomAttributesPresent = state.createCampaign.sms.editorState ? exportText(state.createCampaign.sms.editorState).match(curlyBracesRegex) !== null : false;
        return {

            editorState: state.createCampaign.sms.editorState,
            smsLength: smsLength,
            smsPages: getSmsPageCount(smsLength, state.createCampaign.sms.isUnicode, optoutMessageData),
            characterOverLimit: state.createCampaign.sms.characterOverLimit,
            isUnicode: state.createCampaign.sms.isUnicode,
            maxCharacterCount: maxCharacterCount,
            customAttributes: getCustomAttributes(state),
            reloadStatus: state.createCampaign.sms.shouldReload,
            optoutMessageData,
            campaignType: state.createCampaign.sms.campaignType,
            selectedSenderIdObject,
            isCustomAttributesPresent

        };
    },
    (dispatch, ownProps) => {
        return {

            onChangeEditorState: (editorState) => {
                dispatch(onChangeEditorState(editorState, Constants.TRANSPORT_SMS));
            },

            sendSampleSms: (message) => {
                dispatch(showSendMessage(true, {}, message, 'campaigns', Constants.TRANSPORT_SMS));
            },
            selectTemplate: () => {
                dispatch(chooseTemplate(true, Constants.TRANSPORT_SMS))
            },
            saveTemplate: (templateContent) => {
                dispatch(saveTemplate({ body: templateContent }, Constants.TRANSPORT_SMS))
            },
            initEditor: () => {
                dispatch(initSMSEditor())
            },
            reloadEditor: (status) => {
                dispatch(reloadEditor(Constants.TRANSPORT_SMS, status));
            },
            onChangeSmsCampaignType: (campaignType) => {
                dispatch(changeSmsCampaignType(campaignType));
            }
        };

    }
)(SmsEditor);

export const EmailEditorContainer = connect(
    (state) => { //map state to props
        return {
            subject: state.createCampaign.email.subject,
            editorState: state.createCampaign.email.editorState,
            customAttributes: getCustomAttributes(state),
            metadata: state.createCampaign.metadata,
            billingMetadata: {
                address1: state.billing.billingInfo.streetAddress,
                address2: state.billing.billingInfo.streetAddress2
            },
            reloadStatus: state.createCampaign.email.shouldReload
        };
    },

    (dispatch, ownProps) => {
        return {
            onChangeSubject: (subject) => {
                dispatch(onChangeEmailSubject(subject));
            },

            onChangeEditorState: (editorState) => {
                dispatch(onChangeEditorState(editorState, Constants.TRANSPORT_EMAIL));
            },

            saveTemplate: (templateContent, subject) => {
                dispatch(saveTemplate({ subject, body: templateContent }, Constants.TRANSPORT_EMAIL))
            },
            selectTemplate: () => {
                dispatch(chooseTemplate(true, Constants.TRANSPORT_EMAIL))
            },
            sendSampleEmail: (templateContent, subject) => {
                dispatch(showSendMessage(true, {}, { body: templateContent, subject }, 'campaigns', Constants.TRANSPORT_EMAIL));
            },
            updateMetadata: (newMetadata) => {
                dispatch(updateMetadata(newMetadata));
            },
            reloadEditor: (status) => {
                dispatch(reloadEditor(Constants.TRANSPORT_EMAIL, status));
            }
        };

    }
)(EmailEditor);
const getScheduleDate = (date) => {
    return moment(date).format('YYYY-MM-DD');
};
const getScheduleTime = (date) => {
    return moment(date).format('HH:mm');
};
export const CampaignSettingsContainer = connect(
    (state) => { //map state to props
        return {
            isSchedule: state.createCampaign.isSchedule,
            scheduleOn: state.createCampaign.launchOn,
            scheduleDate: getScheduleDate(state.createCampaign.launchOn),
            scheduleTime: getScheduleTime(state.createCampaign.launchOn),
            campaignType: state.createCampaign.campaignType,
            transports: state.createCampaign.transports,
            senderIdsSms: getSenderIdsForTransport(state, Constants.TRANSPORT_SMS),
            selectedSenderIdSms: state.createCampaign.sms.selectedSenderId,
            senderIdsEmail: getSenderIdsForTransport(state, Constants.TRANSPORT_EMAIL),
            senderIdsMessenger: getSenderIdsForMessenger(state),
            selectedSenderIdEmail: state.createCampaign.email.selectedSenderId,
            selectedSenderIdMessenger: state.createCampaign[Constants.TRANSPORT_MESSENGER.toLowerCase()].selectedSenderId,
            senderIds: state.common.senderIds
        };
    },

    (dispatch, ownProps) => {

        return {
            setCampaignSchedule: (isSchedule) => {
                dispatch(setCampaignSchedule(isSchedule));
            },
            setScheduleTime: (currentDate, time) => {
                dispatch(setScheduleTime(currentDate, time));
            },
            setScheduleDate: (currentDate, date) => {
                dispatch(setScheduleDate(currentDate, date));
            },
            toggleTransport: (transport) => {
                dispatch(toggleTransport(transport));
            },
            onSelectSenderId: (senderId, transport, senderIdList) => {
                dispatch(onSelectSenderId(senderId, transport, senderIdList));
            }
        };

    }
)(CampaignSettings);


const getDisplayNameOfMessengerSenderId = ({ createCampaign, common }) => {
    const messengerDetails = createCampaign[Constants.TRANSPORT_MESSENGER.toLowerCase()];
    if (messengerDetails.selectedSenderId) {
        const selectedSenderIdDetails = common.senderIds[Constants.TRANSPORT_MESSENGER].find(item => item.senderId === messengerDetails.selectedSenderId) || {};
        messengerDetails.displayName = selectedSenderIdDetails.displayName || "";
    }
    return messengerDetails;

}


export const CampaignSummaryContainer = connect(
    (state) => { //map state to props

        return {
            campaignName: state.createCampaign.name,
            isSchedule: state.createCampaign.isSchedule,
            scheduleDate: getScheduleDate(state.createCampaign.launchOn),
            scheduleTime: getScheduleTime(state.createCampaign.launchOn),
            selectedSegment: state.createCampaign.selectedSegment,
            transports: state.createCampaign.transports,
            selectedPeopleCount: state.createCampaign.selectedPeopleCount,
            campaignType: state.createCampaign.campaignType,
            selectedEvent: getSelectedEvent(state),
            isCreatingCampaign: state.createCampaign.steps.create.isCreating,
            showSelectSegment: state.createCampaign.showSelectSegment,
            excludedPeople: state.createCampaign.excludedPeople,
            selectedFilters: state.createCampaign.selectedFilters,
            transportDetails: { [Constants.TRANSPORT_SMS]: state.createCampaign.sms, [Constants.TRANSPORT_EMAIL]: state.createCampaign.email, [Constants.TRANSPORT_MESSENGER]: getDisplayNameOfMessengerSenderId(state) }
        };
    },
    (dispatch, ownProps) => {
        return {};

    }
)(CampaignSummary);

export const CampaignSelectSegmentContainer = connect(
    (state) => { //map state to props
        return {
            segments: state.common.segments,
            selectedSegmentId: state.createCampaign.selectedSegment.id,
            selectedFilters: state.createCampaign.selectedFilters,
            selectedPeople: state.createCampaign.selectedPeople
        };
    },

    (dispatch, ownProps) => {
        return {
            onSelectSegment: (selectedSegmentId, selectedSegment) => {
                dispatch(onSelectSegment(selectedSegmentId, selectedSegment))
            },
            clickedImportPeople: () => {
                dispatch(showImportWizard(true));
                dispatch(selectImportPeople());
            },
            showAddEditPerson: () => {
                dispatch(showAddEditPerson(true));
                dispatch(selectCreatePerson());
            }
        };

    }
)(CampaignSelectSegment);


export const CampaignSelectEventContainer = connect(
    (state) => { //map state to props
        return {
            events: state.event.events,
            selectedEvent: state.createCampaign.selectedEvent
        };
    },

    (dispatch, ownProps) => {
        return {
            onSelectEvent: (e) => {
                dispatch(onSelectEvent(e.target.value));
            }
        };

    }
)(CampaignSelectEvent);








const CampaignSelectionContainer = connect(
    (state) => { //map state to props
        return {
            campaignName: state.createCampaign.name,
            campaignType: state.createCampaign.campaignType,
            filterByType: state.createCampaign.isFromPeople ? "broadcast" : ""
        };
    },

    (dispatch, ownProps) => {
        return {
            onChangeCampaignName: (name) => {
                dispatch(onChangeCampaignName(name));
            },
            onChangeCampaignType: (campaignType) => {
                dispatch(onSelectCampaignType(campaignType))
            }
        };

    }
)(CampaignSelection);

const CampaignCreateButtonContainer = connect(
    (state) => { //map state to props
        return {
        };
    },

    (dispatch, ownProps) => {
        return {
            onCreateCampaign: () => {
                dispatch(clickCreateCampaign());
            }
        };

    }
)(CampaignCreateButton);



const MessengerEditorContainer = connect(
    (state, dispatch) => { //map state to props

        return {
            customAttributes: getCustomAttributes(state),
            template: state.createCampaign[Constants.TRANSPORT_MESSENGER.toLowerCase()].template,
            messengerIsNewlySelectedTemplate: state.createCampaign.messengerIsNewlySelectedTemplate
        };
    },
    (dispatch, ownProps) => {
        return {

            onChangeTemplate: (template) => {
                dispatch(onChangeMessengerTemplate(template));
            },

            saveTemplate: (template) => {
                dispatch(saveTemplate(template, Constants.TRANSPORT_MESSENGER))
            },
            selectTemplate: () => {
                dispatch(chooseTemplate(true, Constants.TRANSPORT_MESSENGER))
            },
            cancelSelectMessengerTemplate: () => {
                dispatch(onSelectMessengerTemplate(false))
            }
        };

    }
)(MessengerEditor);
export { CampaignSelectionContainer, CampaignCreateButtonContainer, MessengerEditorContainer };
