import React, { Component } from 'react';
import { Row, Col, Card, Button, CardDeck, InputSpinner, FaIcon } from 'shoutout_themes';
import { faCheck } from 'shoutout_themes/es/FaIconsSolid';
import { BillingInvoices } from './BillingComponents';
import { LoadingComponent } from './../utils/UtilComponents';
import { BillingService } from './../../services/Services';
import UserContext from './../../contexts/UserContext';
import './BillingPlans.css';
import './CountrySelector.css';


class BillingPlans extends Component {

    constructor() {
        super();
        this.state = { countryBillingMap: [], isLoadingPlans: true, value: 1, country: { name: "Sri Lanka", code: "lk" }, selector: false, litePlans: [], emailQuantity: 1, engagementPlans: [], engageAdditionalPlans: [], filterTriggered: "" };
        this.panelColorClasses = ["dark", "primary", "success", "warning"];
        this._onClickPurchaseLite = this._onClickPurchaseLite.bind(this);
        this._changeLiteQuantity = this._changeLiteQuantity.bind(this);
        this._changeEngageAdditionalQty = this._changeEngageAdditionalQty.bind(this);
        this._onClickPurchaseAdditionalEmail = this._onClickPurchaseAdditionalEmail.bind(this);
        this._onClickSubscriptionPackage = this._onClickSubscriptionPackage.bind(this);
    }


    _navigateToBilling() {
        this.props.history.push('/billing/purchase');
    }
    _onClickPurchaseLite(e) {
        let plan = this.state.litePlans[e.target.id];
        this.props.navigateToPurchase(plan);
        this._navigateToBilling();
    }

    _onDropDown = () => {
        this.setState({ selector: !this.state.selector });

    };


    _onDropToggle = (e) => {
        const countryName = e.target.id;
        let selectedCountry = this.state.countryBillingMap.find(country => (country.name === countryName));
        this.setState({ value: selectedCountry.credits, country: { name: countryName, code: selectedCountry.code.toLowerCase() }, selector: false });
        if (this.state.filterTriggered !== "") {
            this.setState({ filterTriggered: "" });
        }


    };

    _onClickPurchaseAdditionalEmail(e) {
        let plan = this.state.engageAdditionalPlans[e.target.id];
        this.props.navigateToPurchase(plan);
        this._navigateToBilling();
    }

    _onClickSubscriptionPackage(e) {
        let plan = this.state.engagementPlans[e.target.id];
        this.props.navigateToPurchase(plan);
        this._navigateToBilling();
    }

    _changeLiteQuantity(index, value) {
        let { litePlans } = this.state;
        litePlans[index].quantity = value;
        this.setState({ litePlans });
    }
    _changeEngageAdditionalQty(index, value) {
        let { engageAdditionalPlans } = this.state;
        engageAdditionalPlans[index].quantity = value;
        this.setState({ engageAdditionalPlans });
    }

    _onSMSCountryInput = (e) => {
        const searchCountry = e.target.value.toLowerCase();
        this.setState({ filterTriggered: searchCountry });
    };

    componentDidMount() {
        const fetchPricing = async () => {
            const countryBillingMap = await BillingService.getSMSPricing()
            const sortedCountryBillingMap = countryBillingMap.sort(function (a, b) {
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
                return 0;
            });
            this.setState({ countryBillingMap: sortedCountryBillingMap });
        }
        fetchPricing();
    }

    static getDerivedStateFromProps(props, state) {


        if (props.plans.length > 0 && state.engagementPlans.length === 0) {


            const plansFiltered = props.plans.reduce((result, { product, metaData, ...rest }) => {
                if (product === "lite") {
                    result.litePlans.push({ product, metaData, ...rest, quantity: 1 });
                } else if (product === "engage") {
                    if (metaData.type === "subscription") {
                        result.engagementPlans.push({ product, metaData, ...rest });
                    } else if (metaData.type === "ondemand") {
                        result.engageAdditionalPlans.push({ product, metaData, ...rest, quantity: 1 });
                    }
                }
                return result;
            }, { litePlans: [], engagementPlans: [], engageAdditionalPlans: [] });

            return { engagementPlans: plansFiltered.engagementPlans, litePlans: plansFiltered.litePlans, engageAdditionalPlans: plansFiltered.engageAdditionalPlans, isLoadingPlans: false };

        }
        return null;
    }

    render() {
        let sMSCreditRate = 1
        const filteredCountries = this.state.filterTriggered ? this.state.countryBillingMap.filter(country => country.name.toLowerCase().includes(this.state.filterTriggered)) : this.state.countryBillingMap;
        if (this.state.countryBillingMap.length !== 0 && this.state.litePlans.length !== 0) {
            sMSCreditRate = this.state.litePlans[0].amount / this.state.litePlans[0].blockSize
        }
        return (
            <UserContext.Consumer>
                {({ plan: currentPlan }) => {
                    return (
                        <Row noGutters={true} className="pt-3 billing-plans">


                            <Col>
                                {this.state.isLoadingPlans &&
                                    <LoadingComponent />}
                                <Card className="shadow rounded-0 border-0">
                                    <Card.Body>
                                        <h6 className="font-weight-bold">Subscription Plans</h6>
                                        <CardDeck>
                                            {this.state.engagementPlans.map((item, index) => (

                                                <Card key={item.id} variant={this.panelColorClasses[index]} className={`shadow-sm rounded-0 ${currentPlan === item.id ? 'selected-plan  border-left-0 border-right-0 border-bottom-0' : 'border-0'}`}>
                                                    <Card.Body>
                                                        <h6>{item.name}</h6>
                                                        <div className="d-flex justify-content-center align-items-center"><h6 className="mb-3">$</h6><h3 className="font-weight-bold">{item.amount}</h3><small>/{item.id === 'sub_starter' ? 'Forever' : 'Monthly'}</small></div>
                                                        {/* <h4>{item.blockSize} Emails</h4> */}
                                                        {item.id === 'sub_starter' ? (
                                                            <div>
                                                                {/* <small className="text-muted">No additional emails</small> */}
                                                                <small className="text-secondary">{item.description}</small>
                                                                <br />
                                                                <ul className="text-left">
                                                                    <li className="text-small">SMS</li>
                                                                    <li className="text-small">Email</li>
                                                                    <li className="text-small">Unlimited Contacts</li>
                                                                    <li className="text-small">Unlimited Customer Segments</li>
                                                                    <li className="text-small">Unlimited Personalized Content</li>
                                                                    <li className="text-small">Unlimited Short URLs</li>
                                                                    <li className="text-small">Unlimited SMS & Email Templates</li>
                                                                </ul>

                                                            </div>
                                                        ) : (
                                                                <div>
                                                                    {/* {this.state.engageAdditionalPlans.map(item => {
                                                                return (<small className="text-muted" key={`{lst-${item.id}}`}>Additional {item.blockSize} emails: $ {item.amount}</small>)
                                                            })} */}
                                                                    <small className="text-secondary">{item.description}</small>
                                                                    <br />

                                                                    <ul className="text-left">
                                                                        <li className="text-small">All Starter features</li>
                                                                        <li className="text-small">API Integrations</li>
                                                                        <li className="text-small">Automated/ Triggered SMS & Emails</li>
                                                                        <li className="text-small">Multiple Sender IDs / Mobile Numbers</li>
                                                                        <li className="text-small">360° Customer Profile View</li>
                                                                        <li className="text-small">ShoutOUT Forms</li>
                                                                    </ul>

                                                                </div>
                                                            )}
                                                        <div className="text-center">
                                                            <hr />
                                                            {currentPlan === item.id ? <span className="text-success text-small">Current Plan <FaIcon className="ml-2" icon={faCheck} size="1x" /></span> : <div> {item.id !== "sub_starter" && <Button variant={this.panelColorClasses[index]} id={index} onClick={this._onClickSubscriptionPackage}>Select</Button>}<br /></div>}
                                                        </div>
                                                    </Card.Body>

                                                </Card>

                                            ))}
                                        </CardDeck>
                                    </Card.Body>
                                </Card>

                                <Card className="mt-3 shadow rounded-0 border-0">
                                    <BillingInvoices invoiceList={[]} />
                                </Card>

                            </Col>

                            <Col lg={7} className="ml-lg-3">

                                <Card className="shadow rounded-0 border-0">
                                    <Card.Body>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h6 className="font-weight-bold mb-0">Buy SMS Packages</h6>

                                            <div className="dropdown d-inline mx-auto">
                                                <button className="text-left btn btn-transparent sms-country-selector border-0 rounded-0 dropdown-toggle p-0" type="button" onClick={this._onDropDown}>
                                                    <div className="d-flex align-items-center text-truncate text-dark px-3 my-auto">
                                                        <div className={`d-inline-block mr-2 sms-country-selector-flag ${this.state.country.code}`}></div>
                                                        <div className="text-truncate sms-country-selector-text">{this.state.country.name}</div>
                                                    </div>
                                                </button>
                                                <ul className={`dropdown-menu scrollable-menu ${this.state.selector ? "show" : "hide"}`}>
                                                    <li className="text-dark px-3 my-auto">
                                                        <input className="border-0 w-100 sms-country-selector-input" type="text" autoComplete="off" placeholder="Search.." id="sms-country-search" onBlur={(e) => e.target.value = ""} autoFocus onChange={this._onSMSCountryInput} />
                                                    </li>
                                                    {filteredCountries.map(({ name, code }, index) => {
                                                        return (
                                                            <li className="sms-country-selector-item text-dark px-3 my-auto" onClick={this._onDropToggle} key={index} id={name} value={name}><div className={`d-inline-block mr-2 sms-country-selector-flag ${code.toLowerCase()}`}></div>{name}</li>
                                                        );
                                                    }
                                                    )}
                                                </ul>
                                            </div>
                                            <small className="sms-country-selector-text ml-auto"><strong>{this.state.value * sMSCreditRate}</strong> Credits</small>
                                        </div>


                                        {this.state.litePlans.map((item, index) => {
                                            return (

                                                <Card key={item.id} className="shadow-sm border-0 rounded-0 mb-2" variant="primary">

                                                    <Card.Body className="d-flex align-items-center justify-content-between">
                                                        <div className="text-left">
                                                            <h6 className="mb-0">{item.name}</h6>
                                                            <hr className="my-0 dashed" />
                                                            <small><strong>{item.quantity * item.blockSize}</strong> SMS Credits</small>

                                                        </div>

                                                        <InputSpinner className="input-spinner-width" min="1" max="5" value={item.quantity} onChange={(value) => this._changeLiteQuantity(index, value)} />
                                                        <div>
                                                            <small><strong>Total</strong></small>
                                                            <h4 className="mb-0 font-weight-bold">$ {item.amount * item.quantity}</h4>
                                                        </div>
                                                        <Button variant="primary" size="sm" id={index} onClick={this._onClickPurchaseLite}>Select</Button>
                                                    </Card.Body>

                                                </Card>

                                            )
                                        })}

                                        <small className="mt-3 text-muted">SMS credits will expire after a year</small>
                                    </Card.Body>
                                </Card>

                                <Card className="mt-3 shadow rounded-0 border-0">
                                    <Card.Body>



                                        <h6 className="font-weight-bold">Buy Email Packages</h6>

                                        {this.state.engageAdditionalPlans.map((item, index) => (
                                            <Card className="shadow-sm rounded-0 border-0 mb-2" key={item.id}>
                                                <Card.Body>
                                                    <div className="d-flex align-items-center justify-content-between" key={item.id}>
                                                        <div>
                                                            <h6 className="mb-0">{item.blockSize} Emails</h6>
                                                            <small className="text-muted">Valid for {item.validity} months</small>
                                                        </div>
                                                        <h4 className="font-weight-bold mb-0">$ {item.amount}</h4>
                                                        <InputSpinner className="input-spinner-width" min="1" max="5" value={item.quantity} onChange={(value) => this._changeEngageAdditionalQty(index, value)} />
                                                        <div>
                                                            <small><strong>Total</strong></small>
                                                            <h4 className="mb-0 font-weight-bold">$ {item.amount * item.quantity}</h4>
                                                        </div>
                                                        <Button variant="primary" size="sm" id={index} onClick={this._onClickPurchaseAdditionalEmail}>Select</Button>
                                                    </div>
                                                </Card.Body>
                                            </Card>

                                        ))}

                                    </Card.Body>
                                </Card>
                            </Col>

                        </Row>

                    )
                }
                }
            </UserContext.Consumer>
        );
    }
}

export default BillingPlans;