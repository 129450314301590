/**
 * Created by madura on 1/19/17.
 */
import React, { Component } from 'react';
import { ShoutOUTService, AnalyticsService } from './../../services/Services';
//import Shoutout from './../../services/Shoutout';
import Avatar from 'react-user-avatar';
import {
    Nav,
    Image,
    Popover,
    Button,
    Overlay,
    FaIcon
} from 'shoutout_themes';
import { faTachometerAlt, faUsers, faBullhorn, faFileAlt, faSignInAlt, faInbox, faHeart, faHandshake, faChartLine, faPlug, faCogs, faBriefcase, faCreditCard, faUser, faSignOutAlt, faInfo, faComment } from 'shoutout_themes/es/FaIconsSolid';
import Constants from './../../Constants';
import UserContext from './../../contexts/UserContext';

import { withRouter, NavLink } from 'react-router-dom';
import './SideNavigationBar.css';


class SideNavigationBar extends Component {

    constructor(props) {
        super(props);
        this.shoutout = new ShoutOUTService();
        this._gotoBilling = this._gotoBilling.bind(this);
        this.state = {
            profileMenuShow: false,
            creditsMenuShow: false,
            switchProductMenuShow: false
        };
        this.changePopoverVisibility = this.changePopoverVisibility.bind(this);


        this.creditsRef = React.createRef();
        this.profileRef = React.createRef();
        this.productMenu = React.createRef();
    }


    changePopoverVisibility(updateObj) {
        this.setState(updateObj);
    }

    _gotoBilling() {
        this.props.history.push('/billing');
    }

    _goToMyProfile = () => {
        this.props.history.push('/my-profile');
        this.setState({ profileMenuShow: false });
    }
    gotoLite = () => {

    }
    triggerSupportChat = () => {
        AnalyticsService.trackActivity({
            "name": "trigger-chatBox"
        });
    }

    render() {

        return (
            <UserContext.Consumer>
                {
                    ({ userRole, name, smsCreditsOnDemand, smsCreditsPlan, emailCreditsOnDemand, emailCreditsPlan, logout, token, scopes }) => (
                        <div className="sidebar-wrapper bg-white shadow">
                            <div className="product-logo">
                                <Image display="" src='/assets/images/logo_dark.png' alt="ShoutOUT" width={40} />
                            </div>
                            <br />
                            <div className="push-top">
                                <NavLink to="/dashboard" className="link text-center text-dark" activeClassName="active-link" id="side-nav-link-dashboard">
                                    <FaIcon icon={faTachometerAlt} className="side-nav-icon" />
                                    <span className="link-text">Dashboard</span>
                                </NavLink>
                                {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.PEOPLE.moduleId].read &&
                                    <NavLink to="/people" className="link text-center text-dark" activeClassName="active-link" id="side-nav-link-people">
                                        <FaIcon icon={faUsers} className="side-nav-icon" />
                                        <span className="link-text">People</span>
                                    </NavLink>
                                }
                                {Constants.BUILD_APP === Constants.APP_ENGAGEMENT ?
                                    <React.Fragment>
                                        {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.CAMPAIGNS.moduleId].read &&
                                            <>
                                                <NavLink to="/engagement/campaigns" className="link text-center text-dark" activeClassName="active-link" id="side-nav-link-campaigns">
                                                    <FaIcon icon={faBullhorn} className="side-nav-icon" />
                                                    <span className="link-text">Campaigns</span>
                                                </NavLink>

                                                <NavLink to="/engagement/templates" className="link text-center text-dark" activeClassName="active-link" id="side-nav-link-templates">
                                                    <FaIcon icon={faFileAlt} className="side-nav-icon" />
                                                    <span className="link-text">Templates</span>
                                                </NavLink>
                                            </>
                                        }
                                        {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.RESPONSE.moduleId].read &&
                                            <NavLink to="/engagement/responses" className="link text-center text-dark" activeClassName="active-link" id="side-nav-link-responses">
                                                <FaIcon icon={faSignInAlt} className="side-nav-icon" />
                                                <span className="link-text"  >Responses</span>
                                            </NavLink>
                                        }
                                        <NavLink to="/engagement/inbound" className="link text-center text-dark" activeClassName="active-link" id="side-nav-link-inbound">
                                            <FaIcon icon={faInbox} className="side-nav-icon" />
                                            <span className="link-text"  >Inbound</span>
                                        </NavLink>
                                    </React.Fragment>
                                    : <React.Fragment>
                                        <NavLink to="/loyalty/overview" className="link text-center text-dark" activeClassName="active-link" id="side-nav-link-loyalty-loyalty">
                                            <FaIcon icon={faHeart} className="side-nav-icon" />
                                            <span className="link-text">Loyalty</span>
                                        </NavLink>

                                        <NavLink to="/engagement/campaigns" className="link text-center text-dark" activeClassName="active-link" id="side-nav-link-loyalty-engage">
                                            <FaIcon icon={faHandshake} className="side-nav-icon" />
                                            <span className="link-text">Engage</span>
                                        </NavLink>


                                        {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.LOYALTY_ANALYTICS.moduleId].read &&
                                            <NavLink to="/analytics" className="link text-center text-dark" activeClassName="active-link" id="side-nav-link-loyalty-analytics">
                                                <FaIcon icon={faChartLine} className="side-nav-icon" />
                                                <span className="link-text">Analytics</span>
                                            </NavLink>
                                        }
                                    </React.Fragment>
                                }

                                {
                                    scopes[Constants.USER_ACCESS_CONTROLS.MODULES.APPS.moduleId].read &&

                                    <NavLink to="/integrations" className="link text-center text-dark" activeClassName="active-link" id="side-nav-link-apps">
                                        <FaIcon icon={faPlug} className="side-nav-icon" />
                                        <span className="link-text">Apps</span>
                                    </NavLink>
                                }
                                <Nav.Item ref={this.productMenu} className="cursor-pointer link text-center no-focus-color p-2"

                                    onClick={() => this.changePopoverVisibility({ switchProductMenuShow: !this.state.switchProductMenuShow })}>
                                    <FaIcon icon={faCogs} className="side-nav-icon mx-auto d-block" />
                                    <span className="link-text text-dark" id="nav-item-link-products">Products</span>
                                    <Overlay
                                        show={this.state.switchProductMenuShow}
                                        target={this.productMenu.current}
                                        placement="right"
                                        // container={this}
                                        // containerPadding={10}
                                        onHide={() => this.changePopoverVisibility({ switchProductMenuShow: false })}
                                        rootClose={true}
                                    >
                                        <Popover id="product-menu" className="sidebar-popover border shadow">
                                            <div className="text-center py-3">
                                                <a href={"https://app.lite.getshoutout.com?token=" + token} rel="noopener noreferrer" target="_blank" className="text-dark">  <img className="mr-2 pr-2 border-right" src='/assets/images/lite.png' width='48' alt="Lite API" />Go to Lite</a>
                                                {/* <Button name="liteButton" size="sm" variant="dark"
                                                onClick={this.gotoLite}>Goto Lite</Button> */}
                                            </div>
                                        </Popover>
                                    </Overlay>
                                </Nav.Item>

                                {/* trigger chat box */}

                                <Nav.Item
                                    onClick={this.triggerSupportChat}
                                    className="cursor-pointer link text-center no-focus-color p-2" activeClassName="active-link">
                                    <FaIcon icon={faComment} className="side-nav-icon" />
                                    <span className="link-text text-dark">Support</span>
                                </Nav.Item>

                                {/*
                            <Link to="/events" className="link text-center" activeClassName="active-link">
                                <FaIcon icon="flag" />
                                <span className="link-text">Events</span>
                            </Link>
                            <Link to="/templates" className="link text-center" activeClassName="active-link">
                                <FaIcon icon="description" />
                                <span className="link-text">Templates</span>
                            </Link>
                            <Link to="/integrations" className="link text-center" activeClassName="active-link">
                                <FaIcon icon="power" />
                                <span className="link-text">Integrations</span>
                            </Link>
                       
                    */}
                                {
                                    (userRole === 'admin' || userRole === "finance") ?
                                        <NavLink to="/administrator" className="link text-center text-dark" activeClassName="active-link">
                                            <FaIcon icon={faBriefcase} className="side-nav-icon" />
                                            <span className="link-text">Admin</span>
                                        </NavLink> : null
                                }
                            </div>
                            <Nav className="flex-column push-bottom">
                                <Nav.Item ref={this.creditsRef} className="cursor-pointer text-center no-focus-color"
                                    onClick={() => this.changePopoverVisibility({ creditsMenuShow: !this.state.creditsMenuShow })}>
                                    <div className="link">
                                        <FaIcon icon={faCreditCard} className={`${(smsCreditsPlan < 20 || emailCreditsPlan < 20) ? 'text-danger' : (smsCreditsPlan < 100 || emailCreditsPlan < 100) ? 'text-warning' : 'text-info'} side-nav-icon`} />
                                        <span className="link-text text-dark">Plan</span>
                                    </div>
                                </Nav.Item>
                                <Overlay
                                    show={this.state.creditsMenuShow}
                                    target={this.creditsRef.current}
                                    placement="right"
                                    // container={this}
                                    // containerPadding={20}
                                    containerClassName="bg-dark"
                                    onHide={() => this.changePopoverVisibility({ creditsMenuShow: false })}
                                    rootClose={true}
                                >
                                    <Popover id="credit-menu" className="sidebar-popover rounded-0 border-0 shadow">
                                        <div className="text-center py-3">
                                            <span className="credits-text"><strong>{emailCreditsPlan}</strong></span>
                                            <span className="credits-sub-text">&nbsp;Emails</span>
                                            <br />
                                            <span className="credits-sub-text">Remaining from Plan</span>
                                            <br />
                                            <span className="credits-text"><strong>{emailCreditsOnDemand}</strong></span>
                                            <span className="credits-sub-text">&nbsp;Emails</span>
                                            <br />
                                            <span className="credits-sub-text">Remaining Additionally</span>
                                            <hr />
                                            <span className="credits-text"><strong>{smsCreditsPlan}</strong></span>
                                            <span className="credits-sub-text">&nbsp;SMS Credits</span>
                                            <br />
                                            <span className="credits-sub-text">Remaining from SMS Plan</span>
                                            <br />
                                            <span className="credits-text"><strong>{smsCreditsOnDemand}</strong></span>
                                            <span className="credits-sub-text">&nbsp;SMS Credits</span>
                                            <br />
                                            <span className="credits-sub-text">Remaining Additionally</span>
                                            <br />
                                            <br />

                                            <Button name="creditsButton" size="sm" variant="dark"
                                                onClick={this._gotoBilling}>Buy More</Button>
                                        </div>
                                    </Popover>
                                </Overlay>


                                <Nav.Item ref={this.profileRef} className="cursor-pointer text-center no-focus-color mt-2 link" onClick={() => this.changePopoverVisibility({ profileMenuShow: !this.state.profileMenuShow })}>
                                    <Avatar name={(name || 'unknown').charAt(0)} size={20} className="sidebar-user-avatar" />
                                    <span className="link-text text-dark">Profile</span>
                                </Nav.Item>
                                <Overlay
                                    show={this.state.profileMenuShow}
                                    target={this.profileRef.current}
                                    placement="right"
                                    // container={this}
                                    // containerPadding={20}
                                    onHide={() => this.changePopoverVisibility({ profileMenuShow: false })}
                                    rootClose={true}
                                >
                                    <Popover id="credit-menu" className="sidebar-popover py-3 pl-3 shadow border-0">
                                        <a onClick={this._goToMyProfile} className="link-sub text-primary">
                                            <div><FaIcon icon={faUser} /></div>
                                            <div><span className="link-text text-dark">My Profile</span></div>
                                        </a>
                                        {/* <a href="https://getshoutout.com/launchpad" className="link-sub text-warning" name="switch">
                                            <div><FaIcon icon="random" /></div>
                                            <div><span className="link-text text-dark">Switch App</span></div>
                                        </a> */}

                                        <a onClick={logout} className="link-sub text-dark" name="logout-button">
                                            <div><FaIcon icon={faSignOutAlt} /></div>
                                            <div><span className="link-text text-dark">Logout</span></div>
                                        </a>
                                    </Popover>
                                </Overlay>
                            </Nav>

                        </div>
                    )
                }
            </UserContext.Consumer>
        );
    }
}

export default withRouter(SideNavigationBar);