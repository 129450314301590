import React from "react";
import {
    Button,
    Modal
} from 'shoutout_themes';

class ConfirmSegmentDeleteDialog extends React.PureComponent {
    render() {
        const { show, onHide, okCallback, segmentName } = this.props;
        return (
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>You are about to delete the segment "{segmentName}". Are you sure?</div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="dark" onClick={onHide}>No, Cancel</Button>
                    <Button variant="danger" onClick={okCallback}>Yes, Delete</Button>
                </Modal.Footer>

            </Modal>
        );
    }
};

export default ConfirmSegmentDeleteDialog;