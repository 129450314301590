import React from 'react';
import ReactDOM from 'react-dom';
import { reducers } from './redux/reducers/RootReducer';
import * as firebase from 'firebase/app';
import register from './registerServiceWorker';

import { UserContextContainer } from './contexts/UserContext';
import { BrowserRouter } from 'react-router-dom';
import { ContextProvider } from './contexts/ContextUtils';
import App from './App'

const FIREBASE_CONFIG = {
    apiKey: "AIzaSyA0u47etXNbMye-ba8fT6fwjvNSnFiBJFM",
    authDomain: "shoutout-82d85.firebaseapp.com",
    databaseURL: "https://shoutout-82d85.firebaseio.com",
    storageBucket: "shoutout-82d85.appspot.com",
    messagingSenderId: "406431960011"
};




firebase.initializeApp(FIREBASE_CONFIG);


ReactDOM.render(

    <ContextProvider reducer={reducers}>
        <BrowserRouter>
            <UserContextContainer>

                <App />

            </UserContextContainer>
        </BrowserRouter>
    </ContextProvider>
    ,
    document.getElementById('root')
);
register();

