import React, { PureComponent } from 'react';
import { LineChart, ResponsiveContainer, YAxis, XAxis, Tooltip, Legend, Line, CartesianGrid } from 'recharts';
import {LoadingRocket} from './../utils/UtilComponents';
class LineChartWidget extends PureComponent {

    render() {
        const { dataset, title, isLoading = false ,lineOptions=[]} = this.props;

        return (
            <div className="line_chart_box">
                {title && <h6 className="chart_title text-center font-weight-bold">{title}</h6>}
                {isLoading ?<div className="my-5 py-5"> <LoadingRocket/></div> : <div className="line_char_inner">
                    <ResponsiveContainer width="100%" height={250} className="line-chart-widget">

                        <LineChart data={dataset} margin={{ top: 5, right: 20, left: 10, bottom: 5 }}>
                        <CartesianGrid fill="none" horizontal horizontalFill={[]} horizontalPoints={[]} stroke="#f5f5f5" vertical={false} verticalFill={[]} verticalPoints={[]}/>
                            <XAxis dataKey="key" tick={{fontSize: 10}} />
                            <YAxis tick={{fontSize: 10}}/>
                            <Tooltip />
                            <Legend />
                            {lineOptions.map((item)=>{
                                return (<Line key={item.key} type={item.type || "monotone"} dataKey={item.key||'value'} stroke={item.color} name={item.label}/>)
                            })}
                            


                        </LineChart>



                    </ResponsiveContainer>
                </div>
                }
            </div>
        );
    }
};

export default LineChartWidget;