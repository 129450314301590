import UTIL_ACTIONS from './../actions/UtilActions';
import {
    RECEIVED_SENDER_IDS
} from './../actions/CommonActions';
import {
    ON_SELECT_SENDER_ID
} from './../actions/CampaignCreateActions';
import update from 'immutability-helper';
import {
    filter,
    isEmpty
} from 'lodash';
import Constants from './../../Constants';
const initialState = {
    sendMessage: {
        show: false,
        actionFrom: '',
        sms: {
            to: '',
            senderId: '',
            message: ''
        },
        email: {
            senderId: '',
            to: '',
            message: {
                body: '',
                subject: ''
            }
        },
        facebook_messenger:{
            senderId:''
        },
        isSending: false,
        transport: Constants.TRANSPORT_SMS
    },
    alertBox: {
        show: false,
        message: '',
        title: '',
        type: 'success'
    },
    email: {
        preview: { show: false }
    }
};

const utilReducer = (state = initialState, action) => {
    switch (action.type) {

        case UTIL_ACTIONS.SHOW_SEND_MESSAGE_DIALOG:
            {

                return update(state, {
                    sendMessage: {

                        show: {
                            $set: action.isShow
                        },
                        actionFrom: {
                            $set: action.from
                        },
                        [action.transport.toLowerCase()]: {
                            to: {
                                $set: action.contact.mobile_number
                            },
                            message: {
                                $set: action.message
                            }
                        },
                        transport: { $set: action.transport }
                    }
                });
            }

        case RECEIVED_SENDER_IDS:
            {
                let senderIds = action.senderIds;
                let updateRequest = {
                    sendMessage: {}
                };
                Object.keys(senderIds).forEach(transport => {
                    let filteredSenderId = filter(senderIds[transport], item => { return item.status === 1 });
                    if (filteredSenderId.length) {
                        updateRequest.sendMessage[transport.toLowerCase()] = {
                            senderId: {
                                $set: filteredSenderId[0].senderId
                            }
                        }
                    }
                })

                if (!isEmpty(updateRequest.sendMessage)) {
                    return update(state, updateRequest);
                } else {
                    return state;
                }

            }

        case UTIL_ACTIONS.CHANGE_SEND_MESSAGE_SENDER_ID:
            {
                return update(state, {
                    sendMessage: {
                        [action.transport.toLowerCase()]: {
                            senderId: {
                                $set: action.senderId
                            }
                        }
                    }
                });
            }
        case ON_SELECT_SENDER_ID: {
            return update(state, {
                sendMessage: {
                    [action.transport.toLowerCase()]: {
                        senderId: {
                            $set: action.senderId
                        }
                    }
                }
            });
        }
        case UTIL_ACTIONS.SENDING_MSG_SEND_MESSAGE:
            {
                return update(state, {
                    sendMessage: {
                        isSending: {
                            $set: true
                        }
                    }
                });
            }

        case UTIL_ACTIONS.SENT_MSG_SEND_MESSAGE:
            {
                let newSendMessageState = initialState.sendMessage;
                newSendMessageState.email.senderId = state.sendMessage.email.senderId;
                newSendMessageState.sms.senderId = state.sendMessage.sms.senderId;
                return update(state, {
                    sendMessage: {
                        $set: newSendMessageState
                    }
                });
            }
        case UTIL_ACTIONS.SENT_MSG_ERROR_SEND_MESSAGE:
            {
                return update(state, {
                    sendMessage: {
                        isSending: { $set: false }
                    }
                });
            }
        case UTIL_ACTIONS.CHANGE_SEND_MESSAGE_MESSAGE: {
            return update(state, {
                sendMessage: {
                    [action.transport]: {
                        message: {
                            $set: action.message
                        }
                    }
                }
            });
        }
        case UTIL_ACTIONS.CHANGE_SEND_MESSAGE_TO: {
            return update(state, {
                sendMessage: {
                    [action.transport]: {
                        to: {
                            $set: action.to
                        }
                    }
                }
            });
        }
        case UTIL_ACTIONS.SHOW_ALERT_BOX: {
            return update(state, {
                alertBox: {
                    show: { $set: action.isShow },
                    message: { $set: action.message },
                    title: { $set: action.title },
                    type: { $set: action.alertType }
                }
            });
        }

        case UTIL_ACTIONS.SHOW_EMAIL_PREVIEW: {
            return update(state, {
                email: {
                    preview: {
                        show: { $set: action.isShow },
                        body: { $set: action.body }
                    }
                }
            });
        }
        default:
            {
                return state;
            }
    }
};


export default utilReducer;
