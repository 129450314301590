import React, { useContext } from 'react';
import UserContext from './contexts/UserContext';
import { Route, Redirect } from 'react-router-dom';

// const AuthRedirectRoute = (WrappedComponent) => {
//     return (props) => (
//         <UserContext.Consumer>
//             {({ isLoggedIn }) => {
//                 if (isLoggedIn) {
//                     return <WrappedComponent {...props} />
//                 }
//                 else {

//                     if (props.location.pathname !== '/logout') {
//                         props.router.push('/login')
//                     }
//                 }
//             }}
//         </UserContext.Consumer>)


// }
//TODO: test roles
const AuthRedirectRoute = ({ component: Component, allowedRoles, ...rest }) => {
    const { initComplete,userRole,isLoggedIn} = useContext(UserContext);
    return (

        <Route {...rest} render={(props) => (
            initComplete ?
                isLoggedIn
                    ? (allowedRoles ? (allowedRoles.includes(userRole) ? <Component {...props} userRole={userRole} /> : <div className="d-flex flex-column h-100"><h1 className="text-center my-auto">Not allowed!</h1></div>) : <Component {...props} />)
                    : <Redirect to='/login' />
                :
                <div className="d-flex align-content-center justify-content-center flex-wrap h-100">
                    
                 
                     <h2 className="mb-0 font-weight-bold">Please wait...</h2>
                   
                        
                </div>
        )} />
    )
}



export default AuthRedirectRoute;