/**
 * Created by madura on 1/19/17.
 */
import React, { Component } from 'react';
import { Navbar, Nav } from 'shoutout_themes';
import './TopNavigationBar.css';

class TopNavigationBar extends Component {


    render() {

        return (
            <div className="pb-4">
                <Navbar className="top-nav-bar shadow-sm" bg="white" variant="primary">
                    {/* <Navbar.Brand>
                        <Navbar.Toggle />
                    </Navbar.Brand> */}
                    <Navbar.Collapse>
                        <Nav variant="primary" className="mx-auto">
                            {this.props.children}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>

            </div>
        );
    }
}

export default TopNavigationBar;