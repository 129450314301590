/**
 * Created by asankanissanka on 12/20/16.
 */
import React, { Component } from 'react';
import { ShoutOUTService } from './../../services/Services';
import {
    Form,
    Button,
    Modal,
    FaIcon,
    Typeahead,
    InputGroup,
    Row,
    Col,
    Container
} from 'shoutout_themes';
import { faCheck, faTimes } from 'shoutout_themes/es/FaIconsSolid';
import { notify } from 'react-notify-toast';
import Constants from './../../Constants';
import { countries } from 'country-data';
import { sortBy } from 'lodash';
import FlagIconFactory from 'react-flag-icon-css';
import './SenderRequest.css'

const FlagIcon = FlagIconFactory(React, { useCssModules: false });
const SmsSenderIdPattern = /^[a-zA-Z\d ]{3,11}$/;
const EmailSenderIdPattern = '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';

const allCountries={ name: "All", id: 0 };
const countryList = [{...allCountries}, ...sortBy(countries.all, ['name'])];
const initialState={
    senderId: '',
    company: '',
    isValid: false,
    isSending: false,
    requestSent: false,
    emailName: '',
    businessNature: '',
    designation: '',
    userName: '',
    countries: [{...allCountries}],
    usage: 'transactional'
};
class SenderRequest extends Component {
    constructor() {
        super();
        this.close = this.close.bind(this);
        this.state = {
           ...initialState
        };
        this._handleChange = this._handleChange.bind(this);
        this._setSmsSenderID = this._setSmsSenderID.bind(this);
        this._submitSenderID = this._submitSenderID.bind(this);
        this._setEmailSenderID = this._setEmailSenderID.bind(this);
        this._onChangeInput = this._onChangeInput.bind(this);
        this._checkProps = this._checkProps.bind(this);
    }

    close(result) {
        this.setState({
          ...initialState
        });
        this.props.onCloseCallback(result);
    }

    _setSmsSenderID(e) {
        let senderId = e.target.value;
        let isValid = senderId.match(SmsSenderIdPattern);

        this.setState({ senderId, isValid });
    }

    _onChangeInput(e) {
        e.stopPropagation();
        this.setState({ [e.target.name]: e.target.value });
    }

    _buildEmailSenderId() {
        return this.state.emailName ? this.state.emailName + "<" + this.state.senderId + ">" : this.state.senderId;
    }
    _submitSenderID(e) {
        e.preventDefault();
        const { senderIdType } = this.props;
        let { senderId, company, businessNature,
            designation,
            userName, countries, usage } = this.state;
        senderId = senderId.trim();
        if (senderIdType === Constants.TRANSPORT_EMAIL) {
            senderId = this._buildEmailSenderId();
        }

        this.setState({ isSending: true });
        let ctx = this;
        const transformedCountries = countries.map(({ name }) => {
            return name.toLowerCase();
        })

        ShoutOUTService.requestSenderId(senderId, senderIdType, company, businessNature, designation, userName, transformedCountries, usage).then(res => {
            notify.show("Sender ID request sent", "success");
            //ctx.close(res);
            ctx.setState({ isSending: false, requestSent: true });
        }, err => {
            notify.show("Sender ID request couldn't send ", "error");
            ctx.setState({ isSending: false });
        })
    }

    _setEmailSenderID(e) {
        let name = e.target.name;
        let value = e.target.value;
        let senderId, isValid = false;
        if (name === "emailName") {
            this.setState({ emailName: value });
        }
        else {
            senderId = value;
            isValid = senderId.match(EmailSenderIdPattern);
            this.setState({ senderId, isValid });
        }


    }

    _checkProps() {
        const { userName, company } = this.props;

        this.setState({ company, userName });

    }
    //handle checkbox
    _handleChange(e) {
        const item = e.currentTarget.dataset.name;

        this.setState({ usage: item });
    }

    render() {
        const { senderIdType } = this.props;

        
        return (
            <Modal bsPrefix="modal" show={this.props.showModal} onHide={this.close} onEntered={this._checkProps}>
                <Modal.Header bsPrefix="modal-header" closeButton>
                    <Modal.Title bsPrefix="modal-title">Request {senderIdType} Sender ID</Modal.Title>
                </Modal.Header>
                <>

                    {this.state.requestSent ? <Modal.Body><div>
                        {senderIdType === Constants.TRANSPORT_SMS ?
                            <p className="text-primary">Your request has been sent. Please download the agreement and upload back the signed agreement to proceed.</p> :
                            <p className="text-dark">Verification Email has been sent to requested Email address. Please check your inbox.</p>
                        } <hr /><button type="button" onClick={this.close} className="btn btn-default">Close</button>
                    </div> </Modal.Body>
                        :
                        senderIdType === Constants.TRANSPORT_SMS
                            ?


                            <Form onSubmit={this._submitSenderID} id="sender-request-form">
                                <Modal.Body className="shadow bg-white">
                                    <Form.Group controlId="sms-id">
                                        <Form.Label>
                                            ID *
                                    </Form.Label>
                                        <div className="d-flex align-items-center">
                                            <Form.Control type="input" placeholder="Your Sender ID" ref="senderID"
                                                required onChange={this._setSmsSenderID} maxLength={11} minLength={3} />
                                            <div className="mr-2">{this.state.isValid ? <span className="text-success"><FaIcon icon={faCheck} /></span> : <span className="text-danger"><FaIcon icon={faTimes} /></span>}</div>
                                        </div>

                                    </Form.Group>
                                    <Form.Group controlId="sms-id-description">
                                        <ul>
                                            <li className="text-left">
                                                <small>Minimum 3 characters</small>
                                            </li>
                                            <li className="text-left">
                                                <small>Maximum 11 characters</small>
                                            </li>

                                            {/* <li className="text-left">
                                                <small>No whitespaces</small>
                                            </li> */}
                                            <li className="text-left">
                                                <small>Only alphanumeric characters</small>
                                            </li>
                                        </ul>
                                    </Form.Group>
                                    <Form.Group controlId="company">
                                        <Form.Label>
                                            Company *
                                    </Form.Label>

                                        <Form.Control type="input" placeholder="My Company" name="company"
                                            required onChange={this._onChangeInput} value={this.state.company} />
                                    </Form.Group>

                                    <Form.Group controlId="businessNature">
                                        <Form.Label>
                                            Nature of Business *
                                    </Form.Label>

                                        <Form.Control type="input" placeholder="Restaurant" name="businessNature"
                                            required onChange={this._onChangeInput} value={this.state.businessNature} />
                                    </Form.Group>

                                    <Form.Group controlId="userName">
                                        <Form.Label>
                                            Your Name *
                                    </Form.Label>

                                        <Form.Control type="input" placeholder="Sam De Silva" name="userName"
                                            required onChange={this._onChangeInput} value={this.state.userName} />
                                    </Form.Group>

                                    <Form.Group controlId="designation">
                                        <Form.Label>
                                            Your Designation *
                                    </Form.Label>

                                        <Form.Control type="input" placeholder="Marketing Manager" name="designation"
                                            required onChange={this._onChangeInput} value={this.state.designation} />
                                    </Form.Group>

                                    <Form.Group controlId="countries">
                                        <Form.Label>
                                            Countries *
                                    </Form.Label>
                                        <Typeahead
                                        id="select-country"
                                        defaultSelected={[{...allCountries}]}
                                            onChange={(selections) => {
                                                if (selections && selections.length > 0) {
                                                    this.setState({ countries: selections });
                                                    console.log("selection: ", selections)
                                                } else {
                                                    this.setState({ countries: null });
                                                }
                                            }}
                                            selected={this.state.countries}
                                            placeholder={'Select Country'}
                                            options={countryList}
                                            labelKey={option => `${option.name}`}
                                            renderMenuItemChildren={(result, props) => {
                                                return <span><span className="default-flag">{result.alpha2 && <FlagIcon code={result.alpha2.toLowerCase()} />}</span>&nbsp;&nbsp;{result.name}</span>
                                            }}
                                            multiple={true}
                                            filterBy={['name']}
                                            size="sm"
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="usage">
                                        <Form.Label>
                                            Usage *
                                    </Form.Label>
                                        <Container>
                                            <Row>
                                                <Col>
                                                    <Form.Check
                                                        type="radio"
                                                        label="Transactional"
                                                        name="usage"
                                                        id="radio1"
                                                        data-name="transactional"
                                                        checked={this.state.usage==='transactional'}
                                                        onChange={this._handleChange}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Check
                                                        type="radio"
                                                        label="Promotional"
                                                        name="usage"
                                                        id="radio2"
                                                        data-name="promotional"
                                                        checked={this.state.usage==='promotional'}
                                                        onChange={this._handleChange}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Check
                                                        type="radio"
                                                        label="Both"
                                                        name="usage"
                                                        id="radio3"
                                                        data-name="both"
                                                        checked={this.state.usage==='both'}
                                                        onChange={this._handleChange}
                                                    />
                                                </Col>

                                            </Row>
                                        </Container>
                                    </Form.Group>

                                </Modal.Body>
                                <Modal.Footer className="rounded-0 border-0">
                                    <Form.Group className="text-center">
                                        <Button className="btn btn-primary btn-sm" disabled={!this.state.isValid || this.state.isSending} type="submit">{this.state.isSending ? 'Sending Request...' : 'Send Request'}</Button>
                                    </Form.Group>
                                </Modal.Footer>
                            </Form>
                            :
                            <Form className="shadow bg-white" onSubmit={this._submitSenderID}>
                                <Modal.Body>
                                    <Form.Group controlId="email-id">
                                        <Form.Label>Email Address *</Form.Label>
                                        <div className="d-flex align-items-center">
                                            <Form.Control type="email" placeholder="Your sender ID" name="emailSenderID"
                                                onChange={this._setEmailSenderID} required />
                                            <div className="mr-2">{this.state.isValid ? <span className="text-success"><FaIcon icon={faCheck} /></span> : <span className="text-danger"><FaIcon icon={faTimes} /></span>}</div>
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="email-name">

                                        <Form.Label>Name *</Form.Label>

                                        <Form.Control type="text" placeholder="Your name which should appear in the email" name="emailName"
                                            onChange={this._setEmailSenderID} required />

                                    </Form.Group><br />
                                    <Form.Group controlId="email-senderid-preview">
                                        <small>Your Sender Id will appear as below:<br />{this._buildEmailSenderId()}</small>
                                    </Form.Group>
                                </Modal.Body>
                                <Modal.Footer className="rounded-0 border-0">
                                    <Form.Group className="text-center">
                                        <Button className="btn btn-primary" disabled={!this.state.isValid || this.state.isSending} type="submit">{this.state.isSending ? 'Sending Request...' : 'Send Request'}</Button>
                                    </Form.Group>
                                </Modal.Footer>
                            </Form>
                    }
                </>

            </Modal>
        );
    }
}

export default SenderRequest;
