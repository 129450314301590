/**
 * Created by madura on 1/26/17.
 */
import React from "react";
import {
    Button,
    Modal
} from 'shoutout_themes';

const ConfirmDeleteDialog = ({showConfirmDeleteDialog, selectedPeopleCount, onConfirm, closeConfirmDeleteDialog, selectedPeople, selectAll,selectAllStatus, selectedFilters, showLoader, selectedSegmentId,peopleList}) => {
    return (
        <Modal show={showConfirmDeleteDialog} onHide={closeConfirmDeleteDialog}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>You are about to delete {selectedPeopleCount} people. Are you sure?</div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="dark" onClick={closeConfirmDeleteDialog} disabled={showLoader}>Cancel</Button>
                <Button variant="danger" onClick={() => {
                    onConfirm(selectedPeople, selectAll,selectAllStatus, selectedFilters, selectedSegmentId,peopleList)
                }} disabled={showLoader}>{showLoader ? 'Deleting...' : 'Delete'}</Button>
            </Modal.Footer>

        </Modal>
    );
};

export default ConfirmDeleteDialog;