import { fetchGet, fetchPost, fetchDelete, fetchPut, fetchGetText, handleErrors, fetchPostText, fetchPostMultipart } from './CommonServiceUtils';
import Constants from './../Constants';
import { UtilService } from './Services';
const SHOUTOUT_API_SVR = 'https://api2.getshoutout.com/api/';
const SHOUTOUT_API_SVRLSS = 'https://api.getshoutout.com/legacyservice/';
const SHOUTOUT_API_SVRLSS_V9 = 'https://api.getshoutout.com/accountservice/';
const SHOUTOUT_CORE_SERVICE = 'https://api.getshoutout.com/coreservice/';
const SHOUTOUT_CAMPAIGN_SERVICE = 'https://api.getshoutout.com/campaignservice/';
const SHOUTOUT_AUTH_SERVICE="https://api.getshoutout.com/authservice/";
const SHOUTOUT_UTILITY_SERVICE = "https://api.getshoutout.com/utilityservice/";

class Shoutout {

    //get events logs
    static getRecords(eventId) {
        let url = SHOUTOUT_API_SVR + 'api/items/activities/records';
        if (eventId !== "eventId") {
            url = url + '?item_id=' + eventId;
        }
        return fetchGet(url);
    };

    static getActivities(contactId, fromDate, toDate, eventId) {
        let url = SHOUTOUT_CORE_SERVICE + 'activities';
        if (contactId) {
            url = url + '?contactId=' + contactId + '&fromDate=' + fromDate;
        } else {
            url = url + '?fromDate=' + fromDate;
        }
        if (toDate) {
            url = url + '&toDate=' + toDate;
        }
        if (eventId) {
            url = url + '&eventId=' + eventId;
        }
        return fetchGet(url);
    }
    static exportActivities(fromDate, toDate, eventId) {
        return fetchGetText(`${SHOUTOUT_CORE_SERVICE}activities/export?fromDate=${fromDate}&toDate=${toDate}&eventId=${eventId}`);
    }


    //create new api key
    getNewApiKey() {
        return fetchPost(SHOUTOUT_API_SVRLSS_V9 + 'accounts/apikeys', {
            scopes: {
                activities: ['read', 'write'],
                messages: ['read', 'write'],
                contacts: ['read', 'write']
            },
        });
    };

    //listed created api keys
    static getCreatedApiKeys() {
        return fetchGet(SHOUTOUT_API_SVRLSS_V9 + 'accounts/apikeys');
    };

    //listed user id
    static getSenderIds() {
        return fetchGet(SHOUTOUT_API_SVRLSS_V9 + 'accounts/senders');
    };


    //make sms request
    static sendMessage(fromNumber, toNumber, message, transport) {
        return fetchPost(SHOUTOUT_CORE_SERVICE + 'messages', {
            "source": fromNumber,
            "destinations": [toNumber],
            "transports": [transport.toLowerCase()],
            "content": {
                [transport.toLowerCase()]: message
            }
        });
    };

    //signup
    signup(signupObj) {
        return fetchPost(SHOUTOUT_API_SVR + 'accounts/signup', signupObj);
    };

    subscription(action, cId, ownerId, address, type) {
        return fetchPost(SHOUTOUT_API_SVRLSS + 'hooks/' + cId + '/subscription/' + ownerId, {
            action: action,
            address: address,
            type: type
        });
    };

    //request new sender id 
    static requestSenderId(keyword, type, company, businessNature, designation, userName,countries,usage) {
        let request;
        if (type === Constants.TRANSPORT_EMAIL)
            request = {
                type: type.toLowerCase(),
                keyword
            };
        else {
            request = {
                type: type.toLowerCase(),
                keyword,
                companyName: company,
                businessNature,
                userDesignation: designation,
                userName,
                countries,
                usage
            };
        }
        return fetchPost(SHOUTOUT_API_SVRLSS_V9 + 'accounts/senders', request, { 'name': 'request-sender-id', 'category': 'profile', 'data': { type, keyword } });
    };


    static uploadSmsSenderIdAgreement(id, base64FileContent) {

        return fetchPut(SHOUTOUT_API_SVRLSS_V9 + 'accounts/senders', { _id: id, agreement: base64FileContent });
    };

    static downloadSmsSenderIdAgreement(senderId) {

        return fetchGetText(SHOUTOUT_API_SVRLSS_V9 + 'accounts/senders/' + senderId);
    };

    // request credits
    requestCredits(country, credits) {
        let payload = {
            country: country,
            credits: credits
        };
        return fetchPost(SHOUTOUT_API_SVR + 'billings/credits', payload);
    };

    //get user information
    static getUser() {
        return fetchGet(SHOUTOUT_API_SVRLSS_V9 + 'accounts/users');
    };

    //get team members
    getTeamMembers() {
        return fetchGet(SHOUTOUT_API_SVR + 'accounts/members/list');
    };

    //add new team members
    static requestNewMembers({name, email, scopes}) {
        return fetchPost(SHOUTOUT_AUTH_SERVICE + 'team', {
            email: email,
            name: name,
            scopes
        });
    };

    deleteTeamMember(userId) {
        return fetch(SHOUTOUT_API_SVR + 'accounts/members/' + userId, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            isProtected: true,
            showLoader: true,
        }).then(handleErrors)
    }

    static getCampaigns(limit, skip, type) {
        let url = SHOUTOUT_CAMPAIGN_SERVICE + 'campaignsv2?limit=' + limit + '&skip=' + skip;
        if (type) {
            url += "&type=" + type;
        }
        return fetchGet(url);
    }

    static getCampaignsStat(fromDate,toDate ) {
        let url = SHOUTOUT_CAMPAIGN_SERVICE + 'campaignsv2/summary?fromDate='+fromDate +'&toDate='+toDate;
        return fetchGet(url);
    }
    getCampaign(campaignId) {
        return fetchGet(SHOUTOUT_CAMPAIGN_SERVICE + 'campaignsv2/' + campaignId);
    }

    getCampaignsLogs(campaignId, transport) {
        return fetchGet(SHOUTOUT_CAMPAIGN_SERVICE + 'logs?campaignId=' + campaignId + '&transport=' + transport);
        //return fetchGet(SHOUTOUT_CAMPAIGN_SERVICE + 'logs?campaignId=' + campaignId + '&transport=' + transport.toLowerCase());
    }


    deleteCampaign(campaignId) {
        return fetchDelete(SHOUTOUT_CAMPAIGN_SERVICE + "campaignsv2/" + campaignId);
    }

    static retryCampaign(campaignId) {
        return fetchPost(SHOUTOUT_CAMPAIGN_SERVICE + "campaigns_retry", { "_id": campaignId });
    }

    //get event Details
    static getEvents() {
        return fetchGet(SHOUTOUT_API_SVR + 'coreservice/events');
    }

    getContacts(limit, lastEvaluatedKey) {
        return fetchGet(SHOUTOUT_CORE_SERVICE + 'contacts?skip=' + lastEvaluatedKey + '&limit=' + limit);
    }

    static getContact(contactId) {
        return fetchGet(SHOUTOUT_CORE_SERVICE + 'contacts/' + contactId);
    }

    filterContacts(limit, selectedFilters, lastEvaluatedKey) {
        return fetchPost(SHOUTOUT_CORE_SERVICE + 'contacts/filter?skip=' + lastEvaluatedKey + '&limit=' + limit,
            selectedFilters);
    }

    static exportContacts(requestObj) {

        return fetchPostText(`${SHOUTOUT_CORE_SERVICE}contacts/filter/export`, requestObj);
    }

    deleteContacts(selectedContactsIds, selectedFilters, excludedIds) {
        let url = SHOUTOUT_CORE_SERVICE + 'contacts';
        let deleteObj = {};
        if (selectedFilters && selectedFilters.length) {
            deleteObj.filters = selectedFilters;
        }
        if (selectedContactsIds.length) {
            deleteObj.includeIds = selectedContactsIds;
        }
        if (excludedIds.length) {
            deleteObj.excludeIds = excludedIds;
        }
        return fetchDelete(url, deleteObj);
    }

    getColumns() {
        return new Promise((resolve, reject) => {
            resolve({
                name: {
                    order: 0,
                    label: 'Name',
                    type: 'string',
                    visible: true
                },
                mobile_number: {
                    order: 1,
                    label: 'Mobile Number',
                    type: 'string',
                    visible: true
                },
                email: {
                    order: 2,
                    label: 'Email',
                    type: 'string',
                    visible: true
                },
                last_seen_on: {
                    order: 3,
                    label: 'Last Seen On',
                    type: 'date',
                    visible: true
                },
                tags: {
                    order: 4,
                    label: 'Tags',
                    type: 'array',
                    visible: true
                },
                user_id: {
                    order: 5,
                    label: 'User ID',
                    type: 'string',
                    visible: false
                },
                age: {
                    label: 'Age',
                    type: 'number',
                    visible: false
                }

            });
        });
    }

    static uploadContactsFile(base64FileContent) {
        return fetchPostMultipart(SHOUTOUT_API_SVR + 'coreservice/contacts/upload2', base64FileContent,
            { 'name': 'import-people-file-upload', 'category': 'people' }
        );
    }

    uploadPeople(fileId, contactAttrs, tags, country) {
        let request = {
            file_id: fileId,
            contact_attrs: contactAttrs,
            tags: tags,
            country: country,
            updateMetadata: true
        };
        return fetchPost(SHOUTOUT_API_SVR + 'coreservice/contacts/import2', request, { 'name': 'import-people', 'category': 'people', 'data': request });
    }

    addOrUpdatePeople(peopleList) {
        return fetchPost(SHOUTOUT_CORE_SERVICE + 'contacts', peopleList, { 'name': 'add-update-people', 'category': 'people' });
    }

    static getMessageLogByContactId(contactId, fromDate, toDate) {
        return fetchGet(SHOUTOUT_API_SVRLSS + 'logs?contactId=' + contactId + '&fromDate=' + fromDate + "&toDate=" + toDate);
    }

    static getAppUsersList() {
        return fetchGet(SHOUTOUT_API_SVR + 'accounts/admin/users');
    }




    getAppUserToken(userId) {
        return fetchGet(SHOUTOUT_API_SVR + 'accounts/admin/users/' + userId + '/token');
    }



    _preProcessFilters(filters) {//update columnType to uppercase in filters as some API calls not working with lowercase
        return filters.map(filter => {
            return filter;
        })
    }

    createCampaign(campaignRequest) {

        if (campaignRequest.filters) {
            campaignRequest.filters = this._preProcessFilters(campaignRequest.filters);
        }

        return fetchPost(SHOUTOUT_CAMPAIGN_SERVICE + 'campaigns', campaignRequest, {
            'name': 'create-campaign',
            'category': 'campaigns',
            'data': {
                name: campaignRequest.name,
                transports: campaignRequest.transports.toString(),
                isScheduled: campaignRequest.isScheduled,
                type: campaignRequest.type
            }
        });
    }

    static uploadImage(content, contentType) {
        return fetchPost(SHOUTOUT_UTILITY_SERVICE + 'images', {
            content,
            contentType
        });
    }

    static saveTemplate(content, templateName, templateType) {
        return fetchPost(SHOUTOUT_API_SVRLSS_V9 + 'accounts/templates', { type: templateType, content: UtilService.cleanEmptyValuesInObject(content), name: templateName }, { 'name': 'save-template', 'data': { type: templateType, name: templateName }, 'category': 'campaigns' });
    }

    static getTemplates(templateType) {
        return fetchGet(SHOUTOUT_API_SVRLSS_V9 + 'accounts/templates?type=' + templateType);
    }

    static deleteTemplate(templateId) {
        return fetchDelete(SHOUTOUT_API_SVRLSS_V9 + 'accounts/templates?id=' + templateId);
    }


    static createEvent(event) {
        return fetchPost(SHOUTOUT_API_SVR + 'coreservice/events', event, { 'name': 'create-event', 'data': event, 'category': 'events' });
    }

    static emailSenderIdVerify(token) {
        return fetchPost(SHOUTOUT_API_SVRLSS_V9 + 'accounts/emails/verify', { token }, { 'name': 'email-sender-id-verify', 'category': 'profile' });
    }


    static getIncomingSmsNumbers() {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(["94778845713", "94777845722"]);
            }, 1000);

        });
    }

    static getInboundLogs(fromDate, toDate, transport, search) {
        return fetchGet(`${SHOUTOUT_CAMPAIGN_SERVICE}inboundlogs?transport=${transport.toLowerCase()}&fromDate=${fromDate}${toDate ? '&toDate=' + toDate : ''}${search ? '&search=' + search : ''}`);
    }

    static getInboundLogsStat(fromDate, toDate, transport, search) {
        return fetchGet(`${SHOUTOUT_CAMPAIGN_SERVICE}inboundlogs/summary?transport=${transport.toLowerCase()}&fromDate=${fromDate}&toDate=${toDate}${search ? '&search=' + search : ''}`);
    }

    static getForms() {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve([{ id: "2323", "name": "Movie Request" }, { id: "2321", "name": "Early Request" }]);
            }, 1000);

        })
    }
    static getInvoices() {
        return fetchGet(SHOUTOUT_API_SVRLSS_V9 + 'invoices');
    }

    static updateCampaignInfo(newMetadata) {
        return fetchPut(SHOUTOUT_API_SVRLSS_V9 + 'accounts/users', { campaignInfo: newMetadata });

    }

    static forgetContact(contactId) {
        return fetchDelete(SHOUTOUT_CORE_SERVICE + "contacts/forget/" + contactId);
    }

    static exportContact(contactId) {
        return fetchGetText(SHOUTOUT_CORE_SERVICE + "contacts/export/" + contactId);
    }
}

export default Shoutout;
