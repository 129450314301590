/**
 * Created by madura on 2/7/17.
 */
import React from 'react';
import {
    Row, Col, Card
} from 'shoutout_themes';
import { FaIcon } from 'shoutout_themes';
import { faCheck, faEnvelope, faComment, faCommentAlt } from 'shoutout_themes/es/FaIconsSolid';
import Constants from './../../../../Constants';
import { LoadingComponent } from './../../../utils/UtilComponents';

const getContactsSummary = (campaignType, selectedSegment, excludedPeople, selectedPeopleCount, selectedEvent, selectedFilters) => {
    return (
        campaignType !== Constants.CAMPAIGN_TRIGGER ?
            selectedSegment.id !== '' ?
                <div className="d-flex justify-content-between align-items-center">
                    <span className="mr-2">Selected Segment:</span>
                    <span className="font-weight-bold">{selectedSegment.segment.name} {excludedPeople.length ?
                        <small className="text-muted">&nbsp;&nbsp;
                            Excluding {excludedPeople.length}&nbsp;
                            people</small> : null}</span>
                </div> :
                selectedFilters.length ? null : <div className="d-flex justify-content-between align-items-center">
                    <span className="mr-2">Selected People:</span>
                    <span className="font-weight-bold">{selectedPeopleCount}</span>
                </div> :
            <div className="d-flex justify-content-between align-items-center">
                <span className="mr-2">Selected Event:</span>
                <span className="font-weight-bold">{selectedEvent.title}</span>
            </div>
    );
}
const CampaignSummary = ({ campaignName, isSchedule, scheduleDate, scheduleTime, selectedSegment, transports, selectedPeopleCount, campaignType, selectedEvent, isCreatingCampaign, excludedPeople, selectedFilters, transportDetails }) => {
    const contactSummary = getContactsSummary(campaignType, selectedSegment, excludedPeople, selectedPeopleCount, selectedEvent, selectedFilters);
    console.debug(transportDetails)
    return (
        <>
            <Row className="justify-content-center">
                <Col lg={8} sm={10} xs={12}>
                    <Card className="text-left">
                        <Card.Body>


                            <div className="d-flex justify-content-between align-items-center">

                                <div>
                                    <span className="mr-2">Campaign Name:</span>
                                    <span className="font-weight-bold">{campaignName}</span>
                                </div>


                                <span className="border border-muted rounded-circle p-2 line-height-1">
                                    <FaIcon className="text-success" icon={faCheck} />
                                </span>

                            </div>
                            <hr />
                            <div className="d-flex justify-content-between align-items-center">


                                <span className="mr-2">Channels:</span>
                                <span className="m-0"> {transports.map(transport => {
                                    return <span className="extra-small bg-label p-1 mx-2" key={transport}>
                                        {
                                            transport === Constants.TRANSPORT_EMAIL ?
                                                <FaIcon className="mx-2" icon={faEnvelope} /> :
                                                transport === Constants.TRANSPORT_MESSENGER ?
                                                    <FaIcon className="mx-2" icon={faComment} /> :
                                                    <FaIcon className="mx-2" icon={faCommentAlt} />
                                        }
                                        {transportDetails[transport] && transport === Constants.TRANSPORT_MESSENGER ? transportDetails[transport].displayName : transportDetails[transport].selectedSenderId}</span>

                                })}</span>

                                <span className="border border-muted rounded-circle p-2 line-height-1">
                                    <FaIcon className="text-success" icon={faCheck} />
                                </span>

                            </div>
                            {contactSummary && <div>
                                <hr />
                                <div className="d-flex justify-content-between align-items-center">

                                    <span>
                                        {contactSummary}
                                    </span>
                                    {campaignType !== Constants.CAMPAIGN_TRIGGER && <div>
                                    <span className="mr-2">Launch on:</span>
                                    <span className="font-weight-bold">{isSchedule ? <span>{scheduleDate} at {scheduleTime}</span> : 'Now'}</span>
                                    </div>}

                                    <span className="border border-muted rounded-circle p-2 line-height-1">
                                        <FaIcon className="text-success" icon={faCheck} />
                                    </span>

                                </div>
                            </div>}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {isCreatingCampaign && <LoadingComponent />}
        </>
    )
};
export default CampaignSummary;