import React from "react";
import { Button, Card, Form } from 'shoutout_themes';
import "./People.css";

const PeopleSegmentCreate = ({ showPanel, selectedFilters, createSegment, onChangeCreateSegmentName, segmentName, exisitngSegments, isSegmentNameExist, matchingSegmentWithSameFlters }) => {

    return (

        showPanel ? <Card className="shadow rounded-0 border-0 mb-2">
            <Card.Body>
                <Form>
                    <Form.Group controlId="create-segment">
                        <h6 className="mb-0 font-weight-bold">Create a New Segment</h6>
                        <Form.Control type="text" placeholder="Segment Name"
                            onChange={e => {
                                onChangeCreateSegmentName(e.target.value);
                            }} value={segmentName} required />
                        {isSegmentNameExist && <small className="text-danger">There is a segment with this name. Please choose some other name</small>}
                        {matchingSegmentWithSameFlters ? <small className="text-warning">Segment <strong>{matchingSegmentWithSameFlters.name}</strong> seems have the same filters.</small> : null}
                    </Form.Group>
                    <div className="text-right">
                        <Button variant="dark" size="sm" onClick={() => {
                            createSegment(selectedFilters, segmentName, exisitngSegments)
                        }} disabled={isSegmentNameExist}>Save Segment</Button>
                    </div>
                </Form>
            </Card.Body>
        </Card> : null

    );
};

export default PeopleSegmentCreate;
