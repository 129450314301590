import React from 'react';

import {
    PeoplePageContentContainer
} from './../../redux/containers/PeopleListContainer';
import './People.css';
const PeoplePage=(props)=>{
        return (
            <PeoplePageContentContainer {...props}/>
        );
    }


export default PeoplePage;