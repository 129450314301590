import React from 'react';
import {
   Modal, Button
} from 'shoutout_themes';
import SendEmailMessageContainer from './../../redux/containers/SendEmailMessageContainer';
import SendSmsMessageContainer from './../../redux/containers/SendSmsMessageContainer';
import Constants from './../../Constants';
import {isEmpty} from 'lodash';

const SendMessage=({ show ,sendMessage, showSendMessage,  isSending,senderId ,destination,message,transport,...rest})=> {
    const hideSendMessage=()=>{
        showSendMessage(false)
    }
        return (
            <Modal show={show} onHide={hideSendMessage} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Send Message</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        {transport===Constants.TRANSPORT_SMS && <SendSmsMessageContainer {...rest} hideSendMessage={hideSendMessage}/>}
                        {transport===Constants.TRANSPORT_EMAIL&&<SendEmailMessageContainer {...rest} hideSendMessage={hideSendMessage}/>}
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={hideSendMessage}>Close</Button>
                    <Button variant="info" onClick={() => { sendMessage(senderId, destination, message,transport) }} disabled={isEmpty(senderId) || isEmpty(message)|| isEmpty(destination) || isSending}>{isSending ? 'Sending...' : 'Send'}</Button>
                </Modal.Footer>

            </Modal>);
    

}

export default SendMessage;
