/**
 * Created by madura on 1/23/17.
 */

import people from './PeopleReducers';
import CampaignCreateReducer from './CampaignCreateReducers';
import CommonReducer from './CommonReducers';
import peopleImport from './PeopleImportReducers';
import EventReducer from './EventReducers';

import TemplateReducer from './TemplateReducers';
import UtilReducer from './UtilReducers';
import BillingReducers from './BillingReducers';

import { combineReducer } from './../../contexts/ContextUtils';
/*
 update the states

 * Things should not do inside reducers
 * Mutate its arguments;
 * Perform side effects like API calls and routing transitions;
 * Call non-pure functions, e.g. Date.now() or Math.random().
 * */


const reducers = combineReducer({
    people: people,
    createCampaign: CampaignCreateReducer,
    common: CommonReducer,
    peopleImport,
    event: EventReducer,
    template: TemplateReducer,
    util: UtilReducer,
    billing: BillingReducers
})


export { reducers };
