import React from 'react';
import { Card, Badge, OverlayTrigger, Tooltip, Button, FaIcon } from 'shoutout_themes';
import { faCreditCard,faUniversity,faDownload } from 'shoutout_themes/es/FaIconsSolid';
import { Column, Table, AutoSizer, SortDirection } from 'react-virtualized';
import { sortBy as _sortBy, reverse } from 'lodash';
import moment from 'moment';
import { BillingService } from './../../services/Services';
class BillingInvoices extends React.PureComponent {

    constructor() {
        super();
        this.state = { sortDirection: SortDirection.DESC, sortBy: 'createdOn', invoiceList: [] };
        this._sort = this._sort.bind(this);
    }

    componentDidMount() {

        BillingService.getInvoices().then(res => {
            this.setState({ invoiceList: res });
        });

    }
    _sort({ sortBy, sortDirection }) {
        this.setState({ sortBy, sortDirection })
    }

    onClickDownloadPdf(invoice) {
        BillingService.getInvoice(invoice._id, 1).then(res => {
            let fileName = 'INVOICE-' + invoice.invoiceId + '.pdf';
            let url = 'data:application/octet-stream;base64,' + res;
            let a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
        });
    }
    render() {
        const { invoiceList } = this.state;
        const { sortBy, sortDirection } = this.state;
        const sortedList = _sortBy(invoiceList, [sortBy]);
        if (sortDirection === SortDirection.DESC) {
            reverse(sortedList);
        }
        return (
            <Card className="border-0 rounded-0">

                <Card.Body>
                    <h6 className="font-weight-bold">Invoices</h6>
                    <AutoSizer disableHeight>
                        {({ width }) => (

                            <Table
                                ref='InvoiceTable'
                                disableHeader={false}
                                headerHeight={40}
                                height={400}
                                rowHeight={40}
                                rowGetter={({ index }) => sortedList[index]}
                                rowCount={invoiceList.length || 0}
                                sortBy={sortBy}
                                sortDirection={sortDirection}
                                width={width}
                                sort={this._sort}
                                headerClassName="text-small"
                                gridClassName="text-small"
                            >
                                <Column
                                    label="Created On"
                                    dataKey='createdOn'
                                    width={145}
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return moment(cellData).format('ll');
                                    }}
                                />
                                <Column
                                    label="Id"
                                    dataKey='invoiceId'
                                    width={90}
                                    flexGrow={1}
                                />
                                <Column
                                    label="Amount"
                                    dataKey='amount'
                                    width={90}
                                    flexGrow={1}
                                    cellRenderer={({ cellData, rowData }) => {
                                        return <span>{rowData.currencyCode || "USD"} {cellData}</span>;
                                    }}
                                />
                                <Column
                                    label="Status"
                                    dataKey='paymentStatus'
                                    width={70}
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return <small><Badge variant={cellData === "success" ? "success" : "danger"}>{cellData}</Badge></small>;
                                    }}
                                />

                                <Column
                                    label="Method"
                                    dataKey='paymentMethod'
                                    width={90}
                                    flexGrow={1}
                                    cellRenderer={({ cellData }) => {
                                        return <span><FaIcon className="mr-1" icon={cellData === "online" ? faCreditCard : faUniversity} />{cellData}</span>;
                                    }}
                                />
                                <Column
                                    label="Invoice"
                                    dataKey=''
                                    width={110}
                                    flexGrow={1}
                                    cellRenderer={({ rowData }) => {
                                        return (<OverlayTrigger overlay={<Tooltip id={rowData.invoiceId}>Download a pdf copy of the invoice</Tooltip>}>
                                            <Button variant="link" size="sm" onClick={() => this.onClickDownloadPdf(rowData)}><small><FaIcon className="mr-1" icon={faDownload} />Download</small> </Button>
                                        </OverlayTrigger>);
                                    }}
                                />

                            </Table>

                        )}
                    </AutoSizer>
                </Card.Body>
            </Card >
        );
    }
};
export default BillingInvoices;