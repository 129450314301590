import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Link, withRouter } from 'react-router-dom';
import UserContext from './../../contexts/UserContext';
import { Card, FaIcon, Accordion ,Button } from 'shoutout_themes';
import { faUserPlus, faUsers, faPaperPlane, faCheckCircle, faTachometerAlt } from 'shoutout_themes/es/FaIconsSolid';
import './OnboardSection.css';
import { Context } from './../../contexts/ContextUtils';

const url = "/engagement/campaigns/create/new";

const OnBoardingSteps = ({ id, icon, status, section, title, description, children }) => {

    return (
        <div>
            {!status && <h6 className="mt-2 mb-0 font-weight-bold"><FaIcon className="mr-2" icon={icon} size="1x" /> {section}</h6>}
            <small>
                {id}. {title} {status && <FaIcon className="ml-2 text-success" icon={faCheckCircle} size="1x" />}
            </small>
            {!status ? <p className="extra-small text-muted mb-2">
                {description}
            </p> : <hr className="my-1" />}
            {children}
        </div>
    )
}

const PeopleStep = ({ status, to, action, icon }) => {
    return (<div>
        <OnBoardingSteps section="People" title="Create a person" id="01" icon={faUsers} status={status} description="First step is to create a person to receive your SMS or Email. You can create yourself for testing!">
            {!status && <Link className="btn btn-primary btn-sm rounded-0 border-0 align-center" to={to}><small><FaIcon className="mr-2" icon={icon} size="1x" />{action}</small></Link>}
        </OnBoardingSteps>
    </div>)
}

const CampaignStep = withRouter(({ status, to, action, icon ,location}) => {

    const { store } = useContext(Context);
    const activeKey = String(store.createCampaign.activeStep);
    const campaignurl = location.pathname;
   
    
    return (<div>
        <OnBoardingSteps section="Campaigns" title="Create a campaign" id="02" icon={faPaperPlane} status={status} description="Next, create an SMS or Email campaign by selecting the person you created.">

        {!status && <Link className="btn btn-primary btn-sm rounded-0 border-0 w-100" to={to}><small><FaIcon className="mr-2" icon={icon} size="1x" />{action}</small></Link>}
            <div>
                    {(campaignurl == url)?
                    <Accordion activeKey={activeKey}>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button}  eventKey = "0" data-toggle="collapse-show"  variant="link" >
                                    Select
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body className="extra-small">Give a name to your campaign and select the campaign type as<strong> “Broadcast” </strong>for SMS & Email campaigns.<br/><br/><span className="blockquote-footer">For automated/triggered messages please contact us:</span> 
                                <a href="https://getshoutout.com/contactus">Contact Us</a></Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey = "1">
                                    Configure
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body className="extra-small">In this step you can select the customer segment that you wish to conduct the campaign.
                                    If you haven't uploaded the customer database or created any person you can get it done in this step as well<br/>
                                    <span className="blockquote-footer"> For testing purpose, you can select all and go to the next step. The contact that you created in the earlier step will receive the SMS or Email.</span></Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                    Settings
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body className="extra-small">Next, you have to select the channel that you wish to conduct the campaign<br/><span className="blockquote-footer">( SMS / Email or both)
                                <br/>If you need a specific SMS Sender ID or a number for your business all you have to do is go to “My Profile” and request a new ID by filling the necessary details.
                                <br/><strong>And please upload the agreement along with your signature.</strong></span></Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                    Messages
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="3">
                                <Card.Body className="extra-small">Create your message in the SMS & Email Creators</Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                                    Create
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="4">
                                <Card.Body className="extra-small">Just a quick review before you launch the campaign.</Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        
                    </Accordion>:'' }
                
            </div>

        </OnBoardingSteps>
    </div>)
})

const CompletedStep = ({ status, to, action, icon, onSwitch }) => {
    return (<div>
        <OnBoardingSteps section="Dashboard" title="Yayyyy!!" id="03" icon={faTachometerAlt} status={status} description="You're almost done. Go to Dashboard to see an overview">
            {!status && <Link className="btn btn-primary btn-sm rounded-0 border-0" to={to} onClick={onSwitch}><small><FaIcon className="mr-2" icon={icon} size="1x" />{action}</small></Link>}
        </OnBoardingSteps>
    </div>)
}


const OnboardSection = withRouter(({ contactsAvailable: createdPeople, campaignCreated: createdCampaign, history,location,onChangeActiveStep}) => {
    const { store } = useContext(Context);
    const { hideGettingStarted } = useContext(UserContext);
    const [onBoarding, setOnBoarding] = useState({ finished: false })

    const onSwitchDashboard = useCallback(() => {
        setOnBoarding({ ...onBoarding, finished: true })
    }, [onBoarding])

    useEffect(() => {
        if (onBoarding.finished) {
            setTimeout(hideGettingStarted, 100)
            history.push('/');
        }
    }, [onBoarding])

    if (store.createCampaign.campaignCreated) {
        return (
            <>
                <PeopleStep status={true} />
                <CampaignStep status={true} />
                <CompletedStep action="Go to Dashboard" onSwitch={onSwitchDashboard} icon={faTachometerAlt} />
            </>
        )
    }
    if (store.people.peopleCount > 0) {
        return (
            <>
                <PeopleStep status={true} />
                <CampaignStep action="Go to Campaigns" to="/engagement/campaigns/create/new" icon={faPaperPlane} status={false} />
            </>
        )
    }
    else {
        return <PeopleStep action="Create a Person" icon={faUserPlus} status={false} />
    }


}
)
export default (props) => (
    <Card className="onboard-section shadow rounded-0 border-0">
        <Card.Body className="overflow-auto">
            <p className="lead text-primary">Getting Started</p>
            <hr className="dashed" />
            <OnboardSection {...props} />
        </Card.Body>
    </Card>)