/**
 * Created by madura on 2/7/17.
 */
import CREATE_CAMPAIGN_ACTIONS, {
    ON_CHANGE_EMAIL_SUBJECT,
    ON_SELECT_SENDER_ID,
    SET_SCHEDULE_CAMPAIGN,
    ON_CHANGE_CAMPAIGN_NAME,
    SET_SCHEDULE_ON,
    SELECT_EDITOR,
    ON_CHANGE_EDITOR_STATE,
    ON_SELECT_EVENT,
    ON_CAMPAIGN_CREATING
} from './../actions/CampaignCreateActions';
import {
    RECEIVED_SENDER_IDS
} from './../actions/CommonActions';
import BILLING_ACTIONS from './../actions/BillingActions';


import PEOPLE_IMPORT_ACTIONS from './../actions/PeopleImportActions';
import { PERSON_CREATED } from './../actions/PeopleActions';
import update from 'immutability-helper';
import Constants from './../../Constants';
import { find, isEmpty, assign } from 'lodash';
import CAMPAIGN_CREATE_ACTIONS from './../actions/CampaignCreateActions';
const initialState = {
    sms: {
        message: '',
        sample: '',
        pages: 1,
        selectedSenderId: '',
        editorState: '',
        characterOverLimit: false,
        isUnicode: false,
        shouldReload: false,
        campaignType: 'promotional'
    },
    email: {
        message: '',
        subject: '',
        selectedSenderId: '',
        editorState: '',
        shouldReload: false
    },
    [Constants.TRANSPORT_MESSENGER.toLowerCase()]: {
        template: {},
        selectedSenderId: '',
    },
    transports: [],
    name: '',
    isSchedule: false,
    launchOn: new Date().toISOString(),
    activeEditor: Constants.TRANSPORT_SMS,
    campaignType: '',
    showSelectSegment: false,
    showSelectEvent: false,
    selectedEvent: '',
    activeStep: 0,
    steps: {
        create: { isCreating: false }
    },
    selectedSegment: { id: '' },
    selectedPeople: [],
    excludedPeople: [],
    isFromPeople: false,
    selectedFilters: [],
    selectedPeopleCount: 0,
    selectImportPeople: false,
    selectCreateContact: false,
    metadata: {},
    messengerIsNewlySelectedTemplate: false, //Added to identify select template since it's hard to submit a callback to GalleryContainer
    campaignCreated: false
};

const _CAMPAIGN_CREATE_REDUCER_ACTIONS = {
    _SHOW_SELECT_EVENT: '_SHOW_SELECT_EVENT',
    _SHOW_SELECT_SEGMENT: '_SHOW_SELECT_SEGMENT',

    _SET_IS_CREATING: '_SET_IS_CREATING',
    _SELECT_FILTERS: '_SELECT_FILTERS'
};
const getActiveSenderIdForTransport = (senderIds, transport) => {
    let senderIdObj = find(senderIds[transport.toUpperCase()], item => {
        return item.status === 1;
    });
    if (!isEmpty(senderIdObj)) {
        return senderIdObj.senderId;
    }
    return '';
};

const CampaignCreateReducer = (state = initialState, action) => {
    switch (action.type) {


        case ON_CHANGE_EMAIL_SUBJECT: {
            return update(state, {
                email: {
                    subject: { $set: action.subject }
                }
            });
        }
        case ON_SELECT_SENDER_ID: {
            const newState = {
                [action.transport]: {
                    selectedSenderId: { $set: action.senderIdObj.senderId }
                }
            };
            if (action.transport === Constants.TRANSPORT_SMS.toLowerCase()) {
                if (action.senderIdObj.metadata && action.senderIdObj.metadata.usage) {
                    newState[action.transport].campaignType = { $set: action.senderIdObj.metadata.usage === "both" ? "promotional" : action.senderIdObj.metadata.usage };
                }
            }
            return update(state, newState);
        }
        case ON_CHANGE_CAMPAIGN_NAME: {
            return update(state, {
                name: { $set: action.campaignName }
            });
        }

        case SET_SCHEDULE_CAMPAIGN: {
            return update(state, {
                isSchedule: { $set: action.isSchedule }
            });
        }
        case SET_SCHEDULE_ON: {
            return update(state, {
                launchOn: { $set: action.date }
            });
        }

        case ON_CAMPAIGN_CREATING: {
            return CampaignCreateReducer(state, {
                type: _CAMPAIGN_CREATE_REDUCER_ACTIONS._SET_IS_CREATING,
                isShow: true
            });
        }

        case SELECT_EDITOR: {
            return update(state, {
                activeEditor: { $set: action.editorType }
            });
        }

        case ON_CHANGE_EDITOR_STATE: {
            let updateRequest = {
                [action.transport]: {
                    editorState: { $set: action.editorState }
                }
            };
            if (action.transport === 'sms') {
                updateRequest[action.transport].characterOverLimit = { $set: action.characterOverLimit };
                updateRequest[action.transport].isUnicode = { $set: action.isUnicode };
            }
            return update(state, updateRequest)
        }
        case CREATE_CAMPAIGN_ACTIONS.SELECT_CAMPAIGN_TYPE: {
            let updateRequest = {};
            if (action.campaignType === Constants.CAMPAIGN_BROADCAST) {
                updateRequest = {
                    showSelectSegment: { $set: true },
                    showSelectEvent: { $set: false },

                };
                if (state.transports.includes(Constants.TRANSPORT_MESSENGER)) {
                    const newTransports = [...state.transports];
                    newTransports.splice(state.transports.indexOf(Constants.TRANSPORT_MESSENGER), 1);
                    updateRequest.transports = { $set: newTransports };
                }


            } else if (action.campaignType === Constants.CAMPAIGN_TRIGGER) {
                updateRequest = {
                    showSelectSegment: { $set: false },
                    showSelectEvent: { $set: true }
                };
            }
            updateRequest.activeEditor = { $set: updateRequest.transports && updateRequest.transports['$set'].length > 0 ? updateRequest.transports['$set'][0] : (state.transports.length > 0 ? state.transports[0] : '') };
            updateRequest.campaignType = { $set: action.campaignType };
            updateRequest.activeStep = { $set: 0 };
            return update(state, updateRequest);
        }
        case ON_SELECT_EVENT: {
            return update(state, {
                selectedEvent: { $set: action.eventId }
            });
        }

        case CREATE_CAMPAIGN_ACTIONS.SELECT_SEGMENT: {
            return update(state, {
                selectedSegment: { $set: action.selectedSegment },
                isFromPeople: { $set: action.isFromPeople },
                selectImportPeople: { $set: false },
                selectCreateContact: { $set: false },
                selectedPeople: { $set: [] },
                excludedPeople: { $set: [] },
                selectedFilters: { $set: [] }
            });
        }

        case CREATE_CAMPAIGN_ACTIONS.SELECT_PEOPLE: {
            return update(state, {
                selectedPeople: { $set: action.selectedPeople },
                isFromPeople: { $set: action.isFromPeople },
                excludedPeople: { $set: action.excludedPeople || [] },
                selectedPeopleCount: { $set: action.selectedPeopleCount || 0 }
            });
        }

        case CREATE_CAMPAIGN_ACTIONS.CREATE_FROM_PEOPLE: {

            let updateRequest = {
                excludedPeople: { $set: action.excludedPeople || [] },
                selectedPeople: { $set: [] },
                selectedFilters: { $set: [] },
                selectImportPeople: { $set: false },
                selectCreateContact: { $set: false },
                isFromPeople: { $set: true },
                showSelectEvent: { $set: false },
                showSelectSegment: { $set: false },
                activeStep: { $set: 0 },
                campaignType: { $set: Constants.CAMPAIGN_BROADCAST }
            };

            if (action.selectedSegment && action.isSelectAll) {
                updateRequest.selectedSegment = { $set: action.selectedSegment };
            } else {
                if (action.selectedFilters.length && action.isSelectAll) {
                    updateRequest.selectedFilters = { $set: action.selectedFilters };
                }
                updateRequest = assign(updateRequest, {
                    selectedSegment: { $set: initialState.selectedSegment },
                    selectedPeople: { $set: action.selectedPeople },
                    selectedPeopleCount: { $set: action.selectedPeopleCount || 0 }
                });
            }

            return update(state, updateRequest);
        }

        case CREATE_CAMPAIGN_ACTIONS.CAMPAIGN_CREATED: {
            const newState = { ...initialState, campaignCreated: true };
            newState[Constants.TRANSPORT_EMAIL.toLowerCase()].selectedSenderId = state[Constants.TRANSPORT_EMAIL.toLowerCase()].selectedSenderId;
            newState[Constants.TRANSPORT_SMS.toLowerCase()].selectedSenderId = state[Constants.TRANSPORT_SMS.toLowerCase()].selectedSenderId;
            return update(state, { $set: newState });
        }

        case CREATE_CAMPAIGN_ACTIONS.CAMPAIGN_CREATE_ERROR: {
            return CampaignCreateReducer(state, {
                type: _CAMPAIGN_CREATE_REDUCER_ACTIONS._SET_IS_CREATING,
                isShow: false
            });
        }

        case CREATE_CAMPAIGN_ACTIONS.TOGGLE_TRANSPORT: {
            let index = state.transports.indexOf(action.transport);
            if (index < 0) {
                if (action.transport === Constants.TRANSPORT_SMS) {
                    state = CampaignCreateReducer(state, { type: SELECT_EDITOR, editorType: Constants.TRANSPORT_SMS })
                } else if (!state.transports.length) {
                    state = CampaignCreateReducer(state, { type: SELECT_EDITOR, editorType: action.transport })
                }
                return update(state, { transports: { $push: [action.transport] } })
            }
            state = update(state, { transports: { $splice: [[index, 1]] } });
            if (state.transports.length) {
                return CampaignCreateReducer(state, { type: SELECT_EDITOR, editorType: state.transports[0] });
            }
            return state;
        }
        case _CAMPAIGN_CREATE_REDUCER_ACTIONS._SHOW_SELECT_EVENT: {
            return update(state, {
                showSelectEvent: { $set: action.isShow }
            });
        }

        case _CAMPAIGN_CREATE_REDUCER_ACTIONS._SET_IS_CREATING: {
            return update(state, {
                steps: {
                    create: {
                        isCreating: { $set: action.isShow }
                    }
                }
            })
        }
        case _CAMPAIGN_CREATE_REDUCER_ACTIONS._SHOW_SELECT_SEGMENT: {
            return update(state, {
                showSelectSegment: { $set: action.isShow }
            });
        }
        case CAMPAIGN_CREATE_ACTIONS.CHANGE_ACTIVE_STEP: {
            return update(state, {
                activeStep: { $set: action.activeStep }
            });
        }
        case _CAMPAIGN_CREATE_REDUCER_ACTIONS._SELECT_FILTERS: {
            return update(state, {
                selectedFilters: { $set: action.selectedFilters }
            });
        }

        case RECEIVED_SENDER_IDS: {
            const smsTransport = Constants.TRANSPORT_SMS.toLowerCase(), emailTransport = Constants.TRANSPORT_EMAIL.toLowerCase(), messengerTransport = Constants.TRANSPORT_MESSENGER.toLowerCase();

            return update(state, {
                [smsTransport]: {
                    selectedSenderId: { $set: getActiveSenderIdForTransport(action.senderIds, smsTransport) }
                },
                [emailTransport]: {
                    selectedSenderId: { $set: getActiveSenderIdForTransport(action.senderIds, emailTransport) }
                },
                [messengerTransport]: {
                    selectedSenderId: { $set: getActiveSenderIdForTransport(action.senderIds, messengerTransport) }
                }
            });
        }

        case CREATE_CAMPAIGN_ACTIONS.SELECT_IMPORT_PEOPLE: {
            return update(state, {
                selectImportPeople: { $set: true },
                selectCreateContact: { $set: false },
                selectedSegment: { $set: initialState.selectedSegment },
                selectedPeople: { $set: [] },
                excludedPeople: { $set: [] }
            });
        }
        case CREATE_CAMPAIGN_ACTIONS.SELECT_CREATE_PERSON: {
            return update(state, {
                selectImportPeople: { $set: false },
                selectCreateContact: { $set: true },
                excludedPeople: { $set: [] },
                selectedSegment: { $set: initialState.selectedSegment },
                selectedFilters: { $set: [] }
            });
        }

        case PEOPLE_IMPORT_ACTIONS.PEOPLE_IMPORTED: {
            if (state.selectImportPeople) {
                return CampaignCreateReducer(state, { type: _CAMPAIGN_CREATE_REDUCER_ACTIONS._SELECT_FILTERS, selectedFilters: action.filters });
            }
            return state;
        }
        case PERSON_CREATED: {
            if (state.selectCreateContact) {
                return update(state, {
                    selectedPeople: { $push: [action.person[0].id] },
                    selectedPeopleCount: { $set: state.selectedPeopleCount + 1 }
                });
            }
            return state;
        }
        case CREATE_CAMPAIGN_ACTIONS.CLICK_CREATE_CAMPAIGN: {
            let updateRequest = {
                isFromPeople: { $set: false }
            };
            if (state.campaignType === Constants.CAMPAIGN_BROADCAST) {
                updateRequest.showSelectSegment = { $set: true };
                updateRequest.showSelectEvent = { $set: false };
            } else if (state.campaignType === Constants.CAMPAIGN_TRIGGER) {
                updateRequest.showSelectSegment = { $set: false };
                updateRequest.showSelectEvent = { $set: true };
            }
            return update(state, updateRequest);
        }
        case BILLING_ACTIONS.RECEIVED_BILLING_INFO: {
            if (action.campaignInfo) {
                let { address1, address2, ...rest } = action.campaignInfo;
                return update(state, {
                    metadata: { $set: { address1, address2, ...rest } }
                });
            }
            return state;
        }
        case CAMPAIGN_CREATE_ACTIONS.SET_CAMPAIGN_METADATA: {
            return update(state, {
                metadata: { $set: action.metadata }
            });
        }
        case CREATE_CAMPAIGN_ACTIONS.RELOAD_EDITOR: {
            return update(state, {
                [action.transport.toLowerCase()]: {
                    shouldReload: { $set: action.status }
                }
            });
        }

        case CAMPAIGN_CREATE_ACTIONS.MESSENGER_ONCHANGE_TEMPLATE: {
            return update(state, {
                [Constants.TRANSPORT_MESSENGER.toLowerCase()]: {
                    template: { $set: action.template }
                }
            });
        }



        case CAMPAIGN_CREATE_ACTIONS.MESSENGER_NEW_TEMPLATE_SELECT: {
            return update(state, {
                messengerIsNewlySelectedTemplate: { $set: action.isSelect }
            });
        }

        case CAMPAIGN_CREATE_ACTIONS.CHANGE_SMS_CAMPAIGN_TYPE: {
            return update(state, {
                [Constants.TRANSPORT_SMS.toLowerCase()]: { campaignType: { $set: action.campaignType } }
            });
        }
        default:
            return state;
    }
};

export default CampaignCreateReducer;
